import clsx from "clsx";
import React from "react";
import { availableColors } from "../../utilities/constants";
import { ErrorMessage } from "./CreateUpdateOpeningWithJD";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { nanoid } from "@reduxjs/toolkit";
import OpeningButton from "./Button";

const CustomFieldType = ({ field, errors, register, remove, control, customFieldIndex, watch }) => {
	const type = field.type;
	if (type === 0) {
		return (
			<TextTypeCustomField
				type={type}
				register={register}
				customFieldIndex={customFieldIndex}
				errors={errors}
				remove={remove}
			/>
		);
	} else if (type === 1) {
		return (
			<DropDownTypeCustomField
				register={register}
				customFieldIndex={customFieldIndex}
				errors={errors}
				control={control}
				type={type}
				remove={remove}
				watch={watch}
			/>
		);
	} else {
		return null;
	}
};

export default CustomFieldType;

const TextTypeCustomField = ({ register, customFieldIndex, errors, type, remove }) => {
	return (
		<div className="p-4 rounded-md bg-white flex flex-col gap-3">
			<div className="flex flex-row justify-between items-center">
				<TypeDisplayer type={type} />
				<DeleteRHFItem
					handleClick={() => {
						remove(customFieldIndex);
					}}
				/>
			</div>
			<CustomInputWithLabel
				title={"Field Name"}
				register={register}
				registerFieldName={`customFields.${customFieldIndex}.fieldName`}
				id={`customFields.${customFieldIndex}.fieldName`}
				errorCriteria={errors?.customFields?.[customFieldIndex]?.fieldName?.message}
			/>
			<CustomInputWithLabel
				title={"Default Value"}
				register={register}
				registerFieldName={`customFields.${customFieldIndex}.config.defaultValue`}
				id={`customFields.${customFieldIndex}.config.defaultValue`}
				errorCriteria={errors?.customFields?.[customFieldIndex]?.config?.defaultValue?.message}
			/>
		</div>
	);
};

const DropDownTypeCustomField = ({ register, customFieldIndex, errors, control, type, remove, watch }) => {
	const {
		append: dropDownValuesAppend,
		remove: dropDownValuesRemove,
		fields: dropDownValues,
	} = useFieldArray({
		control: control,
		name: `customFields.${customFieldIndex}.config.dropDownValues`,
	});

	function handleAddNewDropDownOption() {
		const dropDownValueId = nanoid();

		dropDownValuesAppend({
			id: dropDownValueId,
			value: "",
			color: availableColors[dropDownValues.length % availableColors.length],
		});
	}

	return (
		<div className="p-4 rounded-md bg-white flex flex-col gap-3">
			<div className="flex flex-row justify-between items-center">
				<TypeDisplayer type={type} />
				<DeleteRHFItem
					handleClick={() => {
						remove(customFieldIndex);
					}}
				/>
			</div>
			<CustomInputWithLabel
				title={"Field Name"}
				register={register}
				registerFieldName={`customFields.${customFieldIndex}.fieldName`}
				id={`customFields.${customFieldIndex}.fieldName`}
				errorCriteria={errors?.customFields?.[customFieldIndex]?.fieldName?.message}
			/>
			<OpeningButton text="Add Option" handleClick={handleAddNewDropDownOption} />

			{dropDownValues.map((dropDownObject, dropDownObjectIndex) => {
				return (
					<div key={dropDownObject.id} className="flex flex-row justify-between items-center gap-3">
						<DropDownColorIdentifier color={dropDownObject.color} />
						<div className="flex flex-1 flex-col gap-0">
							<input
								autoFocus
								id={`customFields.${customFieldIndex}.config.dropDownValues.${dropDownObjectIndex}.value`}
								{...register(
									`customFields.${customFieldIndex}.config.dropDownValues.${dropDownObjectIndex}.value`,
								)}
								onKeyDown={(e) => {
									if (e.key.toLowerCase() === "enter") {
										e.preventDefault();
										handleAddNewDropDownOption();
									}
								}}
								placeholder="Enter a drop down option"
								className={"mt-1 rounded-md border p-2"}
							/>
							<ErrorMessage
								errorCriteria={
									errors?.customFields?.[customFieldIndex]?.config?.dropDownValues?.[
										dropDownObjectIndex
									]?.value?.message
								}
							/>
						</div>

						<DeleteRHFItem
							handleClick={() => {
								dropDownValuesRemove(dropDownObjectIndex);
							}}
						/>
					</div>
				);
			})}
			<ErrorMessage
				errorCriteria={
					errors?.customFields?.[customFieldIndex]?.config?.dropDownValues?.message ||
					errors?.customFields?.[customFieldIndex]?.config?.dropDownValues?.root?.message
				}
			/>
		</div>
	);
};

const TypeDisplayer = ({ type }) => {
	if (type === 0) {
		return <TypeHeader header={"Text"} />;
	} else if (type === 1) {
		return <TypeHeader header={"Drop Down"} />;
	}
};

const TypeHeader = ({ header }) => {
	return <h1 className="text-base font-medium">{header}</h1>;
};

const CustomInputWithLabel = ({ id, register, registerFieldName, errorCriteria, title }) => {
	return (
		<>
			<div className="">
				<label htmlFor={id} className="block text-sm font-medium text-gray-600">
					{title}
				</label>
				<input
					type="text"
					id={id}
					placeholder={`Enter ${title}`}
					{...register(registerFieldName)}
					className="mt-1 w-1/2 rounded-md border p-2"
				/>
				<ErrorMessage errorCriteria={errorCriteria} />
			</div>
		</>
	);
};

export const DropDownColorIdentifier = ({ color }) => {
	return <div className={clsx("h-6 w-6 rounded-md", color)}></div>;
};

const DeleteRHFItem = ({ handleClick }) => {
	return (
		<IconButton
			type="button"
			className="rounded-md"
			onClick={() => {
				handleClick();
			}}
		>
			<DeleteIcon className="text-gray-600" />
		</IconButton>
	);
};
