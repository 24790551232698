import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import StopScreenShareIcon from "@mui/icons-material/StopScreenShare";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const AdminUI = (props) => {
	return (
		<Box className="flex flex-col justify-center items-center">
			{props.candidateName && props.openingTitle && (
				<Box className="flex flex-col justify-center items-center">
					<Box className="flex flex-row justify-center items-center gap-[5px]">
						<Typography variant="subtitle1" className="text-center  font-semibold">
							Candidate's Name:
						</Typography>
						<Typography variant="subtitle1" className="text-center font-semibold text-blue-600">
							{props.candidateName}
						</Typography>
					</Box>
					<Box className="flex flex-row justify-center items-center gap-[5px]">
						<Typography variant="subtitle1" className="text-center  font-semibold">
							Role:
						</Typography>
						<Typography variant="subtitle1" className="text-center font-semibold text-blue-600">
							{props.openingTitle}
						</Typography>
					</Box>
				</Box>
			)}
			<Box id="buttons" className="flex justify-center my-2">
				<Tooltip title={props.isVideoOn ? "Turn off your video" : "Turn on your video"}>
					<Button
						onClick={props.handleVideo}
						id="video-btn"
						className={`px-4 py-2 mx-1 rounded-lg ${
							props.isVideoOn ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
						} hover:bg-blue-500 hover:text-white`}
						type="button"
					>
						{props.isVideoOn ? (
							<VideocamIcon fontSize="small" />
						) : (
							<VideocamOffIcon fontSize="small" />
						)}
					</Button>
				</Tooltip>
				<Tooltip title={props.isMicOn ? "Turn off your microphone" : "Turn on your microphone"}>
					<Button
						onClick={props.handleMic}
						id="audio-btn"
						className={`px-4 py-2 mx-1 rounded-lg ${
							props.isMicOn ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
						} hover:bg-blue-500 hover:text-white`}
						type="button"
					>
						{props.isMicOn ? <MicIcon fontSize="small" /> : <MicOffIcon fontSize="small" />}
					</Button>
				</Tooltip>
				<Tooltip title={props.isScreenShared ? "Stop Screen Share" : "Start Screen Share"}>
					<Button
						onClick={props.share}
						id="share-btn"
						className={`px-4 py-2 mx-1 rounded-lg ${
							props.isScreenShared ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
						} hover:bg-blue-500 hover:text-white`}
						type="button"
					>
						{props.isScreenShared ? (
							<StopScreenShareIcon fontSize="small" />
						) : (
							<ScreenShareIcon fontSize="small" />
						)}
					</Button>
				</Tooltip>
				<Tooltip
					title={
						props.isTabAudioMuted
							? "Unmute all audio from this tab. This refers to audio coming from other attendees and the candidate."
							: "Mute all audio from this tab. This refers to audio coming from other attendees and the candidate."
					}
				>
					<Button
						onClick={props.toggleMute}
						id="mute-btn"
						className={`px-4 py-2 mx-1 rounded-lg ${
							props.isTabAudioMuted ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
						} hover:bg-blue-500 hover:text-white`}
						type="button"
					>
						{props.isTabAudioMuted ? (
							<VolumeOffIcon fontSize="small" />
						) : (
							<VolumeUpIcon fontSize="small" />
						)}
					</Button>
				</Tooltip>
			</Box>
		</Box>
	);
};

export default AdminUI;
