import React from "react";
import { Button, Tooltip } from "@mui/material";
function ScheduleHeader({ scheduleType, onClose }) {
	return (
		<>
			<Button
				onClick={onClose}
				className="absolute top-0 right-2 text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full"
				style={{
					minWidth: "unset",
					padding: 0,
					width: "36px",
					height: "36px",
					backgroundColor: "rgba(0, 0, 0, 0.05)",
				}}
			>
				<Tooltip title="close">
					<i className="fas fa-times" style={{ fontSize: "15px" }}></i>
				</Tooltip>
			</Button>
			<h1 className="text-xl font-semibold mb-4 text-center">
				{scheduleType === 0
					? "Schedule Interview"
					: scheduleType === 1
						? "Reschedule Interview"
						: scheduleType === 2
							? "Bulk Schedule Interviews"
							: ""}
			</h1>
			<p className="mb-4 text-center font-medium">
				Please select a suitable time slot for the candidate's interview:
			</p>
		</>
	);
}

export default ScheduleHeader;
