import { Avatar, Tabs, Tab, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Transactions from "../components/Pricing/Transactions";
import Invoices from "../components/Pricing/Invoices";
import { toast } from "react-toastify";
import CurrentPlan from "../components/Pricing/CurrentPlan";
import Account from "../components/Pricing/Account";
import AddCredits from "../components/Pricing/AddCredits";
import { useSelector } from "react-redux";
import { fetchOrganizationDetailsAPI } from "../utilities/organizationApi";
import ApartmentIcon from "@mui/icons-material/Apartment";

const BillingDashboard = () => {
	const { user } = useSelector((state) => state.auth);
	const [organization, setOrganization] = useState(user);
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();
	const searchQuery = new URLSearchParams(location.search);
	const page = +searchQuery.get("page");
	const [tab, setTab] = useState(page && page < 4 ? page : 0);
	const navigate = useNavigate();

	const plans = organization?.pricingPlans || [];
	const activePlan = plans.find((plan) => plan.isActive) || {};
	const hasActivePlan = Object.keys(activePlan).length > 0;

	const handleChange = (event, newValue) => {
		setTab(newValue);
		const url = new URL(window.location.href);
		url.searchParams.set("page", newValue);
		window.history.replaceState(null, "", url.href);
	};

	const { organizationId } = user.organizationId;

	const getOrganizationsDetails = async () => {
		if (!user.isPricingFeatureActive) {
			navigate("/admin");
		}
		if (user.organizationId) {
			setIsLoading(true);
			try {
				const res = await fetchOrganizationDetailsAPI();
				setOrganization(res);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				toast.error(error.message);
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		getOrganizationsDetails();
	}, [user.organizationId]);

	return (
		<div>
			<div className="bg-white p-4 rounded-lg shadow-lg w-full mx-auto">
				<div className="relative mb-4">
					<div className="w-full h-24 bg-gradient-to-r from-blue-400 via-grey-500 to-purple-500 rounded-t-lg"></div>
					<Avatar
						alt={organization && organization.organizationName}
						className="w-20 h-20 rounded-full absolute bottom-[-36px] left-4 border-4 border-white"
					>
						<ApartmentIcon />
					</Avatar>
				</div>
				<div className="pl-32 pt-4 flex items-center justify-between">
					<div>
						<Typography variant="h6" className="font-bold text-gray">
							{organization && organization.organizationName}
						</Typography>
						<Typography variant="body2" className="text-gray-600">
							{organization && organization.contactInfo}
						</Typography>
					</div>

					{/*
						{hasActivePlan && activePlan.type === "PayAsYouGo" && !activePlan.isTrial ? (
							<AddCredits
								organizationId={organizationId}
								organization={organization}
								getOrganizationsDetails={getOrganizationsDetails}
								isLoading={isLoading}
								key={organization?._id}
							/>
						) : (
							""
						)}
					*/}
				</div>
				<div className="mt-4">
					<Box className="border-b border-gray-200">
						<Tabs value={tab} onChange={handleChange} centered>
							<Tab
								label="Account"
								className={`${tab === 0 ? "text-black" : "text-gray-500"}`}
							/>
							<Tab label="Plan" className={`${tab === 1 ? "text-black" : "text-gray-500"}`} />
							<Tab
								label="Transactions"
								className={`${tab === 2 ? "text-black" : "text-gray-500"}`}
							/>
							<Tab
								label="Invoices"
								className={`${tab === 3 ? "text-black" : "text-gray-500"}`}
							/>
						</Tabs>
					</Box>
				</div>
			</div>
			<Box mt={2}>
				{tab === 0 && (
					<Account
						organizationId={organizationId}
						organization={organization}
						getOrganizationsDetails={getOrganizationsDetails}
						isLoading={isLoading}
						key={organization?._id}
					/>
				)}
				{tab === 1 && (
					<CurrentPlan
						organizationId={organizationId}
						organization={organization}
						getOrganizationsDetails={getOrganizationsDetails}
						isLoading={isLoading}
						key={organization?._id}
					/>
				)}
				{tab === 2 && (
					<Transactions
						organizationId={organizationId}
						organization={organization}
						getOrganizationsDetails={getOrganizationsDetails}
						key={organization?._id}
					/>
				)}
				{tab === 3 && (
					<Invoices
						organizationId={organizationId}
						organization={organization}
						getOrganizationsDetails={getOrganizationsDetails}
						key={organization?._id}
					/>
				)}
			</Box>
		</div>
	);
};

export default BillingDashboard;
