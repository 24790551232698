import { Typography, Box, Paper } from "@mui/material";
import { getFormattedDateTime4 } from "../../utilities/utilityFunctions";
const InterviewTimeout = ({ scheduledTime }) => {
	const currentTime = Date.now();
	const timeSinceInterview = Math.abs(new Date(currentTime) - new Date(scheduledTime));
	const formattedTimeSinceInterview = new Date(timeSinceInterview).toISOString().substr(11, 8); // Format to HH:MM:SS

	return (
		<Box className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 font-poppins">
			<Paper
				elevation={12}
				className="p-12 rounded-xl w-full sm:w-3/4 md:w-2/3 lg:w-1/2 bg-white transform transition-transform duration-500 hover:scale-105 animate-fade-in"
			>
				<Typography variant="h3" align="center" className="text-red-500 mb-6 font-bold font-mono">
					Interview Timeout
				</Typography>
				<Typography variant="h6" align="center" className="text-gray-700 mb-4 font-mono">
					Unfortunately, the interview time has passed.
				</Typography>
				<Typography variant="body1" align="center" className="text-gray-600 mb-4 font-mono">
					Scheduled Time:{" "}
					<span className="text-gray-900 font-semibold">
						{getFormattedDateTime4(scheduledTime)}
					</span>
				</Typography>
				<Typography variant="body1" align="center" className="text-gray-600 font-mono">
					Time since interview:{" "}
					<span className="text-gray-900 font-semibold">{formattedTimeSinceInterview}</span>
				</Typography>
			</Paper>
		</Box>
	);
};
export default InterviewTimeout;
