import { CircularProgress, Grid, Typography } from "@mui/material";
import StatCard from "./StatCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PercentIcon from "@mui/icons-material/Percent";
import BarChartIcon from "@mui/icons-material/BarChart";
import PaymentIcon from "@mui/icons-material/Payment";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AddCardIcon from "@mui/icons-material/AddCard";
import CurrentPlanCard from "./CurrentPlanCard";

const Account = ({ organizationId, organization, isLoading }) => {
	const plans = organization?.pricingPlans || [];
	const activePlan = plans.find((plan) => plan.isActive) || {};
	const hasActivePlan = Object.keys(activePlan).length > 0;

	return (
		<div className="w-full mx-auto p-2">
			{isLoading ? (
				<div className="text-center">
					<CircularProgress />
				</div>
			) : (
				<div>
					<div className="bg-white p-4 rounded-lg shadow-lg w-full mx-auto">
						<Typography variant="h5" className="ml-2 text-gray-500 uppercase font-medium">
							Stats at a glance
						</Typography>
						<Grid container spacing={2} justifyContent="center" className="mt-2">
							<Grid item xs={12} sm={6} md={3}>
								<StatCard
									value={organization?.currentBalance ?? 0}
									label={"Current Balance"}
									color="green"
									IconComponent={AttachMoneyIcon}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<StatCard
									value={organization?.outstandingBalance ?? 0}
									label={"Outstanding Balance"}
									color="red"
									IconComponent={MoneyOffIcon}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<StatCard
									value={organization?.promoBalance ?? 0}
									label={"Promo Balance"}
									color="red"
									IconComponent={MoneyOffIcon}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<StatCard
									value={
										organization?.countryCode === "" || organization?.countryCode === "IN"
											? 18
											: 0
									}
									label={"Tax Percentage"}
									color="gray"
									IconComponent={PercentIcon}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<StatCard
									value={organization?.interviewRate ?? "Default"}
									label={"Custom Interview Rate"}
									color="blue"
									IconComponent={BarChartIcon}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<StatCard
									value={organization?.promoInterviewRate ?? "Default"}
									label={"Promo Interview Rate"}
									color="blue"
									IconComponent={BarChartIcon}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<StatCard
									value={organization?.paymentType ?? "Prepaid"}
									label={"Payment Mode"}
									color="purple"
									IconComponent={PaymentIcon}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<StatCard
									value={
										organization?.countryCode === "" || organization?.countryCode === "IN"
											? "INR"
											: "USD"
									}
									label={"Currency"}
									color="yellow"
									IconComponent={MonetizationOnIcon}
								/>
							</Grid>
						</Grid>
					</div>

					{hasActivePlan ? (
						<div className="mt-4">
							<CurrentPlanCard
								organization={organization}
								value={activePlan}
								IconComponent={AddCardIcon}
								key={organization._id}
							/>
						</div>
					) : (
						""
					)}
				</div>
			)}
		</div>
	);
};

export default Account;
