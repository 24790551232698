import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	onGoingSearchInputVlaue: "",
	allOnGoingReports: [],
	oneTimeLoad: true,
};

const onGoingInterviewSlice = createSlice({
	name: "onGoingInterview",
	initialState,
	reducers: {
		updateInputValue: (state, action) => {
			state.onGoingSearchInputVlaue = action.payload;
		},
		setOnGoingReports: (state, action) => {
			let reports = action.payload;
			reports?.forEach((el) => {
				const duplicateIndex = state.allOnGoingReports.findIndex((report) => report._id === el._id);
				if (duplicateIndex !== -1) {
					state.allOnGoingReports.splice(duplicateIndex, 1);
				}
				state.allOnGoingReports.unshift(el);
			});
		},
		removeOnGoingReport: (state, action) => {
			let reports = state.allOnGoingReports.filter((el) => {
				return el?._id !== action.payload;
			});
			state.allOnGoingReports = reports;
		},
		addOnGoingReport: (state, action) => {
			const duplicateIndex = state.allOnGoingReports.findIndex(
				(report) => report._id === action.payload._id,
			);
			if (duplicateIndex !== -1) {
				state.allOnGoingReports.splice(duplicateIndex, 1);
			}
			state.allOnGoingReports.unshift(action.payload);
		},
		changeOneTimeLoad: (state, action) => {
			state.oneTimeLoad = action.payload;
		},
	},
});

export const onGoingInterviewApiSlice = createApi({
	reducerPath: "onGoingInterviewApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "/api",
	}),
	tagTypes: ["Ongoing-Interview"],
	endpoints: (builder) => ({
		getOngoingInterviews: builder.query({
			query: () => "/interviewReports/onGoing",
			providesTags: ["Ongoing-Interview"],
		}),
	}),
});

export const { useGetOngoingInterviewsQuery } = onGoingInterviewApiSlice;
export const {
	updateInputValue,
	setOnGoingReports,
	removeOnGoingReport,
	addOnGoingReport,
	changeOneTimeLoad,
} = onGoingInterviewSlice.actions;
export const onGoingInterviewReducer = onGoingInterviewSlice.reducer;
