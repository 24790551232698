import axios from "./axiosInstance";

// fetch the zoho countries list
async function fetchCountriesAPI() {
	try {
		const response = await axios.get(`/api/zoho/country-list`);
		if (response.status === 200) {
			return response.data.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while checking countries");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

// fetch the states list
async function fetchStatesAPI() {
	try {
		const response = await axios.get(`/api/zoho/state-list`);
		if (response.status === 200) {
			return response.data.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while checking states");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function createInvoice(data) {
	try {
		const response = await axios.post(`/api/zoho/create-invoices`, data);
		if (response.status === 200) {
			return response.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while create invoices");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function createAccountInZoho(data) {
	try {
		const response = await axios.post(`/api/zoho/create-account`, data);
		console.log(response);
		if (response.status === 200) {
			return response.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while create account");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}
export { fetchCountriesAPI, fetchStatesAPI, createInvoice, createAccountInZoho };
