import { Box } from "@mui/material";
import React from "react";

const CanLeave = () => {
	return (
		<Box id="panel2" className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto">
			<h2 className="text-center text-2xl font-bold mb-6">
				Feel free to close the tab. You can come back later or refresh to resume the interview.
			</h2>
		</Box>
	);
};

export default CanLeave;
