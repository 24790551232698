import { Box } from '@mui/material';
import React from 'react';

const NetworkLost = ({
	ping,
	loadingForLost,
	setLoadingForLost,
	retryCountdown,
	autoRetried,
	setAutoRetried,
}) => {
	return (
		<Box className='bg-gray-200'>
			<Box
				id='panel2'
				className='bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto'>
				<h2 className='text-center text-xl font-semibold mb-6'>
					{loadingForLost
						? 'Checking network...'
						: autoRetried
						? "Looks like your internet's not back yet. Could you give it a check and try one more time?"
						: `Your network connection appears unstable. We'll automatically retry in ${retryCountdown} seconds. Please check your internet connection if possible. We appreciate your patience and understanding during this time."`}
				</h2>
				<Box className='flex justify-center mt-4'>
					<Box
						id='continue2'
						className={`bg-blue-500 text-white py-2 px-4 rounded text-xs normal-case hover:bg-blue-600 focus:outline-none focus:border-none
                        ${
							loadingForLost
								? 'cursor-wait opacity-50'
								: 'cursor-pointer'
						}
                        `}
						onClick={async () => {
							if (!loadingForLost) {
								setAutoRetried(true);
								setLoadingForLost(true);
								const noNetwork = await ping();
								noNetwork && setLoadingForLost(false);
							}
						}}>
						Retry
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default NetworkLost;
