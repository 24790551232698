import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./TabularOpeningEvaluations.css";
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ReportsLoader from "../components/Skeleton-Loaders/ReportsLoader";
import { getOrganizationInterviews } from "../utilities/organizationApi";
import ReportsDataGridOrganization from "../components/grid/ReportsDataGridOrganization";
import { fetchOrganizationsSuperAdminAPI } from "../utilities/superAdmin/organization";
import { toast } from "react-toastify";
import { updateState } from "../features/organization/organizationInterviewSlice";

const SUPER_ADMIN_ROLE = "1096";

const TabularOrganizationsEvaluations = () => {
	const { user } = useSelector((state) => state.auth);
	const [isLoading, setIsLoading] = useState(false);
	const [candidates, setCandidates] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const {
		showCompletedInterviews,
		showActiveOpenings,
		organizationId: selectedOrganizationId,
		selectedMonth,
		selectedOpening,
	} = useSelector((state) => state.organizationInterview);
	const [organizationId, setOrganizationId] = useState(selectedOrganizationId || user.organizationId);

	const dispatch = useDispatch();

	const getInterviewReportsForOrganizations = useCallback(
		async (year, month) => {
			try {
				if (!year || !month) {
					return;
				}
				dispatch(updateState({ field: "organizationId", value: organizationId }));
				setIsLoading(true);
				const candidates = await getOrganizationInterviews(organizationId, year, month);
				setCandidates(candidates);
			} catch (error) {
				console.error(error);
				toast.error("Error fetching interview reports.");
			} finally {
				setIsLoading(false);
			}
		},
		[organizationId],
	);

	useEffect(() => {
		const [year, month] = selectedMonth.split("-");
		if (organizationId) getInterviewReportsForOrganizations(year, month);
	}, [selectedMonth, organizationId]);

	useEffect(() => {
		if (user.role === SUPER_ADMIN_ROLE && !organizations.length) {
			const fetchOrganizationsDetails = async () => {
				try {
					setIsLoading(true);
					const res = await fetchOrganizationsSuperAdminAPI();
					setOrganizations(res);
				} catch (error) {
					console.error(error);
					toast.error(error.message);
				} finally {
					setIsLoading(false);
				}
			};
			fetchOrganizationsDetails();
		}
	}, [user.role]);

	const handleMonthChange = (event) => {
		const newMonth = event.target.value;
		if (newMonth !== selectedMonth) {
			dispatch(updateState({ field: "selectedMonth", value: newMonth }));
			dispatch(updateState({ field: "selectedOpening", value: null }));
			if (!organizationId) toast.warn("Select an organization");
		}
	};

	const handleOrganizationSelection = useCallback((event, value) => {
		setOrganizationId(value?._id || null);
		dispatch(updateState({ field: "selectedOpening", value: null }));
	}, []);

	const handleOpeningSelection = useCallback((event, value) => {
		dispatch(updateState({ field: "selectedOpening", value: value || null }));
	}, []);

	const handleToggleActiveOpenings = () => {
		dispatch(updateState({ field: "showActiveOpenings", value: !showActiveOpenings }));
	};

	const handleChangeCompleteInterviews = () => {
		dispatch(updateState({ field: "showCompletedInterviews", value: !showCompletedInterviews }));
	};

	const filteredCandidates = useMemo(() => {
		try {
			let filterCandidates = candidates;
			const [year, month] = selectedMonth.split("-");
			const startDate = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0));
			const endDate = new Date(Date.UTC(year, month, 0, 23, 59, 59));
			if (showCompletedInterviews) {
				filterCandidates = filterCandidates.filter((candidate) => {
					const interviewDate = new Date(candidate.interviewEndTimeDate);
					return (
						candidate.interviewCompleted === true &&
						candidate.evaluation?.trim() !== "" &&
						interviewDate >= startDate &&
						interviewDate <= endDate
					);
				});
			}
			if (showActiveOpenings) {
				filterCandidates = filterCandidates.filter((candidate) => {
					const openingStatus = candidate?.openingData?.status;
					return openingStatus === undefined || openingStatus === true;
				});
			}

			if (selectedOpening && Object.keys(selectedOpening).length) {
				filterCandidates = filterCandidates.filter(
					(candidate) => candidate?.opening === selectedOpening?.opening,
				);
			}

			return filterCandidates;
		} catch {
			toast.warn("Something went wrong while applying the filter.");
			return candidates;
		}
	}, [candidates, showActiveOpenings, selectedOpening, showCompletedInterviews, selectedMonth]);

	const filteredOpenings = useMemo(() => {
		try {
			const groupedByTitle = candidates.reduce((acc, curr) => {
				if (!acc[curr.openingData.title]) acc[curr.openingData.title] = new Set();
				acc[curr.openingData.title].add(
					JSON.stringify({
						opening: curr.opening,
						createdAt: curr.openingData.createdAt,
					}),
				);
				return acc;
			}, {});
			const result = Object.entries(groupedByTitle).flatMap(([title, entries]) => {
				const openings = Array.from(entries).map((entry) => JSON.parse(entry));
				openings.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
				return openings.map((entry, index) => ({
					title: index === 0 ? title : `${title} (duplicated${index})`,
					opening: entry.opening,
				}));
			});
			return [...result];
		} catch (e) {
			console.log("Error in filteredOpenings ", e.message);
			return [];
		}
	}, [candidates]);

	return (
		<Box className="bg-gray-100 min-h-screen">
			<Box className="container mx-auto p-6">
				<Box className="bg-white p-6 rounded-lg shadow-md mb-6">
					<div className="flex justify-between flex-wrap">
						<Box>
							<FormControlLabel
								control={
									<Checkbox
										checked={showCompletedInterviews}
										onChange={handleChangeCompleteInterviews}
										inputProps={{ "aria-label": "controlled" }}
										size="small"
										disabled={isLoading}
									/>
								}
								label="Show All Completed Interviews"
								sx={{
									"& .MuiFormControlLabel-label": {
										fontSize: "0.9rem",
									},
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={showActiveOpenings}
										onChange={handleToggleActiveOpenings}
										inputProps={{ "aria-label": "controlled" }}
										size="small"
										disabled={isLoading}
									/>
								}
								label="Show Active Openings"
								sx={{
									"& .MuiFormControlLabel-label": {
										fontSize: "0.9rem",
									},
								}}
							/>
						</Box>
						<Box className="flex flex-wrap flex-end mb-2">
							{user.role === SUPER_ADMIN_ROLE && (
								<Autocomplete
									disablePortal
									options={organizations}
									getOptionLabel={(option) => option.organizationName || ""}
									value={organizations.find((org) => org._id === organizationId) || null}
									onChange={handleOrganizationSelection}
									disabled={isLoading}
									sx={{
										width: 200,
										marginRight: "15px",
										"& .MuiInputBase-root": {
											height: "40px",
										},
									}}
									renderInput={(params) => (
										<TextField {...params} label="Search Organization" size="small" />
									)}
									freeSolo
								/>
							)}
							<Autocomplete
								key={selectedOpening ? "selected" : "cleared"}
								disablePortal
								options={filteredOpenings}
								getOptionLabel={(option) => option.title || ""}
								value={selectedOpening || null}
								onChange={handleOpeningSelection}
								disabled={isLoading}
								sx={{
									width: 200,
									marginRight: "15px",
									"& .MuiInputBase-root": {
										height: "40px",
									},
								}}
								renderInput={(params) => (
									<TextField {...params} label="Select Opening" size="small" />
								)}
								freeSolo
							/>
							<TextField
								id="monthFilter"
								type="month"
								value={selectedMonth}
								onChange={handleMonthChange}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								size="small"
								disabled={isLoading}
								sx={{
									width: 200,
									"& .MuiInputBase-root": {
										height: "40px",
									},
								}}
							/>
						</Box>
					</div>
					{isLoading ? (
						<ReportsLoader />
					) : (
						<ReportsDataGridOrganization candidates={filteredCandidates} />
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default TabularOrganizationsEvaluations;
