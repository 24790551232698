const isValidEmail = (email) => {
	try {
		const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
		return emailRegex.test(email);
	} catch {
		return false;
	}
};

const isValidPhoneNumber = (phoneNumber) => {
	try {
		const phoneRegex = /^\d{6,11}$/;

		return phoneRegex.test(phoneNumber);
	} catch {
		return false;
	}
};

function countWord(sentence) {
	try {
		if (typeof sentence !== "string") {
			return false;
		}
		const words = sentence.trim().split(/\s+/);
		return words.length > 0 && words[0] !== "" ? words.length : 0;
	} catch {
		return 0;
	}
}

export { isValidEmail, isValidPhoneNumber, countWord };
