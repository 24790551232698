import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const NetworkModal = ({ showModal, setShowModal }) => {
	return (
		<Modal
			open={showModal}
			onClose={() => setShowModal(false)}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'>
			<Box
				className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white p-6 rounded-lg shadow-lg border-none outline-none'
				sx={{
					'& .MuiBackdrop-root': {
						// Targeting the backdrop directly
						outline: 'none !important',
					},
					'&:focus-visible': {
						// Trying to target the focus state specifically
						outline: 'none !important',
					},
				}}>
				<Typography id='modal-modal-title' variant='h6' component='h2'>
					Slow Internet Alert!
				</Typography>
				<Typography id='modal-modal-description' className='mt-4'>
					If you encounter issues or experience slow response times,
					try changing your network and refreshing your browser. Your
					session is saved, so don't worry.
				</Typography>
				<Box className='flex justify-end mt-3'>
					<Button
						className='normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs'
						onClick={() => setShowModal(false)}>
						OK
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default NetworkModal;
