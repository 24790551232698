import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	allDropReports: [],
	dropSearchInputValue: "",
};

const dropReportSlice = createSlice({
	name: "dropReports",
	initialState,
	reducers: {
		addDropReports: (state, action) => {
			const duplicateIndex = state.allDropReports.findIndex(
				(report) => report._id === action.payload._id,
			);
			if (duplicateIndex !== -1) {
				state.allDropReports.splice(duplicateIndex, 1);
			}
			state.allDropReports.unshift(action.payload);
		},
		removeDropReports: (state, action) => {
			let reports = state.allDropReports.filter((el) => {
				return el?._id !== action.payload;
			});
			state.allDropReports = reports;
		},
		updateInputValues: (state, action) => {
			state.dropSearchInputValue = action.payload;
		},
	},
});


export const { addDropReports, removeDropReports, updateInputValues } = dropReportSlice.actions;
export default dropReportSlice.reducer;
