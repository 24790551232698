import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name, personName, theme) {
	return {
		fontWeight: personName?.includes(name)
			? theme.typography.fontWeightMedium
			: theme.typography.fontWeightRegular,
	};
}

export default function MultipleSelectChip({ options = [], personName, handleChange }) {
	const theme = useTheme();

	return (
		<Select
			multiple
			fullWidth
			maxRows={1}
			size="small"
			value={personName}
			onChange={handleChange}
			input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
			renderValue={(selected) => (
				<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
					{selected.length < 4
						? selected.map((value) => <Chip key={value} label={value} />)
						: selected.slice(0, 4).map((value) => <Chip key={value} label={value} />)}
					{selected.length > 4 && <Chip key="more" label={`+${selected.length - 4} more`} />}
				</Box>
			)}
			MenuProps={MenuProps}
		>
			{options.map((el) => (
				<MenuItem key={el.id} value={el.email} style={getStyles(el.id, personName, theme)}>
					{el.email}
				</MenuItem>
			))}
		</Select>
	);
}
