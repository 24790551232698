import { TextField } from "@mui/material/node";

const dateColumnFilter = (valueGetter) => {
    const filter = [
        {
            label: 'Equals to',
            value: 'equals',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let date = valueGetter(row);
                    let filterDate = new Date(filterItem.value);
                    return date.toDateString() === filterDate.toDateString();
                };
            },
            InputComponent: (props) => <TextField type="date" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: 'Not equals to',
            value: 'notEquals',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let date = valueGetter(row);
                    let filterDate = new Date(filterItem.value);
                    return date.toDateString() !== filterDate.toDateString();
                };
            },
            InputComponent: (props) => <TextField type="date" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: 'Before',
            value: 'before',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let date = valueGetter(row);
                    let filterDate = new Date(filterItem.value);
                    return date < filterDate;
                };
            },
            InputComponent: (props) => <TextField type="date" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: 'After',
            value: 'after',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let date = valueGetter(row);
                    let filterDate = new Date(filterItem.value);
                    return date > filterDate;
                };
            },
            InputComponent: (props) => <TextField type="date" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        // {
        //     label: 'Between',
        //     value: 'between',
        //     getApplyFilterFn: (filterItem) => {
        //         if (!filterItem.value) {
        //             return null;
        //         }
        //         return (value, row) => {
        //             let date = valueGetter(row);
        //             let filterDate = filterItem.value.split(',');
        //             return date > new Date(filterDate[0]) && date < new Date(filterDate[1]);
        //         };
        //     },
        //     InputComponent: (props) => <TextField type="date" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        // }
    ]
    return filter;
}

export default dateColumnFilter;