import React, { createContext, useContext, useState } from 'react';

const GridApiContext = createContext(null);

export const useGridApi = () => useContext(GridApiContext);

export const GridApiProvider = ({ children }) => {
  const [gridApi, setGridApi] = useState(null);

  return (
    <GridApiContext.Provider value={{ gridApi, setGridApi }}>
      {children}
    </GridApiContext.Provider>
  );
};