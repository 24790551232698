import { Box, Button, Typography, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";

const MeetingLobby = ({ proctorName, candidateName, openingTitle, onJoinClick }) => {
	const [isCameraOn, setIsCameraOn] = useState(false);
	const [isMicOn, setIsMicOn] = useState(false);
	const [localProctorName, setLocalProctorName] = useState(proctorName || "");
	const [nameError, setNameError] = useState("");

	useEffect(() => {
		setLocalProctorName(proctorName || "");
	}, [proctorName]);

	const handleProctorNameChange = (event) => {
		const newName = event.target.value;
		if (newName.length <= 20 && /^[a-zA-Z0-9\s]*$/.test(newName) && !newName.includes("|")) {
			setLocalProctorName(newName);
			setNameError("");
		} else if (newName.length > 20) {
			setNameError("Name must be 20 characters or less");
		} else if (newName.includes("|")) {
			setNameError("The '|' character is not allowed");
		} else {
			setNameError("Only letters, numbers, and spaces are allowed");
		}
	};

	const isNameValid = localProctorName.trim() !== "" && !nameError;

	return (
		<Box className="flex flex-col justify-center items-center">
			<Box className="flex flex-col justify-center items-center">
				{candidateName && openingTitle && (
					<Box className="flex flex-col justify-center items-center">
						<Box className="flex flex-row justify-center items-center gap-[5px]">
							<Typography variant="subtitle1" className="text-center  font-semibold">
								Candidate's Name:
							</Typography>
							<Typography
								variant="subtitle1"
								className="text-center font-semibold text-blue-600"
							>
								{candidateName}
							</Typography>
						</Box>
						<Box className="flex flex-row justify-center items-center gap-[5px]">
							<Typography variant="subtitle1" className="text-center  font-semibold">
								Role:
							</Typography>
							<Typography
								variant="subtitle1"
								className="text-center font-semibold text-blue-600"
							>
								{openingTitle}
							</Typography>
						</Box>
					</Box>
				)}
				<Box className="flex flex-row justify-center gap-[5px] items-center">
					<Typography variant="subtitle1" className="text-center  font-semibold">
						Meeting Status:
					</Typography>
					<Typography variant="subtitle1" className="text-center text-blue-600 font-semibold">
						Active
					</Typography>
				</Box>
			</Box>
			<Box className="flex flex-col justify-center items-center w-full">
				<Box className="mb-6 w-full max-w-md">
					<Typography variant="subtitle1" className="text-center font-semibold mb-3 text-gray-700">
						Enter your name to join the meeting
					</Typography>
					<TextField
						value={localProctorName}
						onChange={handleProctorNameChange}
						label="Your Name"
						variant="outlined"
						fullWidth
						InputProps={{
							className: "bg-gray-50 border rounded-md shadow-sm",
						}}
						InputLabelProps={{
							className: "text-gray-500",
						}}
						className="shadow-lg hover:shadow-xl transition-shadow duration-200 focus:shadow-xl focus:ring-2 focus:ring-blue-500"
						error={!!nameError}
						helperText={nameError}
					/>
				</Box>
				<Box className="flex justify-center items-center my-2">
					<Button
						className={`px-4 py-2 mx-1 rounded-lg bg-blue-600 text-white hover:bg-blue-500 hover:text-white`}
						onClick={() => setIsCameraOn(!isCameraOn)}
					>
						{isCameraOn ? (
							<VideocamIcon fontSize="small" />
						) : (
							<VideocamOffIcon fontSize="small" />
						)}
					</Button>
					<Button
						className={`px-4 py-2 mx-1 rounded-lg bg-blue-600 text-white hover:bg-blue-500 hover:text-white`}
						onClick={() => setIsMicOn(!isMicOn)}
					>
						{isMicOn ? <MicIcon fontSize="small" /> : <MicOffIcon fontSize="small" />}
					</Button>
				</Box>
				<Button
					className={`px-4 py-2 mx-auto block bg-blue-600 text-white rounded-lg hover:bg-blue-500 text-center normal-case ${
						!isNameValid ? "cursor-not-allowed opacity-50" : ""
					}`}
					onClick={() => onJoinClick(isCameraOn, isMicOn, localProctorName)}
					disabled={!isNameValid}
				>
					Join Meeting
				</Button>
			</Box>
		</Box>
	);
};

export default MeetingLobby;
