import React, { useState } from "react";
import {
	Box,
	Button,
	IconButton,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import logo from "../../assets/zi-favicon.png";
import PreviewIcon from "@mui/icons-material/Preview";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	maxWidth: "100%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	overflowY: "auto",
	maxHeight: "90vh",
	borderRadius: "10px",
	"@media (min-width: 800px)": {
		width: 800,
	},
};

const VolumeBasedPlanDetailsModal = ({ volumeDetails }) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<IconButton onClick={() => setShowModal(true)}>
				<PreviewIcon color="primary" />
			</IconButton>

			<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
				aria-labelledby="add-credit"
				aria-describedby="add-credit"
			>
				<Box sx={modalStyle}>
					<Box className="flex justify-center mb-6">
						<img src={logo} alt="Zinterview Logo" className="h-16" />
					</Box>
					<div className="text-center">Volume Based Details </div>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell className="text-center">
									Interview Count (more than equal to)
								</TableCell>
								<TableCell className="text-center">Price of all interviews</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{volumeDetails?.map((volume, index) => (
								<TableRow key={index}>
									<TableCell className="text-center">{volume.toUnits}</TableCell>
									<TableCell className="text-center">{volume.chargeOfInterviews}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</Modal>
		</>
	);
};

export default VolumeBasedPlanDetailsModal;
