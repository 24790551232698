import { TextField } from "@mui/material/node";

const getNumberColumnFilters = (valueGetter) => {
    const numberFilter = [
        {
            label: '=',
            value: 'eq',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let valueFromReport = valueGetter(row);
                    return valueFromReport == filterItem.value;
                };
            },
            InputComponent: (props) => <TextField type="number" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: '>',
            value: 'gt',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let valueFromReport = valueGetter(row);
                    return valueFromReport > filterItem.value;
                };
            },
            InputComponent: (props) => <TextField type="number" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,

        },
        {
            label: '<',
            value: 'lt',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let valueFromReport = valueGetter(row);
                    return valueFromReport < filterItem.value;
                };
            },
            InputComponent: (props) => <TextField type="number" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,

        },
        {
            label: '>=',
            value: 'gte',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let valueFromReport = valueGetter(row);
                    return valueFromReport >= filterItem.value;
                };
            },
            InputComponent: (props) => <TextField type="number" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,

        },
        {
            label: '<=',
            value: 'lte',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let valueFromReport = valueGetter(row);
                    return valueFromReport <= filterItem.value;
                };
            },
            InputComponent: (props) => <TextField type="number" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: 'Not Equal',
            value: 'ne',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    let valueFromReport = valueGetter(row);
                    return valueFromReport != filterItem.value;
                };
            },
            InputComponent: (props) => <TextField type="number" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        }
    ]

    return numberFilter;

}

export default getNumberColumnFilters;