import { Tooltip, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { FileOpen } from "@mui/icons-material";

const ViewLogsColumn = ({ interviewReportId }) => {
	return (
		<Tooltip title="View Logs" arrow>
			<IconButton
				component={Link}
				to={`/superadmin/logs/${interviewReportId}`}
				className="hover:bg-sky-100"
				target="_blank"
			>
				<FileOpen color="primary" />
			</IconButton>
		</Tooltip>
	);
};
export default ViewLogsColumn;
