// import { useState } from "react";
// import logo from "./../assets/zinterview-logo-white.png";
// import heroImage from "./../assets/zi_heroimg.png";
import "./../styles/homePageStyles.css";
// const faqs = [
// 	{
// 		id: 1,
// 		question: "Q: How does Zinterview.ai work",
// 		answer: "A: Zinterview.ai uses advanced AI algorithms to conduct interviews, evaluate candidate performance, and provide detailed assessments. You can customize interviews based on skills and access interview recordings for a comprehensive review.",
// 	},
// 	{
// 		id: 2,
// 		question: "Q: Can Zinterview.ai be used for both technical and non-technical roles?",
// 		answer: "A: Absolutely! Zinterview.ai is versatile and can conduct interviews for a wide range of roles, including both technical and non-technical positions.",
// 	},
// 	{
// 		id: 3,
// 		question: "Q: How does ZInterview.ai benefit my company?",
// 		answer: "A: By leveraging AI-powered interviews, you can significantly decrease hiring times, increase interview capacity, and ensure that only the most promising candidates reach human interviewers. This efficiency can help you stay ahead in recruitment and showcase your company's commitment to innovation.",
// 	},
// ];
const Home = () => {
	let url = "https://zinterview.ai";

	return (
		<div className="w-screen h-screen overflow-hidden">
			<iframe src={url} title="Zinterview" className="w-screen h-screen" />;
		</div>
	);
	// const [showAccordion, setShowAccordion] = useState([false, false, false]);
	// const handleToggleAccordion = (e, i) => {
	// 	setShowAccordion((prev) => {
	// 		const newArr = [...prev];
	// 		newArr[i] = !newArr[i];
	// 		return newArr;
	// 	});
	// };
	// return (
	// 	<>
	// 		<nav class="bg-gradient-to-r from-blue-500 to-purple-500 p-4 px-12">
	// 			<div class="flex items-center justify-between nav-bar">
	// 				<div class="flex items-center">
	// 					<img src={logo} alt="zinterview.ai" class="w-64  mr-2" />
	// 				</div>
	// 				<ul class="flex space-x-4 list-none">
	// 					<li>
	// 						<a href="#home" class="text-white hover:text-gray-200 no-underline">
	// 							Home
	// 						</a>
	// 					</li>
	// 					<li>
	// 						<a href="#benefits" class="text-white hover:text-gray-200 no-underline">
	// 							Benefits
	// 						</a>
	// 					</li>
	// 					<li>
	// 						<a href="#features" class="text-white hover:text-gray-200 no-underline">
	// 							Features
	// 						</a>
	// 					</li>
	// 					<li>
	// 						<a href="#faqs" class="text-white hover:text-gray-200 no-underline">
	// 							FAQs
	// 						</a>
	// 					</li>
	// 				</ul>
	// 			</div>
	// 		</nav>

	// 		<div class="bg-gradient-to-r from-blue-500 to-purple-500 text-white text-center py-20 hero-section">
	// 			<h1 class="text-2xl sm:text-4xl font-bold mb-2">
	// 				Cut Hiring Time in Half: Zinterview.ai's AI-Driven Interviews
	// 			</h1>
	// 			<div class="text-center py-2">
	// 				<p class="text-sm sm:text-lg mb-4 text-white">
	// 					Optimize Your Hiring Process with AI-Enhanced Interviews
	// 				</p>
	// 			</div>
	// 			<a
	// 				href="https://forms.gle/4efuetdWpmk61w8T8"
	// 				rel="noreferrer"
	// 				target="_blank"
	// 				class="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-bold py-2 px-4 rounded hero-button no-underline"
	// 			>
	// 				Schedule a Demo
	// 			</a>
	// 		</div>
	// 		<div class="flex justify-center items-center">
	// 			<div class="max-w-5xl text-center py-4">
	// 				<img
	// 					src={heroImage}
	// 					alt="Zinterview.ai Product Screenshot"
	// 					class="rounded-lg shadow-lg hero-image"
	// 				/>
	// 			</div>
	// 		</div>
	// 		<div class="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-4 px-2 sm:px-40 font-bold text-lg text-center">
	// 			Zinterview.ai revolutionizes the recruitment process by enabling HR professionals to configure
	// 			and conduct AI-evaluated spoken interviews that assess candidates' suitability for different
	// 			roles and skill proficiencies.
	// 		</div>
	// 		<div id="benefits" class="py-8 sm:py-20 px-2 sm:px-10">
	// 			<div class="max-w-4xl mx-auto">
	// 				<div class="text-center mb-6">
	// 					<h2 class="text-2xl sm:text-4xl font-bold text-indigo-800">Benefits</h2>
	// 				</div>
	// 				<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
	// 					<div class="bg-white text-gray-800 p-4 sm:p-6 rounded-lg shadow-lg text-center benefit-panel">
	// 						<div class="flex items-center justify-center mb-2">
	// 							<i class="fas fa-stopwatch text-pink-500 text-3xl sm:text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-2">
	// 							Let AI conduct the interviews, reducing hiring times significantly.
	// 						</h3>
	// 					</div>
	// 					<div class="bg-white text-gray-800 p-4 sm:p-6 rounded-lg shadow-lg text-center benefit-panel">
	// 						<div class="flex items-center justify-center mb-2">
	// 							<i class="fas fa-chart-line text-pink-500 text-3xl sm:text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-2">
	// 							Increase your interview capacity, enabling you to assess more candidates
	// 							efficiently.
	// 						</h3>
	// 					</div>
	// 					<div class="bg-white text-gray-800 p-4 sm:p-6 rounded-lg shadow-lg text-center benefit-panel">
	// 						<div class="flex items-center justify-center mb-2">
	// 							<i class="fas fa-check text-pink-500 text-3xl sm:text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-2">
	// 							Send only the most promising applicants to human interviewers, saving valuable
	// 							resources.
	// 						</h3>
	// 					</div>
	// 					<div class="bg-white text-gray-800 p-6 rounded-lg shadow-lg text-center">
	// 						<div class="flex items-center justify-center mb-3">
	// 							<i class="fas fa-cogs text-pink-500 text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-3">
	// 							Harness the power of AI for interviews that are efficient, consistent, and
	// 							unbiased.
	// 						</h3>
	// 					</div>

	// 					<div class="bg-white text-gray-800 p-6 rounded-lg shadow-lg text-center">
	// 						<div class="flex items-center justify-center mb-3">
	// 							<i class="fas fa-users text-pink-500 text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-3">
	// 							Conduct interviews seamlessly for both tech and non-tech roles, expanding your
	// 							talent pool.
	// 						</h3>
	// 					</div>

	// 					<div class="bg-white text-gray-800 p-6 rounded-lg shadow-lg text-center">
	// 						<div class="flex items-center justify-center mb-3">
	// 							<i class="fas fa-lightbulb text-pink-500 text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-3">
	// 							Showcase your company as a leader in innovative recruitment, attracting top
	// 							talent.
	// 						</h3>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 		<div
	// 			id="features"
	// 			class="bg-gradient-to-r from-blue-500 to-purple-500 py-8 sm:py-20 px-2 sm:px-10"
	// 		>
	// 			<div class="max-w-4xl mx-auto">
	// 				<div class="text-center mb-6">
	// 					<h2 class="text-2xl sm:text-4xl font-bold text-white">Features</h2>
	// 				</div>
	// 				<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
	// 					<div class="bg-white text-gray-800 p-4 sm:p-6 rounded-lg shadow-lg text-center feature-panel">
	// 						<div class="flex items-center justify-center mb-2">
	// 							<i class="fas fa-robot text-yellow-500 text-3xl sm:text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-2">AI-Powered Interviews.</h3>
	// 						<p>
	// 							Our cutting-edge AI conducts interviews, ensuring a consistent and fair
	// 							evaluation process.
	// 						</p>
	// 					</div>
	// 					<div class="bg-white text-gray-800 p-4 sm:p-6 rounded-lg shadow-lg text-center feature-panel">
	// 						<div class="flex items-center justify-center mb-2">
	// 							<i class="fas fas fa-video text-yellow-500 text-3xl sm:text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-2">Access Interview Session Recording.</h3>
	// 						<p>
	// 							Review interview recordings to gain deeper insights into candidate
	// 							performance.
	// 						</p>
	// 					</div>
	// 					<div class="bg-white text-gray-800 p-4 sm:p-6 rounded-lg shadow-lg text-center feature-panel">
	// 						<div class="flex items-center justify-center mb-2">
	// 							<i class="fas fa-cogs text-yellow-500 text-3xl sm:text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-2">Skill-Based Interviewing.</h3>
	// 						<p>
	// 							Customize AI interviews to assess candidates based on their skills, not just
	// 							their resumes.
	// 						</p>
	// 					</div>
	// 					<div class="bg-white text-gray-800 p-6 rounded-lg shadow-lg text-center">
	// 						<div class="flex items-center justify-center mb-3">
	// 							<i class="fas fa-laptop-code text-yellow-500 text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-3">Tech and Non-Tech Roles</h3>
	// 						<p>
	// 							ZInterview.ai caters to a wide range of roles, from tech wizards to creative
	// 							minds.
	// 						</p>
	// 					</div>

	// 					<div class="bg-white text-gray-800 p-6 rounded-lg shadow-lg text-center">
	// 						<div class="flex items-center justify-center mb-3">
	// 							<i class="fas fa-poll-h text-yellow-500 text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-3">Comprehensive AI-Based Evaluation.</h3>

	// 						<p>
	// 							Receive detailed AI-generated evaluations, providing valuable insights into
	// 							each candidate.
	// 						</p>
	// 					</div>

	// 					<div class="bg-white text-gray-800 p-6 rounded-lg shadow-lg text-center">
	// 						<div class="flex items-center justify-center mb-3">
	// 							<i class="fas fa-trophy text-yellow-500 text-4xl"></i>
	// 						</div>
	// 						<h3 class="font-bold mb-3">AI-Driven Candidate Ranking.</h3>

	// 						<p>
	// 							ZInterview.ai ranks candidates based on their interview performance and
	// 							suitability for the role, helping you identify top talent quickly and
	// 							efficiently.
	// 						</p>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 		<div id="faqs" class="container mx-auto mt-8 p-2 sm:p-4">
	// 			<div class="text-center mb-6">
	// 				<h2 class="text-2xl sm:text-4xl font-bold text-indigo-800">FAQs</h2>
	// 			</div>
	// 			<div class="space-y-4">
	// 				{faqs.map((faq, i) => {
	// 					return (
	// 						<div
	// 							key={faq.id}
	// 							class="pb-2 sm:pb-4 cursor-pointer hover:bg-gray-200 p-2 sm:p-4"
	// 							onClick={(e) => handleToggleAccordion(e, i)}
	// 						>
	// 							<div class="flex items-center justify-between">
	// 								<h3 class="text-lg sm:text-xl font-semibold">{faq.question}</h3>
	// 								<i class="fas fa-chevron-down"></i>
	// 							</div>
	// 							<p class={`${!showAccordion[i] ? "hidden" : ""} mt-2`}>{faq.answer}</p>
	// 						</div>
	// 					);
	// 				})}
	// 			</div>
	// 		</div>
	// 		<footer class="bg-blue-900 text-white py-4 sm:py-6">
	// 			<div class="container mx-auto flex justify-between items-center">
	// 				<img src={logo} alt="zinterview.ai" class="w-32 mr-2 scale-110" />
	// 				<ul class="flex space-x-4 list-none">
	// 					<li>
	// 						<a href="#" class="text-white hover:text-gray-200 no-underline">
	// 							Home
	// 						</a>
	// 					</li>
	// 					<li>
	// 						<a href="#benefits" class="text-white hover:text-gray-200 no-underline">
	// 							Benefits
	// 						</a>
	// 					</li>
	// 					<li>
	// 						<a href="#features" class="text-white hover:text-gray-200 no-underline">
	// 							Features
	// 						</a>
	// 					</li>
	// 					<li>
	// 						<a href="#faqs" class="text-white hover:text-gray-200 no-underline">
	// 							FAQs
	// 						</a>
	// 					</li>
	// 				</ul>
	// 			</div>
	// 		</footer>
	// 	</>
	// );
};

export default Home;
