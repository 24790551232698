import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Modal } from '@mui/material';

const renderConfirmationDialog = (
    label,
    onClose,
    onConfirm,
    icons = true,
    timer = 0,
    header = "Confirmation"
) => {
    const element = document.getElementById("ConfirmationDialogContainer");
    if (element) {
        const root = ReactDOM.createRoot(element);
        root.render(<ConfirmationDialog
            label={label}
            onClose={onClose}
            onConfirm={onConfirm}
            icons={icons}
            timer={timer}
            header={header}
        />);
        return () => root.unmount();
    }
}

const ConfirmationDialog = ({
    label = "",
    onClose = () => { },
    onConfirm = () => { },
    icons = true,
    timer = 0,
    header = "Confirmation"
}) => {
    const [open, setOpen] = React.useState(true);
    const [count, setCount] = React.useState(timer);

    const onClickConfirm = () => {
        onConfirm();
        setOpen(false);
    };

    const onDiscard = () => {
        onClose();
        setOpen(false);
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCount((prev) => prev - 1000);
        }, 1000);
        if (count < 1000) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [count]);

    return (
        <Modal
            open={open}
            onClose={onDiscard}
            aria-labelledby="confirmation-dialog"
            aria-describedby="confirmation-dialog-description"
        >
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "30vw",
                    boxShadow: 24,
                    backgroundColor: "white",
                    // padding: "1rem",
                    borderRadius: "0.5rem",
                }}
            >
                <div
                    style={{
                        padding: "1rem",
                        // w-96
                        // width: "22rem",
                    }}
                >
                    <h2
                        style={{
                            fontSize: "1.25rem",
                            fontWeight: "600",
                            marginBottom: "1.25rem"
                        }}
                    >{header}</h2>
                    <p
                        style={{
                            marginBottom: "1.5rem"
                        }}
                    >{label}</p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1rem"
                        }}
                    >
                        <button
                            style={{
                                backgroundColor: "#ccc",
                                cursor: "pointer",
                                padding: "0.5rem 1rem",
                                borderRadius: "0.25rem",
                                border: "none",
                            }}
                            onClick={onDiscard}
                        >Cancel</button>
                        <button
                            style={{
                                backgroundColor: "#007bff",
                                color: "#fff",
                                cursor: count > 0 ? "not-allowed" : "pointer",
                                padding: "0.5rem 1rem",
                                borderRadius: "0.25rem",
                                border: "none",
                                opacity: count > 0 ? 0.5 : 1
                            }}
                            onClick={onClickConfirm}
                            disabled={count > 0}
                        >Confirm{count > 0 && (<span id="countdown">in {count / 1000}s</span>)}</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export { renderConfirmationDialog };