import { getFormattedDateTime3 } from "../../../utilities/utilityFunctions";

const DateRenderer = ({params}) => {
	return (
		<div className="flex items-center w-full h-full">
			<p>{getFormattedDateTime3(params.row.createdAt)}</p>
		</div>
	);
};

export default DateRenderer;
