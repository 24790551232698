import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "@mui/material/Slider";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { sendUserMessageToCreateJobDescription } from "../utilities/zinterviewgptApi";
import Markdown from "react-markdown";
import { checkIfInvalidOrUnauthorized } from "./../utilities/utilityFunctions";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../features/auth/authSlice";
import { createOpeningAction } from "../features/createOpening/createOpeningSlice";
import "./../styles/openingStyles.css";
import CachedIcon from "@mui/icons-material/Cached";
import { DialogTitle } from "@mui/material";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

// const messagesSchema = z
// 	.object({
// 		role: z.enum(["assistant", "user", "system"], {
// 			required_error: "Role from the openai schema cannot be empty.",
// 		}),
// 		content: z.string().nullish(),
// 	})
// 	.passthrough();

const OpeningType = () => {
	const location = useLocation();

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		watch,
	} = useForm({
		defaultValues: {
			"user-message": "",
			experienceRange: [0, 2],
		},
	});

	const searchQuery = new URLSearchParams(location.search);
	const openingTypeFromSearchParams = searchQuery.get("openingType");
	const defaultOpeningType =
		openingTypeFromSearchParams === "non-technical"
			? 0
			: openingTypeFromSearchParams === "technical"
				? 1
				: "";
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [openingType, setOpeningType] = useState(defaultOpeningType); // 1 -> technical , 0 -> non technical
	const [dialogOpen, setDialogOpen] = useState(
		defaultOpeningType === 1 || defaultOpeningType === 0 ? true : false,
	);
	const [messages, setMessages] = useState([]);
	const [isFetchingResults, setIsFetchingResults] = useState(false);
	const { organizationId } = useParams();
	const { user } = useSelector((state) => state.auth);
	const restricted = user?.restricted;
	const trialExpired = user?.trialExpired;

	useEffect(() => {
		if (!dialogOpen || (messages.length > 0 && messages.length % 2 === 0)) {
			// to clear user-message field, else it will still keep hold of the previous value
			reset();
		}
	}, [dialogOpen, reset, messages]);

	const handleTechOpeningButtonClick = (e) => {
		setOpeningType(1);
		setDialogOpen(true);
		const url = new URL(window.location.href);
		url.searchParams.set("openingType", "technical");
		window.history.replaceState(null, "", url.href);
	};
	const handleNonTechOpeningButtonClick = (e) => {
		setOpeningType(0);
		setDialogOpen(true);
		const url = new URL(window.location.href);
		url.searchParams.set("openingType", "non-technical");
		window.history.replaceState(null, "", url.href);
	};

	const handleDialogClose = () => {
		if (!isFetchingResults) {
			setDialogOpen(false);
			setMessages([]);
			setOpeningType("");
			const url = new URL(window.location.href);
			url.searchParams.delete("openingType");
			window.history.replaceState(null, "", url.href);
		} else {
			toast.warn("Please wait for the current request to be finished!");
		}
	};

	const handleFormSubmit = async (data) => {
		let content = data["user-message"]?.trim();
		let [minExperience, maxExperience] = data["experienceRange"];
		if (messages.length === 0) {
			content = `Please create a job opening with the following job description:
JOB DESCRIPTION: """
${content}
The experience required for the job is ${minExperience} - ${maxExperience}.
"""
`;
		}
		const userMessage = {
			role: "user",
			content: content,
		};
		setMessages((prev) => {
			return [...prev, userMessage];
		});
		try {
			setIsFetchingResults(true);
			const response = await sendUserMessageToCreateJobDescription(
				[...messages, userMessage],
				openingType,
			);
			if (response.tool_calls) {
				const tool_calls = response.tool_calls;
				const toolCall = tool_calls[0];
				const functionData = toolCall?.function;
				const functionName = functionData.name;
				const functionJSONData = functionData.arguments;
				const deserializedOpeningData = JSON.parse(functionJSONData);

				if (deserializedOpeningData) {
					if (functionName === "create_job_opening" && openingType === 0) {
						// nontech
						const {
							coreSkills,
							jobRequirementsAndResponsibilities,
							minExperience,
							maxExperience,
							title,
							specialInstructions,
						} = deserializedOpeningData;
						const experienceRange = [minExperience, maxExperience];
						const finalCoreSkills = coreSkills.map((coreSkill) => ({ value: coreSkill }));
						const finalJobResponsibilities = jobRequirementsAndResponsibilities.map(
							(jobRequirementsAndResponsibility) => ({
								value: jobRequirementsAndResponsibility,
							}),
						);
						dispatch(
							createOpeningAction({
								title,
								experienceRange,
								coreSkills: finalCoreSkills,
								jobRequirementsAndResponsibilities: finalJobResponsibilities,
								interviewGuidelines: specialInstructions,
							}),
						);
						if (!organizationId) navigate("/admin/create-opening-from-jd/0");
						else navigate(`/superadmin/admin/${organizationId}/create-opening-from-jd/0`);
					} else if (functionName === "create_job_opening" && openingType === 1) {
						// tech
						const {
							skillsGroups,
							minExperience,
							maxExperience,
							title,
							specialInstructions,
							jobRequirementsAndResponsibilities,
						} = deserializedOpeningData;
						const finalSkillsGroups = skillsGroups.map((skillGroup) => {
							const { skillGroupName, skills, criteria } = skillGroup;
							const finalSkills = skills.map((skill) => ({ value: skill }));
							return { skillGroupName, criteria, skills: finalSkills };
						});
						const finalJobResponsibilities = jobRequirementsAndResponsibilities.map(
							(jobRequirementsAndResponsibility) => ({
								value: jobRequirementsAndResponsibility,
							}),
						);
						const experienceRange = [minExperience, maxExperience];
						dispatch(
							createOpeningAction({
								title,
								experienceRange,
								skillsGroups: finalSkillsGroups,
								interviewGuidelines: specialInstructions,
								jobRequirementsAndResponsibilities: finalJobResponsibilities,
							}),
						);
						if (!organizationId) navigate("/admin/create-opening-from-jd/1");
						else navigate(`/superadmin/admin/${organizationId}/create-opening-from-jd/1`);
					}
				}
			} else {
				setMessages((prev) => [...prev, response]);
			}
		} catch (error) {
			console.log("error caught in Openingtype app");
			console.log(error);
		} finally {
			setIsFetchingResults(false);
		}
	};
	const latestAssitantMessage =
		messages.length > 1 ? messages.findLast((message) => message?.role === "assistant") : undefined;
	let roleOfMessageToRender = latestAssitantMessage?.role;

	return restricted ? (
		<Box
			className={`flex flex-col items-center justify-center bg-gray-100`}
			style={{
				height: "calc(100vh - var(--navbar-height))",
			}}
		>
			<Box id="panel2" className="bg-white p-6 rounded-lg shadow-lg w-4/5 md:w-1/2 lg:w-1/2">
				<Typography
					variant="h4"
					className={`text-center font-bold mb-6 ${isMobile ? "text-2xl" : "text-4xl"}`}
				>
					{trialExpired ? "Trial Period Ended" : "Payment Overdue"}
				</Typography>
				<Typography
					variant="h6"
					className={`text-justify font-medium ${isMobile ? "text-sm" : "text-lg"}`}
				>
					{trialExpired
						? "Your trial period has ended. To create new opening and enjoy uninterrupted access to all features, please contact your account manager to activate your account."
						: "Your account has been restricted due to an overdue payment. Please complete the payment or contact your account manager to resolve the restriction."}
				</Typography>
			</Box>
		</Box>
	) : (
		<>
			<Box
				className={` bg-gray-200 opening-type-new`}
				style={{
					height: "calc(100vh - var(--navbar-height))",
				}}
			>
				<Typography variant="h4" component={"h1"} textAlign={"center"} className="py-8">
					Select an opening type
				</Typography>

				<Stack className="flex-row flex-wrap items-center justify-center gap-[2rem]">
					<OpeningBox text={"Technical"} handleOpeningTypeClick={handleTechOpeningButtonClick} />
					<OpeningBox
						text={"Non Technical"}
						handleOpeningTypeClick={handleNonTechOpeningButtonClick}
					/>
				</Stack>
			</Box>
			<Dialog
				fullWidth={true}
				maxWidth={"lg"}
				open={dialogOpen}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "rgba(0, 0, 0, 0.4	)", // Adjust opacity as needed
					},
				}}
				className="opening-create-from-jd-dialog text-sm "
				scroll="paper"
				onClose={handleDialogClose}
				PaperProps={{
					component: "form",
					id: "generate-opening-with-gpt",
					onSubmit: handleSubmit(handleFormSubmit),
					elevation: 1,
					sx: {
						borderRadius: "0.5rem",
						padding: "0.8rem",
					},
				}}
			>
				<DialogTitle className="text-2xl font-semibold text-black pb-0">
					Create Opening - {openingType === 1 ? "Technical" : "Non-Technical"}
				</DialogTitle>

				<DialogContent className=" flex max-h-96  flex-col gap-1 overflow-y-auto bg-white py-3">
					<IconButton
						aria-label="close"
						onClick={handleDialogClose}
						className="absolute scale-75 right-3 top-3 m-0 cursor-pointer border-none p-2 outline-none"
					>
						<CloseIcon />
					</IconButton>
					{(messages.length === 0 || (messages.length === 1 && isFetchingResults)) && (
						<InitialRenderer
							control={control}
							errors={errors}
							isFetchingResults={isFetchingResults}
							watch={watch}
							register={register}
						/>
					)}
					{roleOfMessageToRender === "assistant" && (
						<AssistantMessageRenderer latestAssitantMessage={latestAssitantMessage} />
					)}
				</DialogContent>
				<DialogActions
					className="w-full border-gray-300 bg-gray-50 px-5 py-3 "
					sx={{
						borderTop: "1px solid rgb(209 213 219 / 1) ",
					}}
				>
					<div className=" flex w-full flex-col gap-2">
						{roleOfMessageToRender === "assistant" && (
							<div>
								<input
									disabled={isFetchingResults}
									type="text"
									id="user-message-2"
									{...register("user-message", {
										validate: (value) => {
											return value.trim() === ""
												? "Please enter a response before submitting"
												: true;
										},
									})}
									autoFocus
									className="w-full rounded border-[1px] border-solid border-gray-300 p-2 "
									placeholder="Provide your input or confirm details..."
								/>
								{errors?.["user-message"]?.message && (
									<p className="text-sm text-red-400">
										{errors?.["user-message"]?.message}
									</p>
								)}
							</div>
						)}
						{isFetchingResults ? (
							<div className="flex flex-row items-center justify-start ">
								<CircularProgressWithLabel isFetchingResults={isFetchingResults} />
							</div>
						) : (
							<div className="flex flex-row items-center gap-3">
								<Button
									form="generate-opening-with-gpt"
									type="submit"
									disabled={isFetchingResults}
									className="cursor-pointer rounded border-none normal-case  bg-blue-500 px-4 text-white outline-none transition duration-300 hover:bg-blue-600 focus:bg-blue-600 disabled:opacity-50"
									onClick={handleSubmit(handleFormSubmit)}
								>
									Submit
								</Button>
								{roleOfMessageToRender === "assistant" && (
									<Button
										type="button"
										disabled={isFetchingResults}
										className="cursor-pointer rounded  normal-case border-none bg-yellow-400 px-4 text-white outline-none transition duration-300 hover:bg-yellow-500 focus:bg-yellow-500 disabled:opacity-50"
										onClick={(e) => {
											e.preventDefault();
											setValue("user-message", "Looks good.");
											handleSubmit(handleFormSubmit)();
										}}
									>
										Looks Good
									</Button>
								)}
								{roleOfMessageToRender === "assistant" && (
									<Button
										startIcon={<CachedIcon />}
										type="button"
										disabled={isFetchingResults}
										className="cursor-pointer rounded normal-case border-none bg-green-500 px-4 text-white outline-none transition duration-300 hover:bg-green-600 focus:bg-green-600 disabled:opacity-50"
										onClick={(e) => {
											e.preventDefault();
											setValue(
												"user-message",
												"Please regenerate your previous response and provide a better response.",
											);
											handleSubmit(handleFormSubmit)();
										}}
									>
										Regenerate
									</Button>
								)}
							</div>
						)}
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default OpeningType;

const OpeningBox = ({ text, handleOpeningTypeClick }) => {
	return (
		<Paper
			elevation={3}
			className="opening-type-container flex h-[200px] w-[200px] items-center justify-center"
		>
			<Button className="h-full w-full" onClick={handleOpeningTypeClick}>
				<Typography variant="body1" className="opening-type-title">
					{text}
				</Typography>
			</Button>
		</Paper>
	);
};

const InitialRenderer = ({ control, isFetchingResults, errors, watch, register }) => {
	const experienceRange = watch("experienceRange");
	return (
		<div className="flex flex-col gap-2">
			<div>
				<label htmlFor="user-message" className="mb-2 block text-gray-700">
					Type or paste your JD here:
				</label>
				<textarea
					autoFocus
					disabled={isFetchingResults}
					id="user-message"
					className="w-full resize-none rounded-md border p-2"
					rows="11"
					{...register("user-message", {
						validate: (value) => {
							return value.trim() === ""
								? "Please enter some text or job description before submitting"
								: true;
						},
					})}
				></textarea>
				{errors?.["user-message"]?.message && (
					<p className="text-sm text-red-400">{errors?.["user-message"]?.message}</p>
				)}
			</div>
			<div>
				<label htmlFor="experienceRange" className="mb-2 block text-gray-700">
					Experience Range &nbsp;
					{`(${experienceRange[0]} - ${experienceRange[1]} ${experienceRange[0] === 0 && experienceRange[1] === 1 ? "year" : "years"}) `}
				</label>
				<Controller
					control={control}
					name="experienceRange"
					render={({ field: { onChange, onBlur, value, ref } }) => {
						return (
							<Slider
								getAriaLabel={() => "Experience Range for job"}
								value={value}
								valueLabelDisplay="auto"
								onChange={onChange}
								onBlur={onBlur}
								max={15}
								disabled={isFetchingResults}
								min={0}
								step={1}
								className="text-blue-500"
								valueLabelFormat={(data) => {
									return data === 15 ? "15+" : data;
								}}
								// marks={[{ value: 15, label: "15+" }]}
							/>
						);
					}}
				/>
			</div>
		</div>
	);
};

const AssistantMessageRenderer = ({ latestAssitantMessage }) => {
	const content = latestAssitantMessage?.content;
	const startOfContent = content?.indexOf("<content>");
	const endOfContent = content?.indexOf("</content>");
	const mainContent =
		startOfContent >= 0 && endOfContent >= 0 ? (
			<div className="flex flex-col gap-3">
				<Markdown>{content.slice(0, startOfContent)}</Markdown>
				<div className="opening-dialog-content rounded bg-gray-100 p-3 leading-6">
					<Markdown>{content.slice(startOfContent + 9, endOfContent)}</Markdown>
				</div>
				<Markdown>{content.slice(endOfContent + 10)}</Markdown>
			</div>
		) : undefined;
	return (
		<div className="flex flex-1 flex-col gap-2 ">
			<div className="opening-dialog-content flex-1 px-3 py-2">
				{mainContent ? (
					mainContent
				) : (
					<Markdown className="leading-6">{latestAssitantMessage?.content}</Markdown>
				)}
			</div>
		</div>
	);
};

export function CircularProgressWithLabel() {
	return (
		<div role="status">
			<svg
				aria-hidden="true"
				className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-500"
				style={{
					animationDuration: "3s",
				}}
				viewBox="0 0 100 101"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
					fill="#e1e1e1"
				/>
				<path
					d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
					fill="currentFill"
				/>
			</svg>
			<span className="sr-only">Loading...</span>
		</div>
	);
}
