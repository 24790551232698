import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import organizationService from "./organizationService";

let organization = null;
try {
	const cachedOrgData = JSON.parse(sessionStorage.getItem("organization"));
	if (cachedOrgData) {
		organization = cachedOrgData;
	}
} catch (e) {
	organization = null;
}

const initialState = {
	isLoading: "",
	isSuccess: "",
	isError: "",
	message: "",
	organization: organization,
};

export const getOrganizationName = createAsyncThunk(
	"organization/getOrganizationDetails",
	async (organizationId, thunkAPI) => {
		try {
			const res = await organizationService.getOrganizationNameFromAPI(organizationId);
			return res;
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			thunkAPI.rejectWithValue(message);
		}
	},
);

const organizationSlice = createSlice({
	name: "organization",
	initialState,
	reducers: {
		reset: (state, action) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = false;
			state.message = "";
		},
		resetOrganization: (state, action) => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOrganizationName.pending, (state, payload) => {
				state.isLoading = true;
			})
			.addCase(getOrganizationName.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = payload?.message;
				state.organization = payload?.data;
			})
			.addCase(getOrganizationName.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = false;
				state.isError = true;
				state.message = payload;
				state.organization = null;
			});
	},
});
const organizationReducer = organizationSlice.reducer;
export default organizationReducer;
export const { reset, resetOrganization } = organizationSlice.actions;
