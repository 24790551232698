import React, { useState } from "react";
import { toast } from "react-toastify";
import { Box, Modal } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import "../../pages/TabularOpeningEvaluations.css";
import EmailTemplateEditor from "./EmailTemplateEditor";
import CCInviteTo from "./CCInviteTo";
import ScheduleHeader from "./ScheduleHeader";
import InterviewScheduleForm from "./InterviewScheduleForm";
import { updateBulkScheduling } from "../../utilities/interviewReportsApi";
import { addCandidate, updateCandidate } from "../../app/actions/candidates/candidate";
import { getCandidateIds } from "../../utilities/candidateUtils";
import { getEmailBody } from "../../utilities/candidateUtils";
import { Button } from "@mui/material";
import { renderConfirmationDialog } from "../confirmation/ConfirmationDialog";
import { getFormattedDateTime4 } from "../../utilities/utilityFunctions";

const InterviewScheduleComponent = ({ open, scheduleType, interviewReport, onClose }) => {
	const [joinEarly, setJoinEarly] = useState(
		scheduleType === 1 ? interviewReport?.joinEarly ?? true : true,
	);
	const [joinOption, setJoinOption] = useState(interviewReport?.joinOption ?? "onTime");
	const [timeWindow, setTimeWindow] = useState(interviewReport?.timeWindow ?? 24);
	const [selectedDate, setSelectedDate] = useState(() => {
		try {
			const initialDate =
				scheduleType === 1 ? new Date(interviewReport?.schedule) ?? new Date() : new Date();
			return initialDate > new Date() ? initialDate : new Date();
		} catch {
			return new Date();
		}
	});
	const [isCCInviteActive, setIsCCInviteActive] = useState(false);
	const [ccEmails, setCcEmails] = useState([]);
	const [isInterviewSchedulingLoading, setIsInterviewSchedulingLoading] = useState(false);
	const [isEmailBodyVisible, setEmailBodyVisible] = useState(false);
	const { organizationName } = useSelector((state) => state?.auth?.user);
	const { selectedOpening: openingData } = useSelector((state) => state.openingDetails);
	const [emailBody, setEmailBody] = useState(getEmailBody(openingData, organizationName));
	const dispatch = useDispatch();
	const {
		candidates: { selectedCandidate, candidates },
	} = useSelector((state) => state);

	const handleBulkSchedule = async () => {
		try {
			setIsInterviewSchedulingLoading(true);
			let selectedCandidateIds = [];
			if (scheduleType === 0 || scheduleType === 1) {
				selectedCandidateIds.push(interviewReport._id);
			} else {
				selectedCandidateIds = getCandidateIds(candidates, selectedCandidate);
			}
			if (selectedCandidateIds.length + ccEmails.length === 0) {
				toast.warn("Candidate not found, or you have selected a completed interview candidate");
				return;
			}
			const formattedDate = dayjs(selectedDate).toISOString();
			const interviewBaseUrl = `${window.location.protocol}//${window.location.host}/interview/${openingData._id}/start`;
			const formData = {
				selectedCandidateIds,
				ccEmails,
				schedule: formattedDate,
				openingId: openingData._id,
				interviewBaseUrl,
				joinEarly,
				joinOption,
				timeWindow,
				emailBody,
			};
			const response = await updateBulkScheduling(formData);
			const updatedInterviewReportsData = response?.interviewReportsData;
			const interviewReportsMap = new Map(candidates.map((report) => [report._id, report]));
			updatedInterviewReportsData.forEach((report) => {
				if (interviewReportsMap.has(report._id)) {
					dispatch(updateCandidate(report));
				} else {
					dispatch(addCandidate(report));
				}
			});
			toast.success(response?.message);
		} catch (error) {
			console.error("Error bulkScheduling interview:", error);
			toast.warn(error.message);
		} finally {
			setIsInterviewSchedulingLoading(false);
			onClose();
		}
	};

	function toggleEmailBodyVisibility() {
		setEmailBodyVisible(!isEmailBodyVisible);
	}

	const submit = () => {
		try {
			if (!selectedDate) {
				toast.warning("Please select an interview date.");
				return;
			}
			if (new Date() > new Date(selectedDate)) {
				toast.warning("The interview date cannot be in the past. Please select a future date.");
				return;
			}
			if (joinOption === "timeWindow") {
				if (timeWindow.length === 0) {
					toast.warning("Time window cannot be empty");
					return;
				}
				const timeWindowValue = parseFloat(timeWindow);
				if (isNaN(timeWindowValue) || timeWindowValue <= 0 || timeWindowValue > 168) {
					toast.warning("Please select a proper time window.");
					return;
				}
			}
			renderConfirmationDialog(
				"Are you sure you want to schedule?",
				() => null,
				handleBulkSchedule,
				true,
				3000,
			);
		} catch (e) {
			toast.warn(e?.message || "Something went wrong.");
		}
	};

	return (
		<>
			{isEmailBodyVisible ? (
				<EmailTemplateEditor
					emailBody={emailBody}
					toggleEmailBodyVisibility={toggleEmailBodyVisibility}
					setEmailBody={setEmailBody}
				/>
			) : (
				<Modal
					open={open}
					onClose={() => onClose()}
					aria-labelledby="schedule-interview-title"
					aria-describedby="schedule-interview-description"
				>
					<Box className="bg-gray-100 p-8 rounded-lg shadow-lg max-w-[890px] w-full mx-auto overflow-hidden mt-5 relative font-medium max-h-[95vh] flex flex-col">
						<div className="sticky top-0 bg-inherit z-10">
							<ScheduleHeader scheduleType={scheduleType} onClose={onClose} />
						</div>

						<div className="flex-1 overflow-y-auto mt-4">
							<InterviewScheduleForm
								selectedDate={selectedDate}
								setSelectedDate={setSelectedDate}
								joinEarly={joinEarly}
								setJoinEarly={setJoinEarly}
								joinOption={joinOption}
								setJoinOption={setJoinOption}
								timeWindow={timeWindow}
								setTimeWindow={setTimeWindow}
								isCCInviteActive={isCCInviteActive}
								setIsCCInviteActive={setIsCCInviteActive}
								isEmailBodyVisible={isEmailBodyVisible}
								toggleEmailBodyVisibility={toggleEmailBodyVisibility}
							/>

							<CCInviteTo
								isCCInviteActive={isCCInviteActive}
								ccEmails={ccEmails}
								setCcEmails={setCcEmails}
							/>
						</div>
						<div className="sticky bottom-0 bg-inherit z-10 pt-2">
							<Button
								onClick={submit}
								className="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
								style={{ textTransform: "none" }}
								disabled={isInterviewSchedulingLoading}
							>
								{!isInterviewSchedulingLoading ? (
									<>Confirm Interview for {getFormattedDateTime4(selectedDate)}</>
								) : (
									<>
										Please wait, we are scheduling the interview for the candidate at{" "}
										{getFormattedDateTime4(selectedDate)}...{" "}
										<i className="fas fa-circle-notch fa-spin mr-1"></i>
									</>
								)}
							</Button>
						</div>
					</Box>
				</Modal>
			)}
		</>
	);
};
export default InterviewScheduleComponent;
