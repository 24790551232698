import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import {
	saveUserDetailsForAnInterivewByAdmin,
	updateCandidateDetails,
} from "../utilities/interviewReportsApi";
import { updateCandidate, addCandidate as addCandidateAction } from "../app/actions/candidates/candidate";
import { useDispatch } from "react-redux";
import { DropDownColorIdentifier } from "../pages/CreateUpdateOpening/CustomFieldTypes";
import { CountrySelector } from "./country-selector/country-selector";
import { isValidPhoneNumber } from "../utilities/validation";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 450,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: "10px",
};

const AddEditCandidateModal = ({
	isEditing = false,
	candidate = {},
	open = false,
	onClose = () => {},
	finalCustomFields = [],
	isResumeRequired = false,
	openingId,
	candidateId = null,
}) => {
	const [userDetails, setUserDetails] = React.useState(candidate);
	const [savingUserDeets, setSavingUserDeets] = React.useState(false);
	const [resume, setResume] = React.useState(null);
	const dispatch = useDispatch();

	const handleUserDetailsChange = (e) => {
		const { name, value } = e.target;
		setUserDetails((prev) => {
			return { ...prev, [name]: value };
		});
	};

	function handleChangeCustomFields(event, customFieldId) {
		let textValue = event.target.value;
		setUserDetails((prev) => {
			return {
				...prev,
				customFieldsData: {
					...prev.customFieldsData,
					[customFieldId]: textValue,
				},
			};
		});
	}

	const isValidEmail = (email) => {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		return emailRegex.test(email);
	};

	const handleUserDetailsSubmit = async (e) => {
		e.preventDefault();
		setSavingUserDeets(true);
		if (
			userDetails.firstName.trim() !== "" &&
			userDetails.lastName.trim() !== "" &&
			userDetails.email.trim() !== ""
		) {
			if (userDetails.email.trim() === "" || !isValidEmail(userDetails.email)) {
				toast.error("Invalid email address");
				setSavingUserDeets(false);
				return;
			}

			if (userDetails.phoneNumber.trim() !== "" && !isValidPhoneNumber(userDetails.phoneNumber)) {
				toast.error("Phone number must be between 6 and 11 digits and contain only numbers.");
				setSavingUserDeets(false);
				return;
			}

			const formData = new FormData();
			for (let key in userDetails) {
				if (key === "customFieldsData") {
					continue;
				}
				if (key === "email") {
					userDetails[key] = userDetails[key]?.toLowerCase();
				}
				if (typeof userDetails[key] === "object" && userDetails[key] !== null) {
					formData.append(key, JSON.stringify(userDetails[key]));
				} else {
					formData.append(key, userDetails[key]);
				}
			}

			if (resume !== null) {
				formData.append("resume", resume);
			}

			// if (params.openingId) {
			formData.append("openingId", openingId);
			// }

			let res;
			if (isEditing) {
				formData.append("candidateId", candidateId);
				res = await updateCandidateDetails(formData);
			} else {
				// Existing logic to add a new candidate
				formData.append("customFieldsData", JSON.stringify(userDetails?.customFieldsData));
				res = await saveUserDetailsForAnInterivewByAdmin(formData);
			}

			if (res?.message && res?.data) {
				toast.success(res.message ? res.message : "Details saved successfully.");
				// setUserDetails(initialUserDetails);
				setResume(null);
				// setAddCandidate(false);
				// setIsEditing(false);
				// setEditingCandidateId(null);
				onClose();
				dispatch(isEditing ? updateCandidate(res.data) : addCandidateAction(res.data));
			} else if (res?.failed && res?.message) {
				toast.error(res.message);
			}
		} else {
			toast.error("First Name, Last Name and Email are required.");
		}
		setSavingUserDeets(false);
	};

	const customFieldsData = userDetails?.customFieldsData;

	const handleResumeUpload2 = (e) => {
		const file = e.target.files[0];
		if (file.type !== "application/pdf") {
			toast.error("Only PDF files are supported.");
			setResume(null);

			// Reset the file input by creating a new form and replacing the old input
			const oldInput = document.getElementById("resumeU");
			const newInput = document.createElement("input");

			newInput.type = "file";
			newInput.id = oldInput.id;
			newInput.name = oldInput.name;
			newInput.className = oldInput.className;
			newInput.accept = oldInput.accept;

			// Correctly assign the event handler
			newInput.addEventListener("change", handleResumeUpload2);

			// Replace the old input with the new one
			oldInput.parentNode.replaceChild(newInput, oldInput);

			return;
		}
		setResume(file);
	};

	return (
		<Modal
			open={open}
			onClose={() => {
				setUserDetails(candidate);
				onClose();
			}}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
			disableEscapeKeyDown={savingUserDeets}
		>
			<Box sx={modalStyle}>
				<Typography
					id="modal-title"
					variant="h6"
					component="h2"
					textAlign="center"
					className="pt-4 pb-2"
					style={{
						borderBottom: "1px solid #E5E7EB",
					}}
				>
					{isEditing ? "Edit Candidate" : "Add Candidate"}
				</Typography>
				<form onSubmit={handleUserDetailsSubmit}>
					<div
						style={{
							maxHeight: "75vh",
							overflowY: "auto",
						}}
						className="px-8 pt-2 mt-4"
					>
						<Box className="flex space-x-4 mb-4">
							<div className="w-1/2">
								<label
									htmlFor="modal-firstName"
									className="block text-sm font-medium text-gray-600 mb-2"
								>
									First Name
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #E5E7EB",
										},
									}}
									color="black"
									size="small"
									required
									value={userDetails.firstName}
									onChange={handleUserDetailsChange}
									type="text"
									name="firstName"
									fullWidth
									disabled={savingUserDeets}
								/>
							</div>
							<div className="w-1/2">
								<label
									htmlFor="modal-lastName"
									className="block text-sm font-medium text-gray-600 mb-2"
								>
									Last Name
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									color="black"
									size="small"
									required
									value={userDetails.lastName}
									onChange={handleUserDetailsChange}
									type="text"
									name="lastName"
									fullWidth
									disabled={savingUserDeets}
								/>
							</div>
						</Box>
						<Box className="mb-4">
							<label
								htmlFor="modal-preferredName"
								className="block text-sm font-medium text-gray-600 mb-2"
							>
								Preferred Name
							</label>
							<TextField
								sx={{
									"& fieldset": {
										border: "1px solid #F2F3F5",
									},
								}}
								color="black"
								size="small"
								// required
								value={userDetails.preferredName}
								onChange={handleUserDetailsChange}
								type="text"
								name="preferredName"
								fullWidth
								disabled={savingUserDeets}
							/>
						</Box>
						<Box className="mb-4">
							<label
								htmlFor="modal-email"
								className="block text-sm font-medium text-gray-600 mb-2"
							>
								Email Address
							</label>
							<TextField
								sx={{
									"& fieldset": {
										border: "1px solid #F2F3F5",
									},
								}}
								color="black"
								size="small"
								required
								value={userDetails.email}
								onChange={handleUserDetailsChange}
								type="email"
								name="email"
								fullWidth
								disabled={savingUserDeets}
							/>
						</Box>
						<Box className="mb-4">
							<label
								htmlFor="modal-experience"
								className="block text-sm font-medium text-gray-600 mb-2"
							>
								Experience in Years
							</label>
							<TextField
								sx={{
									"& fieldset": {
										border: "1px solid #F2F3F5",
									},
								}}
								color="black"
								size="small"
								// required
								value={userDetails.experience}
								onChange={handleUserDetailsChange}
								type="number"
								name="experience"
								fullWidth
								disabled={savingUserDeets}
							/>
						</Box>
						<Box className="mb-4">
							<CountrySelector
								value={userDetails.country}
								onChange={(newValue) => {
									handleUserDetailsChange({
										target: { name: "country", value: newValue },
									});
								}}
								disabled={savingUserDeets}
							/>
						</Box>
						<Box className="mb-4">
							<label
								htmlFor="modal-phoneNumber"
								className="block text-sm font-medium text-gray-600 mb-2"
							>
								Phone Number
							</label>
							<TextField
								sx={{
									"& fieldset": {
										border: "1px solid #F2F3F5",
									},
								}}
								color="black"
								size="small"
								// required
								value={userDetails.phoneNumber}
								onChange={handleUserDetailsChange}
								type="text"
								name="phoneNumber"
								fullWidth
								disabled={savingUserDeets}
							/>
						</Box>
						{!isEditing &&
							finalCustomFields?.map((customField) => {
								const { type, fieldName, config, _id } = customField;
								return (
									Object.keys(customField)?.length && (
										<div key={_id}>
											{type === 0 && (
												<>
													<label
														htmlFor={`modal-${fieldName}`}
														className="block text-sm font-medium text-gray-600 mb-2"
													>
														{fieldName}
													</label>
													<TextField
														className="mb-4"
														sx={{
															"& fieldset": {
																border: "1px solid #E5E7EB",
															},
															width: "320px",
														}}
														color="black"
														value={
															customFieldsData.hasOwnProperty(_id)
																? customFieldsData[_id]
																: config?.defaultValue
														}
														size="small"
														onChange={(event) => {
															handleChangeCustomFields(event, _id);
														}}
													/>
												</>
											)}
											{type === 1 && (
												<>
													<FormControl fullWidth>
														<InputLabel
															size="small"
															id="demo-simple-select-label"
														>
															{`Select ${fieldName}`}
														</InputLabel>
														<Select
															className="mb-4"
															labelId="demo-simple-select-label"
															id="demo-simple-select"
															label={`Select ${fieldName}`}
															color="black"
															size="small"
															value={customFieldsData?.[_id] || ""}
															onChange={(event) => {
																handleChangeCustomFields(event, _id);
															}}
														>
															{config?.dropDownValues.map((item) => (
																<MenuItem key={item?.id} value={item?.id}>
																	<div className="flex flex-row gap-2 justify-start items-center text-sm">
																		<DropDownColorIdentifier
																			color={item.color}
																		/>
																		{item.value}
																	</div>
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</>
											)}
										</div>
									)
								);
							})}
						{isResumeRequired && (
							<Box className="mb-4">
								<label
									htmlFor="modal-resume"
									className="block text-sm font-medium text-gray-600 mb-2"
								>
									Upload Resume
								</label>
								<TextField
									sx={{
										"& .MuiInputBase-root": {
											padding: "6px",
											fontSize: "0.9rem",
										},
										"& .MuiInputBase-input": {
											padding: "4px",
										},
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
										"&:hover fieldset": {
											border: "1px solid #F2F3F5 !important",
										},
									}}
									color="black"
									size="small"
									// required={
									// 	isResumeRequired
									// }
									type="file"
									id="resumeU"
									accept="application/pdf"
									InputLabelProps={{
										shrink: true,
									}}
									onChange={handleResumeUpload2}
									name="resume"
									fullWidth
									disabled={savingUserDeets}
								/>
								{userDetails?.resumeFileNameInS3 && (
									<Typography
										variant="p"
										onClick={() => {
											const resumeFileName = userDetails.resumeFileNameInS3;
											const hasValidExtension = /\.(pdf|doc|docx)$/i.test(
												resumeFileName,
											);
											const resumeUrl = hasValidExtension
												? resumeFileName
												: `${resumeFileName}.pdf`;

											const url = resumeUrl.endsWith(".pdf")
												? `https://procturemeet.s3.ap-southeast-1.amazonaws.com/resumes/${resumeUrl}`
												: `${window.location.origin}/view/${resumeUrl}`;

											window.open(url, "_blank");
										}}
										rel="noopener noreferrer"
										className={`block text-sm font-medium text-gray-600 mb-2 ${!resume && "cursor-pointer"}`}
									>
										{resume
											? "This will replace the previously uploaded resume"
											: `Previously Uploaded Resume: ${userDetails?.resumeFileNameInS3}`}
									</Typography>
								)}
							</Box>
						)}
					</div>

					<Box
						className="flex justify-end space-x-2 mt-4 px-6 pb-4 pt-2"
						style={{
							borderTop: "1px solid #E5E7EB",
						}}
					>
						<Box
							className={`normal-case cursor-pointer bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 focus:outline-none focus:border-none text-xs ${
								savingUserDeets && "cursor-not-allowed opacity-50"
							}`}
							component={Button}
							onClick={() => {
								setUserDetails(candidate);
								onClose();
							}}
							disabled={savingUserDeets}
						>
							Cancel
						</Box>
						<Box
							className={`normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs ${
								savingUserDeets && "cursor-not-allowed opacity-50"
							}`}
							component={Button}
							type="submit"
							disabled={savingUserDeets}
						>
							{savingUserDeets
								? isEditing
									? "Updating..."
									: "Saving..."
								: isEditing
									? "Update"
									: "Save"}
						</Box>
					</Box>
				</form>
			</Box>
		</Modal>
	);
};

export default AddEditCandidateModal;
