import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Box, Button, TextField, useTheme, ThemeProvider, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { updateCandidateDetails, updateCandidateDetailss } from "../../utilities/interviewReportsApi";
import { clientLogger as clientLoggerApi } from "../../utilities/loggingApi";
import { DEFAULT_COUNTRY } from "../country-selector/countries";
import { CountrySelector } from "../country-selector/country-selector";
import { isValidPhoneNumber } from "../../utilities/validation";

const initialUserDetails = {
	firstName: "",
	lastName: "",
	preferredName: "",
	email: "",
	phoneNumber: "",
	country: DEFAULT_COUNTRY,
	experience: "",
	resumeFileNameInS3: "",
	isProfileSearchable: false,
};

const CorrectionForm = ({ isResumeRequired, report, isMobileInterviewAllowed, orgName, opening }) => {
	const theme = useTheme();
	const params = useParams();
	const [userDetails, setUserDetails] = useState(initialUserDetails);
	const [snackbarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [savingUserDeets, setSavingUserDeets] = useState(false);
	const [resume, setResume] = useState(null);
	const [skipCV, setSkipCV] = useState(false);

	useEffect(() => {
		setUserDetails({
			firstName: report?.firstName,
			lastName: report?.lastName,
			preferredName: report?.preferredName,
			email: report?.email,
			phoneNumber: report?.phoneNumber,
			country: report?.country || DEFAULT_COUNTRY,
			experience: report?.experience,
			resumeFileNameInS3: report?.resumeFileNameInS3,
			isProfileSearchable: report?.isProfileSearchable ?? false,
		});
		setSkipCV(report?.resumeFileNameInS3 ? true : false);
	}, []);

	const openDatabase = () => {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open("canDB", 1);

			request.onerror = function (event) {
				reject("Database error: " + event.target.errorCode);
			};

			request.onsuccess = function (event) {
				resolve(event.target.result);
			};

			// Create the object store if needed
			request.onupgradeneeded = function (event) {
				let db = event.target.result;
				db.createObjectStore("canDB", {
					keyPath: "id",
					// autoIncrement: true,
				});
			};
		});
	};

	const storeSessionInfo = async (openingId, interviewReportId) => {
		const db = await openDatabase();
		const transaction = db.transaction(["canDB"], "readwrite");
		const objectStore = transaction.objectStore("canDB");

		const sessionInfo = {
			id: 1,
			openingId: openingId,
			interviewReportId: interviewReportId,
			activeSession: true,
			tabIsOpen: false,
		};

		const request = objectStore.put(sessionInfo);
		request.onsuccess = function (event) {
			console.log("Session info stored successfully", event.target.result);
		};
		request.onerror = function (event) {
			console.error("Error storing session info:", event.target.errorCode);
		};
	};

	const isValidEmail = (email) => {
		// Regex Explanation:
		// ^ asserts position at start of the string
		// [^@]+ matches one or more characters that are not '@'
		// @ matches the '@' character
		// [^@]+ matches one or more characters that are not '@' ensuring no additional '@' is in the domain
		// \. matches the '.' character
		// [^@.]+ matches one or more characters that are not '@' or '.', ensuring no additional '.' or '@' after the domain
		// $ asserts position at the end of the string
		// The part before the '@' symbol does not contain '@'.
		// The part between the '@' and the '.' does not contain another '@' or '.'.
		// The part after the '.' does not contain another '@' or '.'.
		const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
		return emailRegex.test(email);
	};

	const handleUserDetailsChange = (e) => {
		const { name, value, type, checked } = e.target;
		setUserDetails((prev) => {
			if (type === "checkbox") {
				return { ...prev, [name]: checked };
			} else {
				return { ...prev, [name]: value };
			}
		});
	};

	const handleResumeUpload = (e) => {
		const file = e.target.files[0];
		if (file.type !== "application/pdf") {
			setSnackBarMessage("Only PDF files are allowed");
			setSnackBarOpen(true);
			setResume(null);
			setSkipCV(true);

			// Reset the file input by creating a new form and replacing the old input
			const oldInput = document.getElementById("resume");
			const newInput = document.createElement("input");

			newInput.type = "file";
			newInput.id = oldInput.id;
			newInput.name = oldInput.name;
			newInput.className = oldInput.className;
			newInput.accept = oldInput.accept;

			// Correctly assign the event handler
			newInput.addEventListener("change", handleResumeUpload);

			// Replace the old input with the new one
			oldInput.parentNode.replaceChild(newInput, oldInput);

			return;
		}
		setResume(file);
		setSkipCV(false);
	};

	const handleUserDetailsSubmit = async (e) => {
		e.preventDefault();
		setSavingUserDeets(true);
		// Validate the user input details
		if (
			userDetails.firstName.trim() === "" ||
			userDetails.lastName.trim() === "" ||
			userDetails.preferredName.trim() === "" ||
			userDetails.email.trim() === "" ||
			userDetails.phoneNumber.trim() === "" ||
			!isValidEmail(userDetails.email) ||
			!isValidPhoneNumber(userDetails.phoneNumber) ||
			+userDetails.experience < 0 ||
			userDetails.experience === "" ||
			!userDetails.country
		) {
			// Set the appropriate error message
			let message = "Few Details are missing!";
			if (!isValidEmail(userDetails.email)) {
				message = "Invalid email format!";
			} else if (!isValidPhoneNumber(userDetails.phoneNumber)) {
				message = "Phone number must be between 6 and 11 digits and contain only numbers.";
			} else if (+userDetails.experience < 0 || userDetails.experience === "") {
				message = "Invalid experience";
			}
			setSavingUserDeets(false);
			setSnackBarMessage(message);
			setSnackBarOpen(true);
			return;
		}

		const preferredNamePattern = /^[a-zA-Z ]{3,30}$/; // 3-30 characters, only alphabets and spaces
		const isPreferredNameValid =
			preferredNamePattern.test(userDetails.preferredName) &&
			!userDetails.preferredName.startsWith("aws:");

		if (!isPreferredNameValid) {
			setSnackBarMessage(
				"Preferred name must be 3-30 characters long and must not contain any special characters or numbers.",
			);
			setSnackBarOpen(true);
			setSavingUserDeets(false);
			return;
		}

		// Prepare the form data
		const formData = new FormData();
		for (let key in userDetails) {
			if (key === "email") {
				userDetails[key] = userDetails[key]?.toLowerCase();
			}
			if (typeof userDetails[key] === "object" && userDetails[key] !== null) {
				formData.append(key, JSON.stringify(userDetails[key]));
			} else {
				formData.append(key, userDetails[key]);
			}
		}

		if (!skipCV && isResumeRequired) {
			if (resume !== null) {
				formData.append("resume", resume);
			} else {
				setSnackBarMessage("Resume field is required");
				setSnackBarOpen(true);
				setSavingUserDeets(false);
				return;
			}
		}

		formData.append("openingId", params.openingId);
		formData.append("candidateId", params.interviewReportId);
		formData.append("correctionFormShown", true);

		// Perform the API call
		const res = await updateCandidateDetailss(formData);
		// console.log(`response: ${JSON.stringify(response)}`);

		if (res?.message && res?.data) {
			function clientLogger(message, data = {}) {
				const clientLoggerData = {
					logStreamName: res?.data?._id,
					...data,
				};
				clientLoggerApi(message, clientLoggerData);
			}
			clientLogger("Candidate updated their details.");
			setSnackBarMessage(res?.message ? res?.message : "Details updated successfully.");
			setSnackBarOpen(true);
			await storeSessionInfo(params.openingId, res?.data?._id);
			// navigate(`start/${res?.data}`, { replace: true });
			window.location.reload();
		} else if (res?.failed && res?.message) {
			setSnackBarMessage(res?.message);
			setSnackBarOpen(true);
		}

		setSavingUserDeets(false);
	};

	const handleSnackBarClose = (e) => {
		setSnackBarOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<ThemeProvider theme={theme}>
			<Box className="bg-gray-200 min-h-screen pb-3">
				{/* <Box className="w-full bg-white p-4 flex justify-between items-center border-b border-gray-200">
					<Typography variant="span" className="text-xl font-bold pl-10">
						{orgName ?? "Acme Corp"}
					</Typography>
				</Box> */}
				{(isMobile ? isMobileInterviewAllowed : true) && (
					<Box className="flex justify-center space-x-4 mt-7">
						<Box id="step1Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
						<Box id="step2Bar" className="bg-[#10B981] rounded h-2 w-24"></Box>
						<Box id="step3Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
						<Box id="step4Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
						{opening?.isFaceMatchRequired && (
							<Box id="step5Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
						)}
					</Box>
				)}
				{(isMobile ? isMobileInterviewAllowed : true) ? (
					<Box
						id="panel1"
						className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-7 mx-auto"
					>
						<h2 className="text-center text-2xl font-bold mb-6">Confirm your details</h2>
						<form>
							<Box className="flex space-x-4 mb-4">
								<div className="w-1/2">
									<label
										htmlFor="firstName"
										className="block text-sm font-medium text-gray-600 mb-2"
									>
										First Name
									</label>
									<TextField
										sx={{
											"& fieldset": {
												border: "1px solid #E5E7EB",
											},
										}}
										color="black"
										size="small"
										required
										value={userDetails.firstName}
										onChange={handleUserDetailsChange}
										disabled={savingUserDeets}
										type="text"
										id="firstName"
										name="firstName"
										className="border-gray-200 rounded w-full"
									/>
								</div>
								<div className="w-1/2">
									<label
										htmlFor="lastName"
										className="block text-sm font-medium text-gray-600 mb-2"
									>
										Last Name
									</label>
									<TextField
										sx={{
											"& fieldset": {
												border: "1px solid #F2F3F5",
											},
										}}
										color="black"
										size="small"
										required
										value={userDetails.lastName}
										onChange={handleUserDetailsChange}
										disabled={savingUserDeets}
										type="text"
										id="lastName"
										name="lastName"
										className=" border-gray-200 rounded w-full"
									/>
								</div>
							</Box>
							<Box className="mb-4">
								<label
									htmlFor="preferredName"
									className="block text-sm font-medium text-gray-600 mb-2"
								>
									Preferred Name
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									color="black"
									size="small"
									required
									type="text"
									id="preferredName"
									name="preferredName"
									className=" border-gray-200 rounded w-full"
									value={userDetails.preferredName}
									onChange={handleUserDetailsChange}
									disabled={savingUserDeets}
								/>
							</Box>
							<Box className="mb-4">
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-600 mb-2"
								>
									Email Address
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									color="black"
									size="small"
									required
									type="text"
									id="email"
									name="email"
									className=" border-gray-200 rounded w-full"
									value={userDetails.email}
									onChange={handleUserDetailsChange}
									disabled={savingUserDeets}
								/>
							</Box>
							<Box className="mb-4">
								<label
									htmlFor="experience"
									className="block text-sm font-medium text-gray-600 mb-2"
								>
									Experience in Years
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									color="black"
									size="small"
									required
									type="number"
									id="candidate-experience"
									name="experience"
									className=" border-gray-200 rounded w-full"
									value={userDetails.experience}
									onChange={handleUserDetailsChange}
									disabled={savingUserDeets}
								/>
							</Box>
							<Box className="mb-4">
								<CountrySelector
									value={userDetails.country}
									onChange={(newValue) => {
										handleUserDetailsChange({
											target: { name: "country", value: newValue },
										});
									}}
									disabled={savingUserDeets}
								/>
							</Box>
							<Box className="mb-4">
								<label
									htmlFor="phoneNumber"
									className="block text-sm font-medium text-gray-600 mb-2"
								>
									Phone Number
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									color="black"
									size="small"
									required
									type="text"
									id="phoneNumber"
									name="phoneNumber"
									className=" border-gray-200 rounded w-full"
									value={userDetails.phoneNumber}
									onChange={handleUserDetailsChange}
									disabled={savingUserDeets}
								/>
							</Box>
							{isResumeRequired && (
								<Box className="mb-4">
									<label
										htmlFor="resume"
										className="block text-sm font-medium text-gray-600 mb-2"
									>
										Upload Resume
									</label>
									<TextField
										sx={{
											"& .MuiInputBase-root": {
												padding: "6px",
												fontSize: "0.9rem",
											},
											"& .MuiInputBase-input": {
												padding: "4px",
											},
											"& fieldset": {
												border: "1px solid #F2F3F5",
											},
											"&:hover fieldset": {
												border: "1px solid #F2F3F5 !important",
											},
										}}
										color="black"
										size="small"
										required
										disabled={savingUserDeets}
										accept="application/pdf"
										type="file"
										onChange={handleResumeUpload}
										id="resume"
										name="resume"
										className="text-sm border-gray-200 rounded w-full "
									/>
									{userDetails?.resumeFileNameInS3 && (
										<Typography
											variant="p"
											onClick={() => {
												if (resume) {
													setResume(null);
													setSkipCV(true);

													// Reset the file input by creating a new form and replacing the old input
													const oldInput = document.getElementById("resume");
													const newInput = document.createElement("input");

													newInput.type = "file";
													newInput.id = oldInput.id;
													newInput.name = oldInput.name;
													newInput.className = oldInput.className;
													newInput.accept = oldInput.accept;

													// Correctly assign the event handler
													newInput.addEventListener("change", handleResumeUpload);

													// Replace the old input with the new one
													oldInput.parentNode.replaceChild(newInput, oldInput);
												} else {
													const resumeFileName = userDetails.resumeFileNameInS3;
													const hasValidExtension = /\.(pdf|doc|docx)$/i.test(
														resumeFileName,
													);
													const resumeUrl = hasValidExtension
														? resumeFileName
														: `${resumeFileName}.pdf`;

													const url = resumeUrl.endsWith(".pdf")
														? `https://procturemeet.s3.ap-southeast-1.amazonaws.com/resumes/${resumeUrl}`
														: `${window.location.origin}/view/${resumeUrl}`;

													window.open(url, "_blank");
												}
											}}
											rel="noopener noreferrer"
											className=" cursor-pointer block text-sm font-medium text-gray-600 mb-2"
										>
											{resume
												? "This will replace the previously uploaded resume. Click here to keep the previous resume."
												: `Previously Uploaded Resume: ${userDetails?.resumeFileNameInS3}`}
										</Typography>
									)}
								</Box>
							)}
							{orgName?.toLowerCase() === "zinterview.ai" && (
								<Box className="mb-4">
									<Typography
										variant="body1"
										className="text-sm font-semibold text-gray-600 mb-2"
									>
										Make Profile Searchable?
									</Typography>
									<Box className="flex items-center">
										<input
											type="checkbox"
											className="scale-125 flex-shrink-0 align-middle"
											name="isProfileSearchable"
											id="isProfileSearchable"
											checked={userDetails?.isProfileSearchable}
											onChange={handleUserDetailsChange}
										/>
										<label
											htmlFor="makeProfileSearchable"
											className="text-sm text-gray-600 ml-2"
										>
											Yes, I would like my profile to be searchable for other employers
											looking for candidates for similar positions.
										</label>
									</Box>
								</Box>
							)}
							<Box className="flex justify-end mt-4">
								<Box
									onClick={handleUserDetailsSubmit}
									id="continue1"
									className={`normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs ${
										savingUserDeets && "cursor-not-allowed opacity-50"
									}`}
									component={Button}
									// variant='contained'
									type="submit"
									// fullWidth
									// size='large'
									disabled={savingUserDeets}
								>
									{savingUserDeets ? (
										<span>
											{"Updating Details... "}{" "}
											<i className="fas fa-circle-notch fa-spin mr-1"></i>
										</span>
									) : (
										<span>
											{"Continue "} <i className="fas fa-arrow-right mr-1"></i>
										</span>
									)}
								</Box>
							</Box>
						</form>
					</Box>
				) : (
					<Box
						id="panel2"
						className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
					>
						{" "}
						<h2 className="text-center text-2xl font-bold mb-6">
							Just a heads-up! To participate in this interview, you'll need to switch to a
							desktop browser. This ensures you get the best possible experience and can fully
							engage with the interview process. Thanks for your cooperation!
						</h2>
					</Box>
				)}
				<Snackbar
					open={snackbarOpen}
					autoHideDuration={4000}
					onClose={handleSnackBarClose}
					message={snackBarMessage}
					action={action}
				/>
			</Box>
		</ThemeProvider>
	);
};

export default CorrectionForm;
