import axios from "./axiosInstance";

export const SendUserMessageToServer = async (
	messages,
	isJobDescription = false,
	isPlusSubscription = false,
) => {
	try {
		const res = await axios.post("/api/zinterview-gpt-chat/", {
			messages,
			isJobDescription,
			isPlusSubscription,
		});
		if (res.status === 200) {
			console.log("response received initially is");
			console.log(res.data.message);
			return res.data.message;
		}
	} catch (error) {
		const message = error?.response?.data?.message || error.message;
		console.log("caught error");
		console.log(error);
		throw new Error(message);
	}
};

export const sendUserMessageToCreateJobDescription = async (
	messages,
	openingType,
	isPlusSubscription = false,
) => {
	try {
		const res = await axios.post("/api/zinterview-gpt-chat/create-opening", {
			messages,
			openingType,
			isPlusSubscription,
		});
		if (res.status === 200) {
			return res.data.message;
		}
	} catch (error) {
		const message = error?.response?.data?.message || error.message;
		console.log("caught error");
		console.log(error);
		const newErr = new Error(message);
		newErr.errorCode = error.errorCode;
		throw newErr;
	}
};

export const getMarkdownFromUserInput = async (payload) => {
	try {
		const res = await axios.post("/api/zinterview-gpt-chat/get-markdown", payload);
		if (res.status === 200) {
			return res.data.message;
		}
	} catch (error) {
		const message = error?.response?.data?.message || error.message;
		console.log("caught error");
		console.log(error);
		throw new Error(message);
	}
};
