import React from "react";
import {
	Chip,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import PlanDetails from "./PlanDetails";
import CurrentPlanCard from "./CurrentPlanCard";
import AddCardIcon from "@mui/icons-material/AddCard";
import { useSelector } from "react-redux";

const CurrentPlan = ({ organizationId, organization, getOrganizationsDetails, isLoading }) => {
	const plans = organization?.pricingPlans || [];
	const activePlan = plans.find((plan) => plan.isActive) || {};
	const hasActivePlan = Object.keys(activePlan).length > 0;
	const { user } = useSelector((state) => state.auth);

	return (
		<div className="w-full mx-auto p-2">
			{isLoading ? (
				<div>
					<CircularProgress />
				</div>
			) : (
				<div>
					{hasActivePlan ? (
						<div className="my-4">
							<CurrentPlanCard
								organization={organization}
								value={activePlan}
								IconComponent={AddCardIcon}
								key={organization._id}
							/>
						</div>
					) : (
						""
					)}
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow className="bg-gray-100 min-w-full border rounded-lg overflow-hidden text-sm">
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Start Date
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										End Date
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Renewal Date
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Type
									</TableCell>
									{user.role == "1096" && (
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											Is Pro Rated / Changed Billing Cycle
										</TableCell>
									)}
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Is Trial
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Is Active
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{organization?.pricingPlans?.length > 0 ? (
									organization.pricingPlans
										.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
										.map((row) => (
											<TableRow key={row._id}>
												<TableCell className="text-center" component="th" scope="row">
													{new Date(row.startDate).toDateString()}
												</TableCell>
												<TableCell className="text-center" component="th" scope="row">
													{row.endDate ? new Date(row.endDate).toDateString() : "-"}
												</TableCell>
												<TableCell className="text-center">
													{row.renewalDate
														? new Date(row.renewalDate).toDateString()
														: "-"}
												</TableCell>
												<TableCell className="text-center">
													{row.type === "Package" ? (
														<Chip label="Package" color="primary" />
													) : row.type === "Slabs" ? (
														<Chip label="Slabs" color="secondary" />
													) : row.type === "Volume" ? (
														<Chip label="Volume" color="warning" />
													) : (
														<Chip label="Pay As You Go" color="info" />
													)}
												</TableCell>

												{user.role == "1096" && (
													<TableCell className="text-center">
														{row.isProRated ? (
															<Chip label="Yes" color="success"></Chip>
														) : (
															<Chip label="No" color="error"></Chip>
														)}
													</TableCell>
												)}
												<TableCell className="text-center">
													{row.isTrial ? (
														<Chip label="Yes" color="success"></Chip>
													) : (
														<Chip label="No" color="error"></Chip>
													)}
												</TableCell>
												<TableCell className="text-center">
													{row.isActive ? (
														<Chip label="Yes" color="success"></Chip>
													) : (
														<Chip label="No" color="error"></Chip>
													)}
												</TableCell>
												<TableCell className="text-center">
													<PlanDetails currentPlan={row}></PlanDetails>
												</TableCell>
											</TableRow>
										))
								) : (
									<TableRow>
										<TableCell colSpan={9} className="text-center">
											No Plan till now
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
		</div>
	);
};

export default CurrentPlan;
