import axios from "./axiosInstance";
import { toast } from "react-toastify";

const getTemplates = async () => {
    try {
        let response = await axios.get("/api/templates");
        return response;
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Error fetching templates");
        }
        throw new Error("Internal Server Error");
    }
}

const addTemplate = async (body = {}) => {
    try {
        let response = await axios.post("/api/templates/add", body);
        return response;
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Error saving template");
        }
        throw new Error("Internal Server Error");
    }
}

const updateTemplate = async (body = {}) => {
    try {
        let response = await axios.post("/api/templates/update", body);
        return response;
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Error updating template");
        }
        throw new Error("Internal Server Error");
    }
}

const deleteTemplateApi = async (body = {}) => {
    try {
        let response = await axios.post("/api/templates/delete", body);
        return response;
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Error deleting template");
        }
        throw new Error("Internal Server Error");
    }
}

const generateTemplate = async (body = {}) => {
    try {
        let response = await axios.post("/api/templates/generate", body);
        return response;
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Error generating template");
        }
        throw new Error("Internal Server Error");
    }
}

const sendEmail = async (body = {}) => {
    try {
        let response = await axios.post("/api/templates/send-email", body);
        return response;
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Error sending mail");
        }
        throw new Error("Internal Server Error");
    }
};

export { getTemplates, addTemplate, updateTemplate, deleteTemplateApi, generateTemplate, sendEmail };