import { Alert } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const AlertMessage = () => {
	const { user } = useSelector((state) => state.auth);
	return (
		<>
			{user?.role == "1024" && user.canConductInterviewMessage ? (
				<div className="mt-1">
					<Alert severity="error" className="">
						{user.canConductInterviewMessage}
					</Alert>
				</div>
			) : null}
		</>
	);
};

export default AlertMessage;
