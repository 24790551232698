import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";

const intlInstance = new Intl.NumberFormat("en-IN", {
	maximumFractionDigits: 3,
});

const ScoreRenderer = ({params}) => {
	let interviewReport = params.row;
	let candidateScore = null;

	const getProgressColor = (value) => {
		if (value > 80) return "green";
		if (value >= 60 && value <= 80) return "orange";
		return "red";
	};

	try {
		const { evaluation } = interviewReport;
		let parsedJSON = JSON.parse(evaluation);
		const { verdict, evaluation_report } = parsedJSON;
		const score = verdict?.score;
		const overall_score = evaluation_report?.overall_score;
		const overall_score_in_percentage = evaluation_report?.overall_score_in_percentage;

		if (score >= 0) {
			candidateScore = intlInstance.format(+score);
		}
		if (overall_score >= 0) {
			candidateScore = intlInstance.format(+overall_score) + "/5";

			candidateScore = Math.round(+overall_score * 20);
		}
		if (overall_score_in_percentage >= 0) {
			candidateScore = intlInstance.format(+overall_score_in_percentage);
		}
	} catch (error) {
		candidateScore = null;
	}
	return (
		<div className="flex items-center justify-center w-full h-full">
			{candidateScore ? (
				<CircularProgressWithLabel color={getProgressColor(candidateScore)} value={candidateScore} />
			) : interviewReport?.evaluationInProgress ? (
				<Tooltip title="Evaluation in Progress" arrow>
					<i className="fas fa-circle-notch fa-spin text-lg"></i>
				</Tooltip>
			) : (
				"-"
			)}
		</div>
	);
};

export default ScoreRenderer;

function CircularProgressWithLabel(props) {
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<CircularProgress size={40} variant="determinate" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typography variant="subtitle1" component="div" sx={{ fontWeight: 400 }}>
					{`${Math.round(props.value)}`}
				</Typography>
			</Box>
		</Box>
	);
}
