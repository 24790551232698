import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCustomDataForInterviewReport } from "../../../utilities/interviewReportsApi";
import { fetchAndUpdateCandidate } from "../../../app/actions/candidates/candidate";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { InputComponent } from "../../events/actions/APIFormRenderer";

const CustomFieldsRenderer = ({ params, id, field }) => {
	const [saving, setSaving] = useState(false);

	let interviewReport = params.row;
	let type = field.type;
	let options = [];
	let variant = "text";
	if (type == 1) {
		field.config?.dropDownValues?.forEach((val) => {
			try {
				options.push({
					value: val.id,
					id: val.id,
					label: val.value,
					color: val.color,
					disabled: false,
				});
			} catch (error) {
				console.log(error);
			}
		});
		variant = "dropdown";
	}
	let defaultValue = field.config?.defaultValue ? field.config.defaultValue : "";
	let value =
		interviewReport.customFieldsData && interviewReport.customFieldsData[id]
			? interviewReport.customFieldsData[id]
			: defaultValue;

	let previousValue = value;

	const [textValue, setTextValue] = useState(value);

	const onFieldValueChange = (val) => {
		if (type == 1) {
			handleSaveOnBlur(val);
		}
	};

	const dispatch = useDispatch();

	const handleSaveOnBlur = async (val) => {
		try {
			if (val === previousValue) {
				return;
			}
			setSaving(true);
			const data = {
				interviewReportId: interviewReport._id,
				openingId: interviewReport.opening,
				customFieldId: id,
				value: val.trim(),
			};
			await updateCustomDataForInterviewReport(data);
			dispatch(fetchAndUpdateCandidate(interviewReport._id));
			setSaving(false);
		} catch (error) {
			setSaving(false);
			console.log("there is an error in saving custom text field");
			console.log(error);
			toast.warn(error?.message);
		}
	};

	return (
		<div className="flex items-center justify-center w-full h-full px-1">
			{saving ? (
				<CircularProgress size={25} />
			) : (
				<InputComponent
					fields={options}
					value={textValue}
					type={variant}
					onChange={(e) => {
						setTextValue(e.target.value);
						onFieldValueChange(e.target.value);
					}}
					onKeyDownHandler={(e) => {
						e.stopPropagation();
						if (e.key.toLowerCase() === "enter") {
							handleSaveOnBlur(textValue);
						}
					}}
					onBlurHandler={() => {
						handleSaveOnBlur(textValue);
					}}
					classes="flex-1"
					placeholder=""
					label=""
					size="small"
					styles={{ maxWidth: "20ch" }}
				/>
			)}
		</div>
	);
};

export default CustomFieldsRenderer;
