import axios from "./axiosInstance";

// function to fetch the invoices
async function fetchInvoicesAPI() {
	try {
		const response = await axios.get(`/api/invoices/`);
		if (response.status === 200) {
			return response.data.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while fetching invoices");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

export { fetchInvoicesAPI };
