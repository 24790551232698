import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const RequireAuth = ({ children, superAdmin }) => {
	const { user } = useSelector((state) => state.auth);
	const location = useLocation();

	if (!user) {
		return (
			<Navigate
				to={"/login"}
				replace={true}
				state={{ path: location.pathname, search: location.search }}
			/>
		);
	}
	if (superAdmin && user.role !== "1096") {
		toast.warn("Forbidden");
		return <Navigate to={"/admin"} replace={true} />;
	}

	return <>{children}</>;
};

export default RequireAuth;
