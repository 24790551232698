// import CheckList from '@editorjs/checklist'
// import Code from '@editorjs/code'
// import Delimiter from '@editorjs/delimiter'
import Embed from '@editorjs/embed'
// import Image from '@editorjs/image'
// import InlineCode from '@editorjs/inline-code'
import LinkTool from '@editorjs/link'
import List from '@editorjs/list'
// import Marker from '@editorjs/marker'
// import Quote from '@editorjs/quote'
// import Raw from '@editorjs/raw'
// import SimpleImage from '@editorjs/simple-image'
import Table from '@editorjs/table'
// import Warning from '@editorjs/warning'
import Paragraph from '@editorjs/paragraph'
import Header from "@editorjs/header";
// import axios from 'axios'

// npm i @editorjs/header @editorjs/paragraph @editorjs/embed @editorjs/table @editorjs/list @editorjs/warning @editorjs/code @editorjs/link @editorjs/image @editorjs/raw @editorjs/quote @editorjs/marker @editorjs/checklist @editorjs/inline-code @editorjs/delimiter

export const EDITOR_JS_TOOLS = {
    header: {
        class: Header,
        inlineToolbar: true,
        config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3, 4],
            defaultLevel: 1
        }
    },
    paragraph: {
        class: Paragraph,
        inlineToolbar: true,
        config: {
            placeholder:
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.',
        },
    },
    embed: Embed,
    table: Table,
    list: List,
    // warning: Warning,
    // code: Code,
    linkTool: LinkTool,
    // image: {
    //     class: Image,
    //     inlineToolbar: true,
    //     config: {
    //         uploader: {
    //             async uploadByFile(file) {
    //                 const formData = new FormData()
    //                 formData.append('file', file)
    //                 let resp = await axios.post('/api/files/upload-file', formData, {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data',
    //                     },
    //                 })
    //                 if (resp.status === 200 && resp.data.fileUrl) {
    //                     return {
    //                         success: 1,
    //                         file: {
    //                             url: resp.data.fileUrl,
    //                         },
    //                     }
    //                 } else {
    //                     return {
    //                         success: 0,
    //                         file: {
    //                             url: '',
    //                         },
    //                     }
    //                 }
    //             },
    //         }
    //     }
    // },
    // raw: Raw,
    // quote: Quote,
    // marker: Marker,
    // checklist: CheckList,
    // inlineCode: InlineCode,
    // simpleImage: {
    //     class: SimpleImage,
    //     inlineToolbar: true,
    // },
    // delimiter: Delimiter,
}