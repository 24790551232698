import { useEffect, useState } from "react";
import { fetchPreInterviewDetails } from "../utilities/interviewReportsApi";
import { useParams } from "react-router-dom";
import { PreInterviewDetailsDisplay } from "../components/pre-interview-details";
import { Box } from "@mui/material";

export const PreInterviewDetails = () => {
	const { interviewReportId } = useParams();
	const [details, setDetails] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const fetchInterviewReport = async () => {
		try {
			setLoading(true);
			const response = await fetchPreInterviewDetails(interviewReportId);
			setDetails(response);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchInterviewReport();
	}, []);

	return (
		<Box className="flex w-screen h-screen justify-center items-center ">
			{loading ? (
				<i className="fas fa-circle-notch fa-spin"></i>
			) : error ? (
				error
			) : (
				details && <PreInterviewDetailsDisplay details={details} />
			)}
		</Box>
	);
};
