import React, { useState } from "react";
import {
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	FormControl,
	Select,
	MenuItem,
	Button,
} from "@mui/material";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { updateSecondDevicePositionAccuracy } from "../../../utilities/interviewReportsApi";
import { useParams } from "react-router-dom";

const SidePanel = ({
	attendees,
	audioDevices = [],
	selectedDevice = "",
	onDeviceChange,
	shouldAddPositionSwitch = false,
	lastPositionStatus = false,
	clientLogger = () => {},
}) => {
	const params = useParams();
	const showAudioSettings = audioDevices.length > 0 && selectedDevice;
	const [isAccurate, setIsAccurate] = useState(lastPositionStatus);
	const [isUpdating, setIsUpdating] = useState(false);

	// useEffect(() => {
	//     setIsAccurate(lastPositionStatus);
	// }, [lastPositionStatus]);

	const handleMakeAccurate = async () => {
		try {
			if (isAccurate) return;
			setIsUpdating(true);
			const resp = await updateSecondDevicePositionAccuracy(true, params?.interviewReportId);
			if (!resp) throw new Error(`Failed to update position accuracy, resp: ${resp}`);
			setIsAccurate(true);
		} catch (error) {
			clientLogger("Error updating position accuracy", { error });
		} finally {
			setIsUpdating(false);
		}
	};

	const renderPositionAccuracyControl = () => {
		if (isAccurate) {
			return (
				<Box className="flex items-center gap-2 text-green-600">
					<CheckCircleIcon fontSize="small" />
					<Typography variant="body2">Candidate's second camera position is accurate</Typography>
				</Box>
			);
		}

		return (
			<Button
				variant="outlined"
				onClick={handleMakeAccurate}
				disabled={isUpdating}
				className="normal-case"
				sx={{
					opacity: isUpdating ? 0.7 : 1,
				}}
			>
				{isUpdating
					? "Making position accurate..."
					: "Make candidate's second camera position accurate"}
			</Button>
		);
	};

	return (
		<Box className="w-full mb-4">
			<Paper elevation={3} className="p-4">
				{/* Position Accuracy Section */}
				{shouldAddPositionSwitch && <Box className="mb-6">{renderPositionAccuracyControl()}</Box>}

				{/* Audio Settings Section - Only shown when devices are available */}
				{showAudioSettings && (
					<Box className="mb-6">
						<Typography variant="h6" className="mb-4 font-semibold">
							Audio Settings
						</Typography>
						<FormControl
							size="small"
							sx={{
								width: "100%",
								mb: 2,
							}}
						>
							<Select
								className="text-sm"
								sx={{
									width: "100%",
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
								}}
								value={selectedDevice?.deviceId}
								onChange={onDeviceChange}
							>
								{audioDevices.map((device) => (
									<MenuItem
										key={device.deviceId}
										value={device.deviceId}
										sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											fontSize: "0.875rem",
											lineHeight: "1.25rem",
										}}
									>
										{device.label || `Microphone ${device.deviceId}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				)}

				{/* Roster Section */}
				<Typography variant="h6" className="mb-4 font-semibold">
					In Meeting
				</Typography>
				<TableContainer component={Paper} className="max-h-[300px] overflow-auto">
					<Table stickyHeader aria-label="meeting roster table">
						<TableHead>
							<TableRow>
								<TableCell className="font-semibold bg-gray-100">Attendee</TableCell>
								<TableCell className="font-semibold bg-gray-100">Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{attendees.map((attendee) => (
								<TableRow
									key={attendee.id}
									className={attendees.indexOf(attendee) % 2 === 0 ? "bg-gray-50" : ""}
								>
									<TableCell component="th" scope="row">
										{attendee.name}
									</TableCell>
									<TableCell>
										{attendee.isMuted ? (
											<MicOffIcon className="text-red-500" />
										) : attendee.isSpeaking ? (
											<MicIcon className="text-green-500" />
										) : (
											<MicIcon className="text-gray-500" />
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	);
};

export default SidePanel;
