import React from "react";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
const InterviewHomePage = () => {
	return (
		<>
			<ErrorBoundary interviewPage={true}>
				<Outlet />
			</ErrorBoundary>
		</>
	);
};

export default InterviewHomePage;
