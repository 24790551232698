import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, Box, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import logo from "../assets/zi-favicon.png";
import { createOrganizationAPI } from "../utilities/organizationApi";
import { toast } from "react-toastify";
import { fetchCountriesAPI, fetchStatesAPI } from "../utilities/zoho";

const initialUserData = {
	organizationName: "",
	contactInfo: "",
	userName: "",
	email: "",
	password: "",
	confirmPassword: "",
	countryCode: "",
	stateCode: "",
};

// Define the schema using zod
const userSchema = z
	.object({
		organizationName: z.string().nonempty("Organization name is required"),
		contactInfo: z.string().min(1, "Contact info is required"),
		userName: z.string().min(1, "Username is required"),
		email: z.string().toLowerCase().trim().email("Invalid email address"),
		password: z.string().min(6, "Password must be at least 6 characters long"),
		confirmPassword: z.string().min(6, "Password must be at least 6 characters long"),
		countryCode: z.string().min(1, "Country is required"),
		stateCode: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: ["confirmPassword"],
	});

const CustomerAccountCreation = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isPwdVisible1, setIsPwdVisible1] = useState(false);
	const [isPwdVisible2, setIsPwdVisible2] = useState(false);
	const [countriesList, setCountriesList] = useState({});
	const [statesList, setStatesList] = useState({});

	const methods = useForm({
		resolver: zodResolver(userSchema),
		defaultValues: initialUserData,
	});

	const onSubmit = async (data) => {
		setIsLoading(true);
		const formData = {
			...data,
			email: data.email.toLowerCase().trim(),
		};
		try {
			const res = await createOrganizationAPI(formData);
			toast.success(res.message);
			setIsLoading(false);
		} catch (error) {
			console.log(error.message);
			toast.error(error.message);
			setIsLoading(false);
		}
	};

	const {
		watch,
		register,
		handleSubmit,
		formState: { errors },
	} = methods;

	console.log(errors);
	const fetchCountriesList = async () => {
		try {
			const data = await fetchCountriesAPI();
			setCountriesList(data);
		} catch (error) {
			console.log(error.message);
			toast.error(error.message);
		}
	};

	const fetchStateList = async () => {
		try {
			const data = await fetchStatesAPI();
			setStatesList(data);
		} catch (error) {
			console.log(error.message);
			toast.error(error.message);
		}
	};

	useEffect(() => {
		fetchCountriesList();
		fetchStateList();
	}, []);

	const countryCodeWatcher = watch("countryCode");
	const stateCodeWatcher = watch("stateCode");

	return (
		<Box className="bg-gray-100 h-screen flex flex-col justify-center items-center">
			<Box className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3">
				<Box className="flex justify-center mb-6">
					<img src={logo} alt="Zinterview Logo" className="h-16" />
				</Box>

				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box className="mb		-4">
									<label
										htmlFor="organization-name"
										className="block text-sm font-medium text-gray-600"
									>
										Organization Name
									</label>
									<TextField
										sx={{ "& fieldset": { border: "1px solid #F2F3F5" } }}
										color="black"
										autoComplete="off"
										fullWidth
										size="small"
										required
										type="text"
										id="organization-name"
										className="mt-1 w-full border rounded-md"
										{...register("organizationName")}
										disabled={isLoading}
									/>
									{errors.organizationName && (
										<p className="text-red-500 text-xs mt-1">
											{errors.organizationName.message}
										</p>
									)}
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box className="mb-4">
									<label
										htmlFor="username"
										className="block text-sm font-medium text-gray-600"
									>
										Username
									</label>
									<TextField
										sx={{ "& fieldset": { border: "1px solid #F2F3F5" } }}
										autoComplete="off"
										required
										fullWidth
										id="username"
										{...register("userName")}
										disabled={isLoading}
										color="black"
										size="small"
										type="text"
										className="mt-1 w-full border rounded-md"
									/>
									{errors.userName && (
										<p className="text-red-500 text-xs mt-1">{errors.userName.message}</p>
									)}
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box className="mb-4">
									<label
										htmlFor="contact-info"
										className="block text-sm font-medium text-gray-600"
									>
										Phone Number
									</label>
									<TextField
										sx={{ "& fieldset": { border: "1px solid #F2F3F5" } }}
										autoComplete="off"
										fullWidth
										id="contact-info"
										{...register("contactInfo")}
										disabled={isLoading}
										required
										color="black"
										size="small"
										type="text"
										className="mt-1 w-full border rounded-md"
									/>
									{errors.contactInfo && (
										<p className="text-red-500 text-xs mt-1">
											{errors.contactInfo.message}
										</p>
									)}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box className="mb-4">
									<label
										htmlFor="email"
										className="block text-sm font-medium text-gray-600"
									>
										Email Address
									</label>
									<TextField
										sx={{ "& fieldset": { border: "1px solid #F2F3F5" } }}
										required
										fullWidth
										id="email"
										autoComplete="off"
										{...register("email")}
										disabled={isLoading}
										color="black"
										size="small"
										type="email"
										className="mt-1 w-full border rounded-md"
									/>
									{errors.email && (
										<p className="text-red-500 text-xs mt-1">{errors.email.message}</p>
									)}
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box className="relative mb-4">
									<label
										htmlFor="password"
										className="block text-sm font-medium text-gray-600"
									>
										Password
									</label>
									<TextField
										sx={{ "& fieldset": { border: "1px solid #F2F3F5" } }}
										required
										fullWidth
										type={isPwdVisible1 ? "text" : "password"}
										id="password"
										{...register("password")}
										disabled={isLoading}
										size="small"
										color="black"
										margin="normal"
										autoComplete="off"
										className="mt-1 w-full border rounded-md"
									/>
									<Box
										className="absolute cursor-pointer top-[60%] right-2 transform -translate-y-1/2"
										onClick={() => setIsPwdVisible1((prev) => !prev)}
									>
										{isPwdVisible1 ? (
											<i className="fas fa-eye-slash"></i>
										) : (
											<i className="fas fa-eye"></i>
										)}
									</Box>
									{errors.password && (
										<p className="text-red-500 text-xs mt-1">{errors.password.message}</p>
									)}
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box className="relative mb-4">
									<label
										htmlFor="confirm-password"
										className="block text-sm font-medium text-gray-600"
									>
										Confirm Password
									</label>
									<TextField
										sx={{ "& fieldset": { border: "1px solid #F2F3F5" } }}
										required
										fullWidth
										type={isPwdVisible2 ? "text" : "password"}
										id="confirm-password"
										{...register("confirmPassword")}
										disabled={isLoading}
										size="small"
										color="black"
										margin="normal"
										autoComplete="off"
										className="mt-1 w-full border rounded-md"
									/>
									<Box
										className="absolute cursor-pointer top-[60%] right-2 transform -translate-y-1/2"
										onClick={() => setIsPwdVisible2((prev) => !prev)}
									>
										{isPwdVisible2 ? (
											<i className="fas fa-eye-slash"></i>
										) : (
											<i className="fas fa-eye"></i>
										)}
									</Box>
									{errors.confirmPassword && (
										<p className="text-red-500 text-xs mt-1">
											{errors.confirmPassword.message}
										</p>
									)}
								</Box>
							</Grid>
							{!!Object.keys(countriesList).length && (
								<Grid item xs={12}>
									<Box className="mb-2">
										<label
											htmlFor="paymentType"
											className="block text-sm font-medium text-gray-600"
										>
											Select the country
										</label>
										<Select
											sx={{
												"& fieldset": {
													border: "1px solid #F2F3F5",
												},
											}}
											color="black"
											fullWidth
											size="small"
											required
											id="countryCode"
											name="countryCode"
											disabled={isLoading}
											{...register("countryCode")}
										>
											{Object.keys(countriesList).map((key) => (
												<MenuItem key={key} value={key}>
													{countriesList[key]}
												</MenuItem>
											))}
										</Select>
									</Box>
									{errors.countryCode && (
										<p className="text-red-500 text-xs mt-1">
											{errors.countryCode.message}
										</p>
									)}
								</Grid>
							)}
							{!!Object.keys(statesList).length && countryCodeWatcher == "IN" && (
								<Grid item xs={12}>
									<Box className="mb-2">
										<label
											htmlFor="paymentType"
											className="block text-sm font-medium text-gray-600"
										>
											Select States
										</label>
										<Select
											sx={{
												"& fieldset": {
													border: "1px solid #F2F3F5",
												},
											}}
											color="black"
											fullWidth
											size="small"
											id="stateCode"
											name="stateCode"
											disabled={isLoading}
											{...register("stateCode")}
										>
											{Object.keys(statesList).map((key) => (
												<MenuItem key={key} value={key}>
													{statesList[key]}
												</MenuItem>
											))}
										</Select>
									</Box>
									{errors.stateCode && (
										<p className="text-red-500 text-xs mt-1">
											{errors.stateCode.message}
										</p>
									)}
								</Grid>
							)}
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={`mt-2 normal-case ${isLoading && "cursor-not-allowed opacity-50"}`}
							disabled={isLoading}
						>
							{isLoading ? "Creating User..." : "Create"}
						</Button>
					</form>
				</FormProvider>
			</Box>
		</Box>
	);
};

export default CustomerAccountCreation;
