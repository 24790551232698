import React from "react";
import { InputLabel, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";

export const Label = ({ htmlFor, children }) => {
	return (
		<label htmlFor={htmlFor} className="block text-gray-700 text-xs font-bold">
			{children}
		</label>
	);
};

export const ColStack = ({ children }) => {
	return <div className="flex flex-col justify-center items-start gap-1">{children}</div>;
};

export const CustomSelectwithController = ({ control, id, name, label, getItemsJSX, error }) => {
	return (
		<>
			<div className="flex flex-col gap-1 w-full ">
				<InputLabel
					className={clsx(
						"whitespace-normal block text-gray-700 text-xs font-bold",
						error && "text-red-500",
					)}
					id={`${id}-label`}
				>
					{label} &nbsp;
				</InputLabel>
				<Controller
					control={control}
					name={name}
					render={({ field: { value, onChange } }) => {
						return (
							<Select
								labelId={`${id}-label`}
								id={id}
								value={value}
								onChange={onChange}
								sx={{
									p: "4px",
									minWidth: "130px",
									"& .MuiOutlinedInput-notchedOutline": {
										border: "1px solid rgb(229, 231, 235)",
									},
									"& .MuiSelect-select": {
										padding: "3px 5px",
									},
									"&:hover .MuiOutlinedInput-notchedOutline": {
										border: "1px solid rgb(229, 231, 235)",
									},
								}}
								MenuProps={{
									PaperProps: {
										sx: {
											borderRadius: "5px",
											boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
										},
									},
								}}
								className="rounded-md text-sm w-full"
							>
								{getItemsJSX()}
							</Select>
						);
					}}
				/>
			</div>
		</>
	);
};

export const CustomSelect = ({ items, id, label, onChange, value, getItemsJSX }) => {
	return (
		<>
			<div className="flex flex-col gap-1 w-full ">
				<InputLabel
					className="whitespace-normal block text-gray-700 text-xs font-bold"
					id={`${id}-label`}
				>
					{label} &nbsp;
				</InputLabel>
				<Select
					labelId={`${id}-label`}
					id={id}
					value={value}
					onChange={onChange}
					sx={{
						p: "3px",
						maxWidth: "250px",
						"& .MuiOutlinedInput-notchedOutline": {
							border: "1px solid rgb(229, 231, 235)",
						},
						"& .MuiSelect-select": {
							padding: "3px 5px",
						},
						"&:hover .MuiOutlinedInput-notchedOutline": {
							border: "1px solid rgb(229, 231, 235)",
						},
					}}
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: "5px",
								boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
							},
						},
					}}
					className="rounded-md text-sm w-full"
				>
					{getItemsJSX()}
				</Select>
			</div>
		</>
	);
};
