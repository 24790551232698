import React from "react";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import "flatpickr/dist/flatpickr.min.css";
import { getFormattedDateTime4 } from "../../../utilities/utilityFunctions";
function InterviewDatePicker({ selectedDate, setSelectedDate, joinEarly, setJoinEarly }) {
	return (
		<>
			<div
				style={{
					boxShadow: "0 -4px 6px -1px rgba(0, 0, 0, 0.1)",
					border: ".5px solid gray",
					borderRadius: "8px",
					padding: "3px",
				}}
			>
				<Flatpickr
					value={selectedDate}
					options={{
						enableTime: true,
						dateFormat: "D M d Y h:i K",
						minDate: "today",
						maxDate: new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000), // maximum allow 2 month
						minTime: "00:00",
						maxTime: "23:59",
						inline: true,
						onChange: (dates) => {
							const newSelectedDate = new Date(dates[0].getTime());
							const maxDate = new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000);
							if (newSelectedDate > maxDate) {
								setSelectedDate(maxDate);
								toast.warn(`The maximum allowed time is ${getFormattedDateTime4(maxDate)}.`);
							} else {
								setSelectedDate(newSelectedDate);
							}
						},
					}}
					style={{ display: "none" }}
				/>
			</div>
			<div className="mt-4">
				<label className="block mb-2">
					<input
						type="checkbox"
						id="joinEarly"
						className="mr-2"
						checked={joinEarly}
						onChange={() => setJoinEarly(!joinEarly)}
					/>
					Allow candidate to join 10 minutes before scheduled time
				</label>
			</div>
		</>
	);
}

export default InterviewDatePicker;
