import { useCallback, useEffect, useState } from "react";
import { fromZodError } from "zod-validation-error";

function useLocalStorage(key, initialValue, schemaToSatisfy) {
	const [value, setValue] = useState(initialValue);
	useEffect(() => {
		try {
			const parsedVal = JSON.parse(localStorage.getItem(key));
			if (parsedVal) {
				if (schemaToSatisfy) {
					const validate = schemaToSatisfy.safeParse(parsedVal);
					if (validate.error) {
						const validationError = fromZodError(validate.error);
						throw validationError;
					} else {
						setValue(validate.data);
					}
				} else {
					setValue(parsedVal);
				}
			}
		} catch (error) {
			console.log("Error in useLocalStorage", error.message);
		}
	}, [key, schemaToSatisfy]);

	const setValueInLocalStorageAndState = useCallback(
		(newVal) => {
			try {
				// validation?
				setValue(newVal);
				localStorage.setItem(key, JSON.stringify(newVal));
			} catch (e) {
				console.log("Error in useLocalStorage.setValueInLocalStorageAndState", e);
			}
		},
		[key],
	);

	const removeValueFromLocalStorageAndState = useCallback(() => {
		try {
			setValue(null); // null or initalValue?
			localStorage.removeItem(key);
		} catch (e) {
			console.log("Error in useLocalStorage.removeValueFromLocalStorageAndState", e);
		}
	}, [key]);
	return [value, setValueInLocalStorageAndState, removeValueFromLocalStorageAndState];
}

export default useLocalStorage;
