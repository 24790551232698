import { toast } from "react-toastify";
import axiosInstance from "../../../utilities/axiosInstance.js";
import {
    HIDE_CANDIDATES, UNHIDE_CANDIDATES,
    SELECT_ALL_CANDIDATE, SELECT_CANDIDATE,
    UNSELECT_ALL_CANDIDATE, UNSELECT_CANDIDATE,
    HIDE_HIDDEN_CANDIDATES, SHOW_HIDDEN_CANDIDATES,
    SET_ALL_CANDIDATES, REMOVE_ALL_CANDIDATES,
    ADD_CANDIDATE, UPDATE_CANDIDATE,
    REMOVE_CANDIDATE,
    HIDE_SINGLE_CANDIDATE, UNHIDE_SINGLE_CANDIDATE,
    SHOW_COMPLETED_INTERVIEWS,
    SET_LOADING_FETCH_REPORTS,
    ADD_CANDIDATES
} from "./types.js";

export const selectCandidate = (candidate) => (dispatch) => {
    dispatch({
        type: SELECT_CANDIDATE,
        payload: candidate,
    });
}

export const unselectCandidate = (candidate) => (dispatch) => {
    dispatch({
        type: UNSELECT_CANDIDATE,
        payload: candidate,
    });
}

export const selectAllCandidate = (candidates) => (dispatch) => {
    dispatch({
        type: SELECT_ALL_CANDIDATE,
        payload: candidates,
    });
}

export const unselectAllCandidate = () => (dispatch) => {
    dispatch({
        type: UNSELECT_ALL_CANDIDATE,
        payload: [],
    });
}

export const addCandidate = (candidate) => (dispatch) => {
    dispatch({
        type: ADD_CANDIDATE,
        payload: candidate,
    });
}

export const addCandidates = (candidates)=>(dispatch) =>{
    dispatch({
        type: ADD_CANDIDATES,
        payload: candidates,
    });
}

export const removeCandidate = (candidateId) => (dispatch) => {
    dispatch({
        type: REMOVE_CANDIDATE,
        payload: candidateId,
    });
}

export const updateCandidate = (candidate) => (dispatch) => {
    dispatch({
        type: UPDATE_CANDIDATE,
        payload: candidate,
    });
}

export const fetchAndUpdateCandidate = (candidateId) => async (dispatch) => {
    try {
        const response = await axiosInstance.get(`/api/interviewReports/interviewReport/${candidateId}`);
        dispatch(updateCandidate(response?.data?.report));
    } catch (error) {
        console.log(error);
        toast.error("Error fetching candidate");
    }
}

export const setAllCandidates = (candidates) => (dispatch) => {
    dispatch({
        type: SET_ALL_CANDIDATES,
        payload: candidates,
    });
}

export const removeAllCandidates = () => (dispatch) => {
    dispatch({
        type: REMOVE_ALL_CANDIDATES,
        payload: [],
    });
}

export const showHiddenCandidates = () => (dispatch) => {
    dispatch({
        type: SHOW_HIDDEN_CANDIDATES,
    });
}

export const showCompletedInterviews = () => (dispatch) => {
    dispatch({
        type: SHOW_COMPLETED_INTERVIEWS,
    });
}

export const hideHiddenCandidates = () => (dispatch) => {
    dispatch({
        type: HIDE_HIDDEN_CANDIDATES,
    });
}

export const hideCandidates = () => async (dispatch, getState) => {
    const state = getState();
    const { selectedCandidate } = state.candidates;
    dispatch(setLoading(true))
    try {
        await axiosInstance.post("/api/interviewReports/hide", { reportIds: selectedCandidate });
        dispatch({
            type: HIDE_CANDIDATES,
        });
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            console.log(error);
            toast.error("Error hiding candidates");
        }
    }
}

export const unhideCandidates = () => async (dispatch, getState) => {
    const state = getState();
    const { selectedCandidate } = state.candidates;
    dispatch(setLoading(true))
    try {
        await axiosInstance.post("/api/interviewReports/unhide", { reportIds: selectedCandidate });
        dispatch({
            type: UNHIDE_CANDIDATES,
        });
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            console.log(error);
            toast.error("Error unhiding candidates");
        }
    }
}

export const hideSingleCandidate = (candidateId) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        await axiosInstance.post("/api/interviewReports/hide", { reportIds: [candidateId] });
        dispatch({
            type: HIDE_SINGLE_CANDIDATE,
            payload: candidateId,
        });
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            console.log(error);
            toast.error("Error hiding candidates");
        }
    }
}

export const unhideSingleCandidate = (candidateId) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        await axiosInstance.post("/api/interviewReports/unhide", { reportIds: [candidateId] });
        dispatch({
            type: UNHIDE_SINGLE_CANDIDATE,
            payload: candidateId,
        });
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            console.log(error);
            toast.error("Error unhiding candidates");
        }
    }
}
export const fetchingInterviewReportWithID = (openingId) => async (dispatch) => {
    dispatch(setLoadingFetchReports(true));
    try {
        const response = await axiosInstance.get(`/api/interviewReports/${openingId}`);
        dispatch(setAllCandidates(response.data.reports));
    } catch (error) {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error("Error fetching interview reports");
        }
    }
    finally{
        dispatch(setLoadingFetchReports(false));
    }
}

export const setLoading = (loading) => (dispatch) => {
    dispatch({
        type: "SET_LOADING",
        payload: loading,
    });
};
export const setLoadingFetchReports = (loading) => (dispatch) => {
    dispatch({
        type: SET_LOADING_FETCH_REPORTS,
        payload: loading,
    });
};


