import axios from "./axiosInstance";

const textToSpeechGC = async (langCode, text, _id) => {
	try {
		const response = await axios.post(`/api/external-services/textToSpeechGC`, {
			langCode,
			text,
			_id,
		});
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		console.log("error in text to speech");
		console.log(error.message);
	}
};

// const unscriptedELSA = async (Mp3File, interviewReportId) => {
// 	try {
// 		let formData = new FormData();
// 		formData.append("file", Mp3File);
// 		formData.append("interviewReportId", interviewReportId);
// 		const response = await axios.post(`/api/external-services/unscriptedELSA`, formData);
// 		if (response.status === 200) {
// 			return response?.data;
// 		} else {
// 			return false;
// 		}
// 	} catch (error) {
// 		console.log("error in unscriptedELSA");
// 		console.log(error.message);
// 	}
// };

const azurePronunciationAssessment = async (mp3File, interviewReportId) => {
	try {
		let formData = new FormData();
		formData.append("file", mp3File);
		formData.append("interviewReportId", interviewReportId);
		const response = await axios.post(`/api/external-services/azurePronunciationAssessment`, formData);
		if (response.status === 200) {
			return response?.data;
		} else {
			return false;
		}
	} catch (error) {
		throw error;
	}
};

export { textToSpeechGC, azurePronunciationAssessment };
