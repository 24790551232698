import { ContentCopy, ExpandMore, Info } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOpenings2 } from "../../../utilities/openingsApi";
import { setAllOpenings } from "../../../features/createOpening/createOpeningSlice";
import { AccordionDetails, IconButton, Modal, TextField, Tooltip } from "@mui/material";
import { Accordion, AccordionSummary, Button } from "@mui/material/node";
import { getAllCustomEvents, pasteActions } from "../../../utilities/eventsApi";
import { toast } from "react-toastify";

const CopyActionComponent = ({
	onEventModalClose = () => null,
	triggerId = null,
	eventType = "",
	actionType = "",
}) => {
	const [modalState, setModalState] = React.useState(false);
	const [filter, setFilter] = React.useState("");
	const { allOpenings } = useSelector((state) => state.openingDetails);
	const [selectedOpeningsWithEvents, setSelectedOpeningsWithEvents] = React.useState({});
	const [customEvents, setCustomEvents] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const dispatch = useDispatch();

	const fetchAllOpenings = async () => {
		try {
			const openings = await fetchOpenings2();
			dispatch(setAllOpenings(openings.openings));
		} catch (error) {
			console.error("Error fetching openings:", error);
		}
	};

	const getCustomEvents = async () => {
		try {
			const customEvents = await getAllCustomEvents();
			let obj = {};
			customEvents.data.events.forEach((el) => {
				if (!obj[el.opening._id]) {
					obj[el.opening._id] = [];
				}
				obj[el.opening._id].push({ value: el._id, label: el.eventName, type: "custom" });
			});
			setCustomEvents(obj);
		} catch (error) {
			console.error("Error fetching custom events:", error);
		}
	};

	const confirmPaste = async () => {
		setLoading(true);
		try {
			console.log("Selected openings with events:", selectedOpeningsWithEvents);
			await pasteActions({ events: selectedOpeningsWithEvents, triggerId: triggerId });
			toast.success("Action pasted successfully");
		} catch (error) {
			console.error("Error pasting action:", error);
		} finally {
			setLoading(false);
			// setModalState(false);
			// onEventModalClose();
		}
	};

	useEffect(() => {
		fetchAllOpenings();
		if (eventType != "3") {
			getCustomEvents();
		}
	}, []);

    return null;

	return (
		<div className="ml-2">
			<Tooltip title={!triggerId ? "Save action first" : "Copy this action."}>
				<IconButton
					className={
						!triggerId
							? "cursor-not-allowed bg-gray-100 text-gray-400"
							: "bg-sky-100 hover:bg-sky-200"
					}
					onClick={() => {
						if (!triggerId) {
							return;
						}
						setModalState(true);
					}}
				>
					<ContentCopy fontSize="small" color={!triggerId ? "" : "primary"} />
				</IconButton>
			</Tooltip>
			<Modal
				open={modalState}
				onClose={() => {
					setModalState(false);
					onEventModalClose();
				}}
			>
				<>
					<div
						className="bg-white px-6 py-4 rounded-md shadow-lg flex flex-col"
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							bgcolor: "background.paper",
							boxShadow: 24,
						}}
					>
						<div className="flex justify-between items-center w-full">
							<div className="text-lg font-semibold pb-2 pt-1">Paste Action In:</div>
							<Button
								variant="outlined"
								onClick={() => {
									confirmPaste();
								}}
								disabled={loading}
							>
								Confirm Paste
							</Button>
						</div>
						<TextField
							size="small"
							fullWidth
							variant="outlined"
							label="Search openings"
							placeholder="Search openings"
							className="my-4"
							onChange={(e) => {
								setFilter(e.target.value);
							}}
						/>
						<div
							className="flex flex-col gap-4 pb-2"
							style={{
								maxHeight: "70vh",
								width: "75vh",
								overflowY: "auto",
							}}
						>
							{allOpenings &&
								Array.isArray(allOpenings) &&
								allOpenings
									.filter((el) =>
										filter !== ""
											? String(el.title).toLowerCase().includes(filter.toLowerCase())
											: true,
									)
									.map((opening, index) => {
										return (
											<div key={index} className="flex w-full">
												<Accordion
													className="shadow-none flex flex-col w-full"
													style={{
														border: "1px solid #e0e0e0",
													}}
												>
													<AccordionSummary
														expandIcon={<ExpandMore />}
														aria-controls="panel1-content"
														id="panel1-header"
														className="shadow-none flex items-center align-middle"
													>
														<div className="flex align-middle items-center mr-5">
															<input
																type="checkbox"
																checked={
																	selectedOpeningsWithEvents[opening._id]
																		? selectedOpeningsWithEvents[
																				opening._id
																			] !== ""
																		: false
																}
																className="cursor-pointer"
																onClick={(e) => {
																	// setSelectedOpeningsWithEvents((prev) => {
																	// 	const newSelected = { ...prev };
																	// 	newSelected[opening._id] =
																	// 		!newSelected[opening._id];
																	// 	return newSelected;
																	// });
																}}
															/>
														</div>
														<div className="flex flex-col align-start w-full items-start">
															<div className="text-md font-semibold">
																{opening.title}
															</div>
															<div className="text-xs text-gray-500">
																{opening._id}
															</div>
														</div>
													</AccordionSummary>
													<AccordionDetails className="flex w-full">
														<div className="flex gap-2 flex-wrap">
															{[
																{
																	label: "ON_INTERVIEW_COMPLETE",
																	value: "1",
																	type: "system",
																},
																{
																	label: "ON_ADD_CANDIDATE",
																	value: "2",
																	type: "system",
																},
																{
																	label: "ON_COLUMN_CHANGE",
																	value: "3",
																	type: "system",
																},
																...(customEvents[opening._id] || []),
															]
																.filter((el) =>
																	actionType == "3" && el.type === "system"
																		? false
																		: true,
																)
																.map((el, index) => {
																	return (
																		<Events
																			key={index}
																			el={el}
																			opening={opening}
																			selectedOpeningsWithEvents={
																				selectedOpeningsWithEvents
																			}
																			setSelectedOpeningsWithEvents={
																				setSelectedOpeningsWithEvents
																			}
																		/>
																	);
																})}
														</div>
													</AccordionDetails>
												</Accordion>
											</div>
										);
									})}
						</div>
						<div>
							<div className="flex items-center gap-1 mt-3">
								<Info className="scale-75" />
								<p className="text-xs whitespace-break-spaces">
									Select all the System/Custom events from different openings where you want
									to paste this action.
								</p>
							</div>
						</div>
					</div>
				</>
			</Modal>
		</div>
	);
};

export default CopyActionComponent;

const Events = ({ el, opening, selectedOpeningsWithEvents, setSelectedOpeningsWithEvents }) => {
	return (
		<div className="flex mx-2">
			<input
				type="checkbox"
				className="cursor-pointer mr-2"
				checked={
					selectedOpeningsWithEvents[opening._id]
						? selectedOpeningsWithEvents[opening._id].split(",").includes(el.value)
						: false
				}
				label={el.label}
				onClick={(e) => {
					setSelectedOpeningsWithEvents((prev) => {
						const newSelected = {
							...prev,
						};
						if (newSelected[opening._id]) {
							if (newSelected[opening._id].split(",").includes(el.value)) {
								newSelected[opening._id] = newSelected[opening._id]
									.split(",")
									.filter((item) => item !== el.value)
									.join(",");
							} else {
								newSelected[opening._id] = newSelected[opening._id] + "," + el.value;
							}
						} else {
							newSelected[opening._id] = el.value;
						}
						return newSelected;
					});
				}}
			/>
			<div className="text-xs">{el.label}</div>
		</div>
	);
};
