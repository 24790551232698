import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import AlertMessage from "../components/AlertMessage";
import { useNavHeight } from "../custom-hooks/useNavHeight";
import { useSelector } from "react-redux";

const BaseNavPage = () => {
	const { user } = useSelector((state) => state.auth);
	const navClassName = user?.role === "1024" ? "nav-bar" : "super-admin-nav-bar";
	useNavHeight(navClassName);
	return (
		<>
			<Box
				className=" bg-gray-100"
				style={{
					paddingBottom: "var(--navbar-height, 78px)",
				}}
			>
				<Navbar />
			</Box>
			{user?.role === "1024" && <AlertMessage />}
			<Outlet />
		</>
	);
};

export default BaseNavPage;
