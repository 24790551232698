import { Box } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const FileViewer = () => {
	const { fileNameInS3 } = useParams();
	const [iframeLoaded, setIframeLoaded] = useState(false);

	const documentUrl = `https://procturemeet.s3.ap-southeast-1.amazonaws.com/resumes/${fileNameInS3}`;

	let viewerUrl;
	// if (fileNameInS3.endsWith(".pdf")) {
	// 	viewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(documentUrl)}&embedded=true`;
	// } else {
	viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(documentUrl)}&embedded=true`;
	// }

	return (
		<Box className="flex justify-center items-center w-full h-full min-h-screen">
			{!iframeLoaded && (
				<i className="fas fa-circle-notch fa-spin text-2xl"></i> // Loader
			)}
			<iframe
				className={`w-full h-full min-h-screen ${!iframeLoaded ? "hidden" : ""}`}
				src={viewerUrl}
				title="File Viewer"
				onLoad={() => setIframeLoaded(true)}
			></iframe>
		</Box>
	);
};

export default FileViewer;
