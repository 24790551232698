import { useEffect, useState } from "react";

export const NetworkImage = ({ src, ...props }) => {
	const [imageSrc, setImageSrc] = useState(null);

	useEffect(() => {
		fetch(src, {
			method: "GET",
			mode: "cors",
			headers: { "Cache-Control": "no-cache" },
		})
			.then((response) => response.blob())
			.then((blob) => {
				const imgURL = URL.createObjectURL(blob);
				setImageSrc(imgURL);
			})
			.catch((error) => console.error("Fetch error:", error));
	}, []);

	return imageSrc && <img src={imageSrc} {...props} />;
};
