import { Box, Modal } from "@mui/material";
import React from "react";

const modalStyles = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "95%",
	"@media (min-width:600px)": {
		width: "50%",
	},
	"@media (min-width:800px)": {
		width: "40%",
	},
	bgcolor: "background.paper",
	boxShadow: 5,
	px: 4,
	pt: 4,
	pb: 2,
	overflowY: "auto",
	maxHeight: "90vh",
	borderRadius: "10px",
	border: "none",
	outline: "none",
};

const QbModal = ({ children, open, handleClose }) => {
	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="qb-modal">
			<Box sx={modalStyles}>{children}</Box>
		</Modal>
	);
};

export default QbModal;
