import React from "react";
import ContentLoader from "react-content-loader";

const ReportsLoader = () => {
	return (
		<ContentLoader
			speed={1.5}
			width="100%"
			height={200}
			viewBox="0 0 100% 200"
			backgroundColor="#e2e8f0"
			foregroundColor="#cbd5e0"
		>
			{/* Title on the left */}
			<rect x="40" y="20" rx="4" ry="4" width="200" height="20" />

			{/* Top Options on the right side */}
			<rect x="730" y="20" rx="4" ry="4" width="150" height="40" />
			<rect x="900" y="20" rx="4" ry="4" width="150" height="40" />
			<rect x="1070" y="20" rx="4" ry="4" width="150" height="40" />

			{/* Table Header */}
			<rect x="40" y="80" rx="4" ry="4" width="100" height="20" />
			<rect x="150" y="80" rx="4" ry="4" width="100" height="20" />
			<rect x="730" y="80" rx="4" ry="4" width="100" height="20" />
			<rect x="900" y="80" rx="4" ry="4" width="100" height="20" />
			<rect x="1070" y="80" rx="4" ry="4" width="100" height="20" />

			{/* Single Table Row */}
			<rect x="40" y="110" rx="4" ry="4" width="100" height="40" />
			<rect x="150" y="110" rx="4" ry="4" width="100" height="40" />
			<rect x="730" y="110" rx="4" ry="4" width="100" height="40" />
			<rect x="900" y="110" rx="4" ry="4" width="100" height="40" />
			<rect x="1070" y="110" rx="4" ry="4" width="100" height="40" />
		</ContentLoader>
	);
};

export default ReportsLoader;
