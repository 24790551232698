import axios from "./axiosInstance";

/*
Admin API's related to transactions
*/

// fetch the transactions
async function fetchTransactionsAPI() {
	try {
		const response = await axios.get(`/api/transactions/`);
		if (response.status === 200) {
			return response.data.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while fetching transactions");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

// fucntion to add the credits
async function addCreditsAPI(data) {
	try {
		const response = await axios.post(`/api/transactions/add-credits`, data);
		if (response.status === 200) {
			return response.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while fetching add credits");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

export { fetchTransactionsAPI, addCreditsAPI };
