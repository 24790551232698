import { useEffect, useState } from "react";

export const Countdown = ({ targetTime }) => {
	const [timeLeft, setTimeLeft] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	useEffect(() => {
		if (!targetTime) return;

		const timer = setInterval(() => {
			const now = new Date();
			const interviewDate = new Date(targetTime);
			const difference = interviewDate.getTime() - now.getTime();

			if (difference > 0) {
				const days = Math.floor(difference / (1000 * 60 * 60 * 24));
				const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((difference % (1000 * 60)) / 1000);

				setTimeLeft({ days, hours, minutes, seconds });
			} else {
				clearInterval(timer);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [targetTime]);

	return (
		<div className="flex justify-between bg-blue-100 p-2 rounded">
			<div className="text-center">
				<span className="font-bold text-blue-700">{timeLeft.days}</span>
				<div className="text-xs text-blue-600">Days</div>
			</div>
			<div className="text-center">
				<span className="font-bold text-blue-700">{timeLeft.hours}</span>
				<div className="text-xs text-blue-600">Hours</div>
			</div>
			<div className="text-center">
				<span className="font-bold text-blue-700">{timeLeft.minutes}</span>
				<div className="text-xs text-blue-600">Minutes</div>
			</div>
			<div className="text-center">
				<span className="font-bold text-blue-700">{timeLeft.seconds}</span>
				<div className="text-xs text-blue-600">Seconds</div>
			</div>
		</div>
	);
};
