import { useState } from "react";
import Editor from "@monaco-editor/react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import ComponentHeading from "./ComponentHeading";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
function CodeEditor({
	handleEditorDidMount,
	language,
	extentions,
	languages,
	handleLanguageChange,
	codeExampleValue,
	onChange,
}) {
	const params = useParams();
	const [compiledOutput, setCompiledOutput] = useState("");
	const [compilationError, setCompilationError] = useState(null);
	const [loader, setLoader] = useState(null);
	const interviewReportId = params.interviewReportId;

	const handleCompile = async () => {
		const payload = {
			language,
			code: codeExampleValue,
			interviewReportId,
		};

		try {
			setLoader(true);
			const res = await axios.post("/api/code-execute", payload);
			const stdout = res?.data?.output?.stdout;

			setCompiledOutput(stdout);

			setCompilationError(null);
		} catch (e) {
			if (e.response) {
				const errMsg = e?.response?.data?.err?.stderr;
				if (errMsg) {
					const javaregex = /.*\.java([\s\S]+)/;
					const pyregex = /.*\.py([\s\S]+)/;
					const jsregex = /.*\.js([\s\S]+)/;
					const cppregex = /.*\.cpp([\s\S]+)/;
					const cregex = /.*\.c([\s\S]+)/;
					const csregex = /.*\.cs([\s\S]+)/;
					const matchJava = errMsg.match(javaregex);
					const matchPy = errMsg.match(pyregex);
					const matchJs = errMsg.match(jsregex);
					const matchCpp = errMsg.match(cppregex);
					const matchC = errMsg.match(cregex);
					const matchCs = errMsg.match(csregex);

					if (matchJava) {
						const textAfterError = matchJava[1]?.trim();
						setCompilationError(textAfterError);
						setCompiledOutput("");
					} else if (payload.language === "js") {
						const textAfterError = matchJs[1]?.trim();
						setCompilationError(textAfterError);
						setCompiledOutput("");
					} else if (payload.language === "py") {
						const textAfterError = matchPy[1]?.trim();
						setCompilationError(textAfterError);
						setCompiledOutput("");
					} else if (payload.language === "cpp") {
						const textAfterError = matchCpp[1]?.trim();
						setCompilationError(textAfterError);
						setCompiledOutput("");
					} else if (payload.language === "c") {
						const textAfterError = matchC[1]?.trim();
						setCompilationError(textAfterError);
						setCompiledOutput("");
					} else if (payload.language === "cs") {
						const textAfterError = matchCs[1]?.trim();
						setCompilationError(textAfterError);
						setCompiledOutput("");
					}
				}
			} else {
				setLoader(false);
				setCompilationError("Something went wrong. Please try again.");
				setCompiledOutput("");
			}
		} finally {
			setLoader(false);
		}
	};
	function getKeyByValue(object, value) {
		return Object.keys(object).find((key) => object[key] === value);
	}
	const languageKey = getKeyByValue(extentions, language);

	return (
		<Box
			component="main"
			id="code-editor-for-candidate"
			sx={{
				flexGrow: 1,
				bgcolor: "background.default",
			}}
		>
			{/* <Toolbar /> */}
			<ComponentHeading
				info={true}
				languages={languages}
				extentions={extentions}
				handleChange={handleLanguageChange}
				language={language}
			/>
			<Editor
				height={"20rem"}
				width={"100%"}
				defaultLanguage={languageKey}
				theme="vs-dark"
				onChange={onChange}
				onMount={handleEditorDidMount}
				loading={<EditorLoading />}
				language={languageKey}
				value={codeExampleValue}
				options={{ inlineSuggest: false }}
			/>
			{/* <Button
				sx={{ marginTop: '10px' }}
				onClick={handleCompile}
				color='primary'
				variant='contained'
				className={`normal-case text-xs bg-blue-500 text-white py-2 px-3 rounded hover:bg-blue-600 focus:outline-none focus:border-none`}>
				{loader ? (
					<CircularProgress
						style={{
							color: 'white',
							width: '16px',
							height: '16px',
						}}
					/>
				) : (
					'Run'
				)}
			</Button> */}
			{compiledOutput && (
				<div
					style={{
						backgroundColor: "#e2f0cb",
						border: "1px solid #b7cf89",
						borderRadius: "4px",
						padding: "10px",
						marginBottom: "20px",
						fontFamily: "monospace",
						whiteSpace: "pre-wrap",
						marginTop: "20px",
						overflow: "auto",
					}}
				>
					<strong style={{ color: "#267326" }}>Compiled Output:</strong>
					<pre style={{ color: "#267326", marginBottom: 0 }}>{compiledOutput}</pre>
				</div>
			)}
			{compilationError && (
				<div
					style={{
						backgroundColor: "#f8d7da",
						border: "1px solid #d73d4a",
						borderRadius: "4px",
						padding: "10px",
						marginBottom: "20px",
						marginTop: "20px",
						overflow: "auto",
					}}
				>
					<strong style={{ color: "#721c24" }}>Compilation Error:</strong>
					<pre style={{ color: "#721c24", marginBottom: 0 }}>{compilationError}</pre>
				</div>
			)}
		</Box>
	);
}

export default CodeEditor;

function EditorLoading() {
	return (
		<>
			<CircularProgress color="secondary" sx={{ marginRight: "1rem" }} />
			<Typography variant="body1" gutterBottom>
				{" "}
				Getting your editor ready...{" "}
			</Typography>
		</>
	);
}
