import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	notifications: [],
};

const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		addNotification: (state, action) => {
			const duplicateIndex = state.notifications.findIndex(
				(report) =>
					report.interviewReportId === action.payload.interviewReportId &&
					report.status === action.payload.status,
			);
			if (duplicateIndex !== -1) {
				state.notifications.splice(duplicateIndex, 1);
			}
			state.notifications.unshift(action.payload);
		},
		removeNotification: (state, action) => {
			let reports = state.notifications.filter((el) => {
				return !(
					el?.interviewReportId === action.payload.interviewReportId &&
					el?.status === action.payload.status
				);
			});
			state.notifications = reports;
		},
		removeAllNotification: (state, action) => {
			state.notifications = [];
		},
	},
});

export const { addNotification, removeNotification, removeAllNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
