import { Box } from '@mui/material';

const MultipleTabs = () => {
	return (
		<Box className='bg-gray-200'>
			<Box
				id='panel1'
				className='bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto'>
				<h2 className='text-center text-xl font-semibold mb-6'>
					Uh-oh! You can only open the interview link in one tab at a
					time. Please return to the original tab to continue your
					interview.
				</h2>
			</Box>
		</Box>
	);
};

export default MultipleTabs;
