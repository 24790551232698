import React from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  Toolbar,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';

const ComponentHeading = ({
  heading,
  info = false,
  languages,
  extentions,
  language,
  handleChange,
}) => {
  return (
    <Box>
      <Toolbar
        sx={{
          backgroundColor: '#337CCF',
          color: '#f1f1f1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '1rem',
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            display: 'flex',
          }}
        >
          {heading}
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: '#d4d4d4',
            justifySelf: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          After you've completed writing the code, use the microphone to explain
          or submit it
        </Typography>
        {info && (
          <LanguageOptions
            languages={languages}
            language={language}
            extentions={extentions}
            handleChange={handleChange}
          />
        )}
        {info && (
          <Tooltip title={<TooltipTitle />}>
            <InfoOutlinedIcon />
          </Tooltip>
        )}
      </Toolbar>
    </Box>
  );
};

export default ComponentHeading;

function LanguageOptions({ languages, language, extentions, handleChange }) {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <label htmlFor="code-editor-select">Language</label>
      <select
        class="text-black"
        id="code-editor-select"
        value={language}
        onChange={handleChange}
      >
        {Object.keys(extentions).map((language) => (
          <option value={`${extentions[language]}`} key={language}>
            {stringCapitalize(language)}
          </option>
        ))}
        {/* {languages.map((language) => (
         
        ))} */}
        {/* <option value="cpp">C++</option>
        <option value="java">Java</option>
        <option value="py">Python</option>
        <option value="js">Javascript</option> */}
      </select>
    </FormControl>
  );
}

function stringCapitalize(str) {
  let newStr = str[0].toUpperCase() + str.substring(1).toLowerCase();
  return newStr;
}

function TooltipTitle() {
  // return <span>Submit your code by pressing the <em>Save Code</em> button and explain your code using the microphone or textbox.</span>
  return (
    <div style={{ padding: '8px 14px' }}>
      <strong>Steps to follow : </strong>
      <ol>
        <li>
          Write the code example you've been asked for in your desired language.
        </li>
        <li>Explain your code using the microphone and submit.</li>
        <li>
          You can Undo or Redo your changes in this code editor using{' '}
          <em>Ctrl Z</em> or <em>Ctrl Y</em>
        </li>
      </ol>
    </div>
  );
}
