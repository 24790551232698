import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { openingCustomFieldsSchema } from "../../schemas/opening.schema";
import { updateCustomFieldsOfOpening } from "../../utilities/openingsApi";
import { toast } from "react-toastify";
import CustomFields from "../../pages/CreateUpdateOpening/CustomFields";
import OpeningButton from "../../pages/CreateUpdateOpening/Button";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOpening } from "../../features/createOpening/createOpeningSlice";

const AddCustomFeilds = () => {
	const dispatch = useDispatch();
	const openingData = useSelector((state) => state.openingDetails.selectedOpening);
	const [openAddFieldModal, setOpenAddFieldModal] = useState(false);
	const [isUpdatingCustomFields, setIsUpdatingCustomFields] = useState(false);
	const customFieldsModalStyles = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "95%",
		"@media (min-width:600px)": {
			width: "80%",
		},
		"@media (min-width:960px)": {
			width: "70%",
		},
		"@media (min-width:1280px)": {
			width: "45%",
		},
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
		// overflowY: "auto",
		maxHeight: "90vh",
		borderRadius: "10px",
	};
	const handleCustomFieldsModalClose = () => {
		setOpenAddFieldModal(false);
	};

	const methods = useForm({
		defaultValues: {
			customFields: [],
		},
		values: {
			customFields: openingData?.customFields ?? [],
		},
		resolver: zodResolver(openingCustomFieldsSchema),
	});

	const { handleSubmit } = methods;

	const handleUpdateCustomFields = async (data) => {
		try {
			setIsUpdatingCustomFields(true);
			const payload = {
				openingId: openingData._id,
				customFields: data.customFields,
			};
			const res = await updateCustomFieldsOfOpening(payload);
			dispatch(setSelectedOpening(res));
			setIsUpdatingCustomFields(false);
			handleCustomFieldsModalClose();
		} catch (e) {
			setIsUpdatingCustomFields(false);
			toast.warn(e.message);
		}
	};
	return (
		<>
			<Button
				className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
				onClick={() => {
					setOpenAddFieldModal(true);
				}}
				sx={{
					backgroundColor: "#3B82F6",
					":hover": {
						backgroundColor: "#1D4ED8",
					},
				}}
			>
				<SettingsIcon className="mr-1 h-5 w-5" />
				Add Custom Field
			</Button>
			<Modal
				open={openAddFieldModal}
				onClose={() => {
					if (!isUpdatingCustomFields) {
						handleCustomFieldsModalClose();
					}
				}}
				aria-labelledby="add-field-title"
				aria-describedby="add-custom-field"
				disableEscapeKeyDown={isUpdatingCustomFields}
			>
				<Box sx={customFieldsModalStyles} className="create-update-opening">
					<Typography
						id="add-field-title"
						variant="h6"
						component="h2"
						textAlign="center"
						gutterBottom
					>
						{"Add Custom Fields"}
					</Typography>
					<FormProvider {...methods}>
						<form noValidate onSubmit={handleSubmit(handleUpdateCustomFields)}>
							<CustomFields />

							<div className="flex flex-row gap-2 justify-end items-center">
								<OpeningButton
									text="Close"
									handleClick={handleCustomFieldsModalClose}
									type="button"
									className="bg-gray-400 hover:bg-gray-500 focus:bg-gray-500"
									disabled={isUpdatingCustomFields}
								/>
								<OpeningButton
									text="Save"
									handleClick={handleSubmit(handleUpdateCustomFields)}
									type="submit"
									disabled={isUpdatingCustomFields}
								/>
							</div>
						</form>
					</FormProvider>
				</Box>
			</Modal>
		</>
	);
};

export default AddCustomFeilds;
