import { useDispatch } from "react-redux";
import { hideSingleCandidate, unhideSingleCandidate } from "../../../app/actions/candidates/candidate";
import { IconButton, Tooltip } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ActionsColumn = ({ params }) => {
	const isHidden = params.row?.hidden;
	const dispatch = useDispatch();

	return (
		<div className="flex items-center justify-center w-full h-full">
			<Tooltip title={isHidden ? "Unhide Candidate" : "Hide Candidate"} arrow placement="top">
				<IconButton
					className="bg-orange-200 scale-[.6] visibility-icon-for-rows text-orange-500"
					onClick={() => {
						!isHidden
							? dispatch(hideSingleCandidate(params.row._id))
							: dispatch(unhideSingleCandidate(params.row._id));
					}}
				>
					{isHidden ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default ActionsColumn;
