import { Box } from '@mui/material';

const InvalidResumeToken = () => {
	return (
		<Box className='bg-gray-200'>
			<Box
				id='panel1'
				className='bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto'>
				<h2 className='text-center text-xl font-semibold mb-6'>
					Oops! It looks like the resume URL you're trying to access
					is either invalid or no longer active. Not to worry, though!
					To continue moving forward, please reach out to the HR team,
					and they'll provide you with a new URL. We're here to ensure
					your application process is as smooth as possible. Thanks
					for your understanding!
				</h2>
			</Box>
		</Box>
	);
};

export default InvalidResumeToken;
