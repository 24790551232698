import { TextField } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { Controller } from "react-hook-form";

const MultiLineTextArea = ({
	registeredName,
	id,
	placeholder,
	minRows = 1,
	maxRows = 12,
	control,
	className = "",
	darkerBorder = false,
	disabled = false,
	autoFocus = false,
}) => {
	const textFieldStyles = {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: darkerBorder ? "rgb(207, 207, 207)" : "rgb(229, 231, 235)",
			},
			"&:hover fieldset": {
				borderColor: darkerBorder ? "rgb(207, 207, 207)" : "rgb(229, 231, 235)",
			},
			"&.Mui-focused fieldset": {
				borderColor: "rgb(59, 130, 246)",
			},
			"&.Mui-focusVisible fieldset": {
				borderColor: "rgb(59, 130, 246)",
			},
			"&.Mui-focusWithin fieldset": {
				borderColor: "rgb(59, 130, 246)",
			},
		},
		fontFamily: "inherit",
		fontSize: "inherit",
	};

	return (
		<Controller
			name={registeredName}
			control={control}
			render={({ field: { onChange, value } }) => {
				return (
					<TextField
						sx={textFieldStyles}
						id={id}
						onChange={onChange}
						value={value}
						multiline
						minRows={minRows}
						maxRows={maxRows}
						fullWidth
						disabled={disabled}
						placeholder={placeholder}
						className={"custom-question-text-field text-sm border rounded-md mt-1"}
						InputProps={{
							className: clsx("text-sm p-2 border rounded-md mt-1", className),
						}}
						autoFocus={autoFocus}
					/>
				);
			}}
		/>
	);
};

export default MultiLineTextArea;
