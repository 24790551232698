import React from "react";
import { Box, Typography } from "@mui/material";

const AzurePronunciationRenderer = ({ params }) => {
	const interviewReport = params.row;
	const parsedResult = (() => {
		try {
			return JSON.parse(interviewReport?.azurePronunciationResult || "{}");
		} catch (error) {
			console.error("Error parsing JSON:", error);
			return {};
		}
	})();
	const introAudioClipURL = interviewReport?.introAudioClipURL;

	const pronScore =
		parsedResult?.pronunciationResult?.privPronJson?.PronunciationAssessment?.PronScore ??
		parsedResult?.PronunciationAssessment?.PronScore ??
		"N/A";

	// Check for both audio and score presence
	const hasAudio = Boolean(introAudioClipURL);
	const hasScore = pronScore !== "N/A";

	return (
		<Box className="flex flex-wrap w-full h-full justify-center align-middle items-center text-center">
			{!hasAudio && !hasScore ? (
				<Typography className="text-sm text-gray-500 break-words overflow-hidden whitespace-normal">
					No Audio or Score Found
				</Typography>
			) : (
				<>
					<Typography variant="subtitle1" className="text-gray-600 mr-2">
						Pronunciation Score: {pronScore}
					</Typography>

					{hasAudio ? (
						<Box mt={1}>
							<audio controls>
								<source src={introAudioClipURL} type="audio/mpeg" />
								Your browser does not support the audio element.
							</audio>
						</Box>
					) : (
						<Typography variant="subtitle1" className="text-gray-600 ml-2">
							Audio: N/A
						</Typography>
					)}
				</>
			)}

			{/* 
			const [isPlaying, setIsPlaying] = useState(false);
			const [open, setOpen] = useState(false);

			const audioRef = useRef(introAudioClipURL ? new Audio(introAudioClipURL) : null);

			const handleAudioToggle = () => {
				try {
					if (!audioRef.current) return;

					if (isPlaying) {
						audioRef.current.pause();
					} else {
						audioRef.current.play();
					}

					setIsPlaying(!isPlaying);

					audioRef.current.onended = () => setIsPlaying(false);
				} catch (error) {
					console.log(`Error in handleAudioToggle: ${error}`);
				}
			};

			const handleOpen = () => setOpen(true);
			const handleClose = () => setOpen(false);

			const hasJsonData = Object.keys(parsedResult).length > 0;

			<IconButton onClick={handleAudioToggle} color="primary">
				{isPlaying ? <Pause /> : <PlayArrow />}
			</IconButton>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="json-modal"
				aria-describedby="json-data"
			>
				<Paper
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "80%",
						maxHeight: "80%",
						overflow: "auto",
						padding: 2,
						color: "white",
					}}
				>
					<Typography variant="h6" id="json-modal" mb={2}>
						Azure Pronunciation Result
					</Typography>

					{introAudioClipURL && (
						<IconButton onClick={handleAudioToggle} color="primary">
							{isPlaying ? <Pause /> : <PlayArrow />}
						</IconButton>
					)}

					{hasJsonData ? (
						<Box mt={2} p={1} border="1px solid #ccc" borderRadius="4px">
							<JsonView
								data={parsedResult}
								shouldExpandNode={allExpanded}
								style={defaultStyles}
							/>
						</Box>
					) : (
						<Typography variant="body2" mt={2} textAlign="center">
							No JSON data available
						</Typography>
					)}
				</Paper>
			</Modal>
			*/}
		</Box>
	);
};

export default AzurePronunciationRenderer;
