import React from "react";
import { Box } from "@mui/material";

const MobileNotAllowed = () => {
	return (
		<Box id="panel2" className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto">
			<h2 className="text-center text-2xl font-bold mb-6">
				Just a heads-up! To participate in this interview, you'll need to switch to a desktop browser.
				This ensures you get the best possible experience and can fully engage with the interview
				process. Thanks for your cooperation!
			</h2>
		</Box>
	);
};

export default MobileNotAllowed;
