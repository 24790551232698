import { z } from "zod";
import { allLanguagesWithISO639Codes } from "../utilities/constants";
const ANY_DIFFICULTY = "Any";

const dropDownObjectSchema = z.object({
	id: z.string(),
	color: z.string(),
	value: z.string().trim().min(1),
});

const customFieldsSchema = z
	.object({
		_id: z.string().nullish(),
		type: z.number().min(0).max(1),
		fieldName: z
			.string({
				required_error: "field name is required",
			})
			.trim()
			.min(1),
		config: z.object({
			dropDownValues: z
				.array(dropDownObjectSchema, {
					required_error: "Drop down values are mandatory for a drop down field",
				})
				.min(1, "Atleast 1 value is required for drop down type")
				.optional(),
			defaultValue: z.string().nullish(),
		}),
	})
	.refine(
		({ type, config }) => {
			if (type === 1 && config?.dropDownValues?.length < 1) {
				return false;
			}
			return true;
		},
		{
			message: "Please add atleast one drop down option for your drop down field",
			path: ["config.dropDownValues"],
		},
	);

export const openingCustomFieldsSchema = z.object({
	customFields: z.array(customFieldsSchema),
});

const eachJobRequirementSchema = z.object({
	value: z
		.string({
			required_error: "Each job responisibility/requirement value cannot be empty and is required",
		})
		.min(1, "job responisibility/requirement value cannot be empty"),
});

// Common options schema
const commonOptionSchemas = z.object({
	title: z.string().trim().min(1, "Job Title cannot be empty"),
	experienceRange: z.tuple([
		z
			.number({ required_error: "min experience cannot be empty" })
			.min(0, "Min experience should be atleast 0 years"),
		z.number({ required_error: "max experience cannot be empty" }),
	]),
	proctoring: z.boolean(),
	isSecondaryCameraRequired: z.boolean(),
	isCodeEditorRequired: z.boolean(),
	isFaceMatchRequired: z.boolean(),
	avatarMode: z.boolean(),
	questionsBasedOnResume: z.boolean(),
	isMobileInterviewAllowed: z.boolean(),
	allowSupportContact: z.boolean(),
	supportPhone: z.string().min(7, "Please provide a valid phone number"),
	supportName: z.string().min(3, "Name should be atleast 3 characters long"),
	supportEmail: z.string().trim().email({
		message: "Please provide a valid email",
	}),
	emailRecipients: z.array(
		z.object({
			value: z.string().trim().email("Please provide valid emails to get notified"),
		}),
	),
	maxQuestions: z
		.number()
		.min(5, "The max number of questions cannot be less than 5")
		.max(18, "The max number of questions cannot be greater than 18"),
	interviewGuidelines: z.string().trim().optional(),
	customQuestions: z
		.array(
			z.object({
				value: z.string().trim().min(1, "Custom Question cannot be empty!"),
			}),
		)
		.max(15, "The number of custom questions cannot exceed 15"),
	mixOfBothQuestions: z.enum(["true", "false"]),
	jobRequirementsAndResponsibilities: z
		.array(eachJobRequirementSchema, {
			required_error:
				"Job requirements and responsibilities are mandatory and are missing in the given schema.",
		})
		.min(1, "A minimum of 1 job requirement and responisibility is required"),
	customFields: z.array(customFieldsSchema),
	autoSkipQTimeoutS: z.number().refine((val) => val === 0 || (val >= 30 && val <= 120), {
		message: "Value must be 0 (off) or between 30 and 120 seconds",
	}),
	languageOfQuestions: z.enum(allLanguagesWithISO639Codes),
	languageOfAnswers: z.enum(allLanguagesWithISO639Codes),
	questionCategoriesFromBank: z
		.array(
			z.object({
				_id: z.string().nullish(),
				categoryId: z.string().min(5, "Category is missing"),
				difficulty: z.enum([ANY_DIFFICULTY, "Easy", "Normal", "Hard"]),
				numberOfQuestions: z.number().min(1).max(18),
			}),
		)
		.min(0)
		.max(10, "Only a maximum of 10 categories can be added"),
});

const supportInfoAkaAssignedManagerSchema = commonOptionSchemas.pick({
	supportEmail: true,
	supportName: true,
	supportPhone: true,
	allowSupportContact: true,
});

// technical skills schema
const eachSkillSchema = z.object({
	value: z
		.string({ required_error: "Each skill in a skill group cannot be an empty string!" })
		.min(1, "Skill name cannot be empty"),
});

const skillGroupSchema = z.object({
	_id: z.string().nullish(),
	skillGroupName: z
		.string({
			required_error: "Skill Group name is required",
		})
		.min(1, "Skill group name cannot be empty"),
	skills: z
		.array(eachSkillSchema, {
			required_error: "Each skill group should have at least one skill.",
		})
		.min(1, "Each skill group should have at least one skill"),
	criteria: z
		.number({
			required_error: "Criteria is missing for the current skill group",
		})
		.min(1)
		.max(3),
});
const skillsGroupSchema = z
	.array(skillGroupSchema, {
		required_error: "Atleast 1 skills group is required to create an opening",
	})
	.min(1, "Please add at least on skill group");

const baseTechnicalOpeningSchema = z.object({
	skillsGroups: skillsGroupSchema,
});

const technicalOpeningSchema = baseTechnicalOpeningSchema
	.merge(commonOptionSchemas)
	.passthrough()
	.refine(
		(data) => {
			const { customQuestions = [], questionCategoriesFromBank = [] } = data;
			const a = customQuestions.length;
			const b = questionCategoriesFromBank.reduce((acc, curr) => acc + curr.numberOfQuestions, 0);
			console.log("The lengths are ", a, b);
			return a + b <= 18;
		},
		{
			message: `Total sum of custom questions cannot be more than 18.`,
			path: ["customQuestions"],
		},
	);

// non-technical skills schema

const eachCoreSkillSchema = z.object({
	value: z
		.string({
			required_error: "Each core skill value cannot be empty and is required",
		})
		.min(1, "Core skill value cannot be empty"),
});

const baseNonTechnicalOpeningSchema = z.object({
	coreSkills: z
		.array(eachCoreSkillSchema, {
			required_error: "Core skills are mandatory and are missing in the given schema.",
		})
		.min(1, "At least one core skill is required for the job"),
});

const nonTechnicalOpeningSchema = baseNonTechnicalOpeningSchema
	.merge(commonOptionSchemas)
	.passthrough()
	.refine(
		(data) => {
			const { customQuestions = [], questionCategoriesFromBank = [] } = data;
			const a = customQuestions.length;
			const b = questionCategoriesFromBank.reduce((acc, curr) => acc + curr.numberOfQuestions, 0);
			return a + b <= 18;
		},
		{
			message: "Total sum of custom questions cannot be more than 18",
			path: ["customQuestions"],
		},
	);

export { technicalOpeningSchema, nonTechnicalOpeningSchema, supportInfoAkaAssignedManagerSchema };
