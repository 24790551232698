import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/zinterview-logo-white.png";
import SideDrawer from "../pages/SuperAdmin/SideDrawer";
import "./NavbarStyles.css";
import { getOrganizationName } from "../utilities/organizationApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography, Chip } from "@mui/material";
import NotificationInfoPage from "./SuperAdmin/NotificationInfoPage";

function Navbar() {
	const { user } = useSelector((state) => state.auth);
	const { organizationId } = useParams() || "";
	const [organizationName, setOrganizationName] = useState("Riktam");
	const [drawerOpen, setDrawerOpen] = useState(false);

	const navBarId = user?.role === "1024" ? "nav-bar" : "super-admin-nav-bar";

	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setDrawerOpen(open);
	};

	useEffect(() => {
		const getOrgName = async (organizationId) => {
			const response = await getOrganizationName(organizationId);
			setOrganizationName(response.data.organizationName);
		};
		if (organizationId) getOrgName(organizationId);
		else setOrganizationName(user?.organizationName || "");
	}, [organizationId]);

	return (
		<div>
			<Box
				className="fixed top-0 w-full bg-blue-500 p-4 flex justify-between items-center"
				id={navBarId}
				sx={{ zIndex: "1000" }}
			>
				<Box className="flex items-center w-full">
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
						<MenuIcon color="white" />
					</IconButton>
					<div className="relative flex items-center justify-center w-full h-full flex-1">
						<img src={logo} alt="Zinterview Logo" className="w-44 absolute left-0" />
					</div>
				</Box>
				<SideDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
				{organizationId && (
					<Box className="flex items-center gap-[1rem]">
						<Typography
							variant="p"
							className="text-white no-underline text-sm hover:underline cursor-pointer"
						>
							<Chip label={`Logged in account of ${organizationName}`} color="primary" />
						</Typography>
					</Box>
				)}
				{user?.role === "1096" && <NotificationInfoPage />}
			</Box>
		</div>
	);
}

export default Navbar;
