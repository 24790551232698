import React, { useState } from "react";
import {
	Box,
	Button,
	Chip,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import logo from "../../assets/zi-favicon.png";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	maxWidth: "100%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	overflowY: "auto",
	maxHeight: "90vh",
	borderRadius: "10px",
	"@media (min-width: 800px)": {
		width: 800,
	},
};

const LineItems = ({ organizationId, organization, getOrganizationInvoices, invoice }) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button
				variant="outlined"
				color="warning"
				className="flex-1 mx-2"
				onClick={() => setShowModal(true)}
			>
				Line Items
			</Button>
			<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
				aria-labelledby="deactivate account"
				aria-describedby="deactivate account"
			>
				<Box sx={modalStyle}>
					<Box className="flex justify-between items-center mb-6">
						<img src={logo} alt="Zinterview Logo" className="h-16" />
						<Typography variant="h6" component="h2">
							Line Items
						</Typography>
					</Box>

					{/* <Box className="mb-4">
						<Typography variant="body1">
							<strong>{organization?.organizationName}</strong>
						</Typography>
						<Typography variant="body2">{organization?.contactInfo}</Typography>
					</Box> */}

					{/* <Box className="flex justify-between mb-4">
						<Typography variant="body2">
							<strong>Invoice Date :</strong> {new Date(invoice.issuedDate).toDateString()}
						</Typography>
						<Typography variant="body2">
							<strong>Due Date :</strong> {new Date(invoice.dueDate).toDateString()}
						</Typography>
					</Box> */}

					<TableContainer className="mb-4">
						<Table>
							<TableHead>
								<TableRow className="bg-gray-100">
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										S.No.
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Description
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Rate
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Tax
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Amount
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Qty
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Total Amount
									</TableCell>
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Status
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{invoice?.lineItems.length > 0 ? (
									invoice.lineItems.map((row, index) => (
										<TableRow key={row._id}>
											<TableCell className="text-center">{index + 1}</TableCell>
											<TableCell className="text-center">{row.description}</TableCell>
											<TableCell className="text-center">
												{row.price.toFixed(2)}
											</TableCell>
											<TableCell className="text-center">
												{row.calculatedTax.toFixed(2)}
											</TableCell>
											<TableCell className="text-center">
												{row.amount.toFixed(2)}
											</TableCell>
											<TableCell className="text-center">{row.qty}</TableCell>
											<TableCell className="text-center">
												{(row.amount * row.qty).toFixed(2)}
											</TableCell>
											<TableCell className="text-center">
												{row.paid ? (
													<Chip label="Paid from Balance" color="success"></Chip>
												) : (
													<Chip label="Outstanding" color="error"></Chip>
												)}
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell colSpan={5} className="text-center">
											No Line items
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>

					{/* <Grid container justifyContent="flex-end">
						<Grid item xs={12} sm={6} md={4}>
							<Box className="flex justify-between mb-2">
								<Typography variant="body2">Sub Total : </Typography>
								<Typography variant="body2">{invoice.totalPrice}</Typography>
							</Box>
							<Box className="flex justify-between mb-2">
								<Typography variant="body2">Total Tax : </Typography>
								<Typography variant="body2">{invoice.totalTax}</Typography>
							</Box>
							<Box className="flex justify-between mb-2">
								<Typography variant="body2">
									<strong>Total : </strong>
								</Typography>
								<Typography variant="body2">
									<strong>{invoice.totalAmount}</strong>
								</Typography>
							</Box>
							<Box className="flex justify-between mb-2">
								<Typography variant="body2">
									<strong>
										Total In Words : {toWords(invoice.totalAmount).toUpperCase()} Only
									</strong>
								</Typography>
							</Box>
						</Grid>
					</Grid> */}
				</Box>
			</Modal>
		</>
	);
};

export default LineItems;
