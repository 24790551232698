import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Box, IconButton, Chip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { getOrganizationName } from "../../utilities/organizationApi";
import logo from "../../assets/zinterview-logo-white.png";
import SideDrawer from "./SideDrawer";
import "../NavbarStyles.css";

function Navbar() {
	const { user } = useSelector((state) => state.auth);
	const { organizationId } = useParams();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [organizationName, setOrganizationName] = useState("Riktam");

	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setDrawerOpen(open);
	};

	useEffect(() => {
		const getOrgName = async (organizationId) => {
			const response = await getOrganizationName(organizationId);
			setOrganizationName(response.data.organizationName);
		};
		if (organizationId) getOrgName(organizationId);
		else setOrganizationName(user.organizationName);
	}, [organizationId]);

	return (
		<Box
			className="fixed top-0 w-full bg-blue-500 p-4 flex justify-between items-center"
			id="super-admin-nav-bar"
			sx={{ zIndex: "1000" }}
		>
			<Box className="flex items-center">
				<IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
					<MenuIcon color="white" />
				</IconButton>
				<img src={logo} alt="Zinterview Logo" className="h-8" />
			</Box>
			<SideDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
			<Box className="flex items-center gap-[1rem]">
				<Typography
					variant="p"
					className="text-white no-underline text-sm hover:underline cursor-pointer"
				>
					{organizationId && (
						<Chip label={`Logged in account of ${organizationName}`} color="primary" />
					)}
				</Typography>
			</Box>
		</Box>
	);
}

export default Navbar;
