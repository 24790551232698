import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	Table,
	Typography,
	TableBody,
	TableContainer,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from "@mui/material";
import DropReportRows from "../components/dropInterviews/DropReportRows";
import NoReportsFoundMessage from "../components/onGoingInterviews/NoReportsFoundMessage";
import { updateInputValues } from "../features/dropReports/dropReportsSlice";
import SearchReport from "../components/onGoingInterviews/SearchReport";

const TabularDropInterviewEvaluations = () => {
	const { allDropReports, dropSearchInputValue } = useSelector((state) => state.dropReports);
	let allDropReportsLength = allDropReports?.length || 0;
	const unscripted = localStorage.getItem("unscripted");
	const dispatch = useDispatch();

	const FilterReport = () => {
		const searchValue = dropSearchInputValue.trim().toLowerCase();
		try {
			return allDropReports.filter((report) => {
				const {
					firstName = "",
					lastName = "",
					phoneNumber = "",
					opening = "",
					email = "",
					openingTitle = "",
				} = report;
				const fullName = `${firstName.trim()} ${lastName.trim()}`.toLowerCase();
				return (
					fullName.toLowerCase().includes(searchValue) ||
					phoneNumber.toLowerCase().includes(searchValue) ||
					opening.toLowerCase().includes(searchValue) ||
					email.toLowerCase().includes(searchValue) ||
					openingTitle.toLowerCase().includes(searchValue) ||
					report?.organization?.organizationName.toLowerCase().includes(searchValue)
				);
			});
		} catch (error) {
			console.error("Error filtering reports:", error);
			return [];
		}
	};

	const dropReports = useMemo(() => {
		return FilterReport();
	}, [allDropReports, dropSearchInputValue]);

	const columns = [
		{ label: "Candidate Details", minWidth: 200 },
		{ label: "Opening Details", minWidth: 200 },
		{ label: "Interview Start Time", minWidth: 170 },
		{ label: "Dropped Time", minWidth: 170 },
		{ label: "Org Name", minWidth: 170 },
		{ label: "Trust Score", minWidth: 130 },
		{ label: "Interview Status", minWidth: 170 },
		{ label: "Resume", minWidth: 100 },
		{ label: "View Report", minWidth: 130 },
		{ label: "Logs", minWidth: 130 },
		...(unscripted === "show"
			? [
					{ label: "EPS Score", minWidth: 120 },
					{ label: "CEFR Score", minWidth: 120 },
					{ label: "Audio Clip", minWidth: 120 },
				]
			: []),
		{ label: "Actions", minWidth: 100 },
	];

	function onChangeInput(value) {
		dispatch(updateInputValues(value));
	}

	return (
		<Box className="margin-top-for-nav">
			<Box className={`mx-auto w-[96%] sm:w-[85%]`}>
				<Box className="flex justify-between mt-4">
					<div className=" flex flex-wrap p-1 rounded-lg">
						<Typography variant="h6" className="text-blue-600 font-semibold mb-2">
							Drop Reports
							<Tooltip
								title={`${allDropReportsLength || 0} ${
									allDropReportsLength <= 1 ? "Report" : "Reports"
								} Found`}
								arrow
							>
								({allDropReportsLength || 0})
							</Tooltip>
						</Typography>
					</div>
					<SearchReport
						reportsLength={allDropReportsLength}
						onChangeInput={onChangeInput}
						inputVlaue={dropSearchInputValue}
					/>
				</Box>
				{(allDropReportsLength && allDropReportsLength) > 0 ? (
					<>
						<TableContainer
							className="shadow-lg rounded-md table-auto w-full bg-white text-sm text-left border-collapse"
							sx={{ maxHeight: "70vh", height: "70vh" }}
						>
							<Table className="shadow-sm rounded-lg" stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow className="bg-blue-500">
										{columns?.map((column, id) => (
											<TableCell
												key={id}
												style={{ minWidth: column.minWidth }}
												className="text-center text-white bg-blue-500"
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{dropReports?.map((report, index) => {
										return (
											<TableRow key={index} className="hover:bg-gray-100">
												<DropReportRows report={report} />
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						{allDropReportsLength && (
							<p className="text-red-500 text-center text-sm mt-5">
								Please do not refresh the page. This report is generated in real-time from the
								server, and refreshing will cause the data to be lost.
							</p>
						)}
					</>
				) : (
					<NoReportsFoundMessage />
				)}
			</Box>
		</Box>
	);
};

export default TabularDropInterviewEvaluations;
