import axios from "../axiosInstance";

/*
Super Admin API related to transactions
*/

// API to fetch the transactions
async function fetchTransactionsSuperAdminAPI(data) {
	try {
		const response = await axios.post(`/api/superadmin/transactions/`, data);
		if (response.status === 200) {
			return response.data.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while fetching transactions");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

// API to update the billing cycle
async function updateBillingCycleSuperAdminAPI(data) {
	try {
		const response = await axios.post(`/api/superadmin/transactions/update-billing`, data);
		if (response.status === 200) {
			return response.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while update billing cycle");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

// API to consume interview
async function consumeInterviewSuperAdminAPI(data) {
	try {
		const response = await axios.post(`/api/superadmin/transactions/consume-interview`, data);
		if (response.status === 200) {
			return response.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while consuming interview");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

export { fetchTransactionsSuperAdminAPI, updateBillingCycleSuperAdminAPI, consumeInterviewSuperAdminAPI };
