import { Tooltip, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
const ViewReportsColumn = ({ interviewReportId, openingId }) => {
	return (
		<Tooltip title="View Reports" arrow>
			<IconButton
				component={Link}
				to={`/admin/evaluation/${interviewReportId}?openingId=${openingId}`}
				variant="text"
				className="inline-flex items-center justify-center h-8 w-8 bg-blue-100 text-blue-500 rounded-full hover:bg-blue-200"
				target="_blank"
			>
				<i className="fas fa-chevron-right text-sm hover:text-blue-700"></i>
			</IconButton>
		</Tooltip>
	);
};
export default ViewReportsColumn;
