import * as React from "react";
import {
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Typography,
	Chip,
	CircularProgress,
} from "@mui/material";
import { useState } from "react";
import svg from "../../assets/multitasking.svg";
import { useEffect } from "react";
import { getTriggers, getEventsAndActions } from "../../utilities/eventsApi";
import APIFormRenderer from "./actions/APIFormRenderer.jsx";
import EmailFormRenderer from "./actions/EmailFormRenderer.jsx";
import { API_FORM_FORMAT, EMAIL_TEMPLATE } from "./constants.js";
import { toast } from "react-toastify";

const SystemEventsRenderer = ({ openingId, fields = [], customFields = [] }) => {
	const [eventsAndActions, setEventsAndActions] = useState({
		events: [],
		actions: [],
	});
	const [loading, setLoading] = useState({
		state: "",
		status: false,
	});
	const [actionAndEventStates, setActionAndEventStates] = useState({
		selectedEvent: {},
		selectedAction: {},
	});
	const [loadingActions, setLoadingActions] = useState(false);

	const [actionData, setActionData] = useState([]);

	const [actionsForAnEvent, setActionsForAnEvent] = useState([]);
	const [initialLoading, setInitialLoading] = useState(false);

	const onEventSelection = (e) => {
		setActionAndEventStates({
			...actionAndEventStates,
			selectedEvent: { id: e.target.value, name: e.target.name, type_id: e.target.value },
		});
		getAndSetEvents(e.target.value);
	};

	const onActionSelection = (e) => {
		setActionAndEventStates({
			...actionAndEventStates,
			selectedAction: { id: e.target.value, name: e.target.name, type_id: e.target.value },
		});
		populateFields(e.target.value);
	};

	const onClickAddAction = (e) => {
		switch (actionAndEventStates.selectedAction.type_id) {
			case "1":
				setActionData([...actionData, { ...API_FORM_FORMAT }]);
				break;
			case "2":
				setActionData([...actionData, { ...EMAIL_TEMPLATE }]);
				break;
			default:
				break;
		}
	};

	const populateFields = (keyword, events = null) => {
		let actions = (events ? events : actionsForAnEvent).filter(
			(el) => Number(el.action) === Number(keyword),
		);

		switch (keyword) {
			case "1":
				setActionData(actions);
				break;
			case "2":
				setActionData(actions);
				break;
			case "3":
				break;
			default:
				break;
		}
	};

	const getAndSetEvents = async (event) => {
		if (!event) return;
		let body = {
			keyword: event,
			openingId: openingId,
		};
		try {
			setLoadingActions(true);
			let response = await getTriggers(body);
			if (response && response.data) {
				let allActionForAnEvent = response.data.map((el) => {
					let action = JSON.parse(el.actionData);
					action.id = el._id;
					action.isPaused = el.isPaused;
					action.action = el.actionTypeId;
					return action;
				});
				setActionsForAnEvent(allActionForAnEvent);
				if (actionAndEventStates.selectedAction.id !== "") {
					populateFields(actionAndEventStates.selectedAction.id, allActionForAnEvent);
				}
			} else {
				console.log(response);
			}
		} catch (error) {
			console.log(error);
			// toast.error("Error fetching actions for the event");
		} finally {
			setLoadingActions(false);
		}
	};

	useEffect(() => {
		getActionsAndEvents();
	}, []);

	const getActionsAndEvents = async () => {
		setInitialLoading(true);
		try {
			let response = await getEventsAndActions();
			if (response && response.data) {
				let events = response.data.events?.map((el) => {
					return { value: el.eventName, id: el._id, type_id: el.typeId };
				});
				let actions = response.data.actions?.map((el) => {
					return {
						value: el.actionName,
						id: el._id,
						type_id: el.typeId,
						isDisabled: el.isDisabled
							? true
							: el.enabledFor?.includes("SYSTEM_EVENTS")
								? false
								: true,
					};
				});
				setEventsAndActions({
					events: events,
					actions: actions,
				});
			} else {
				console.log(response);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setInitialLoading(false);
		}
	};

	const Seperator = () => {
		return (
			<Divider
				style={{
					height: "98%",
					marginRight: "1rem",
				}}
				orientation="vertical"
			/>
		);
	};

	const actionHeaderRenderer = (actionType) => {
		switch (actionType) {
			case "1":
			case "2":
				return (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginBottom: "1rem",
						}}
					>
						<Typography id="modal-modal-title" variant="h6" component="h6">
							Add / Edit Actions{" "}
							<span>
								{actionAndEventStates.selectedAction.id !== "" && (
									<Chip label={actionData.length} color="primary" />
								)}
							</span>
						</Typography>
						<Button variant="contained" disabled={loadingActions} onClick={onClickAddAction}>
							Add Action {loadingActions && <CircularProgress className="ml-3" size={20} />}
						</Button>
					</div>
				);
			default:
				return null;
		}
	};

	const RenderActions = () => {
		if (actionAndEventStates.selectedAction.type_id === undefined) {
			return (
				<div className="flex-col flex justify-center align-middle w-full items-center">
					<img src={svg} alt="workflow.svg" className="max-w-[60%]" />
					<Typography variant="p" className="text-gray-500 flex justify-center text-center mt-10">
						To avoid repetitive tasks, implement an automated workflow or process.
					</Typography>
				</div>
			);
		}

		if (loadingActions) {
			return (
				<div className="w-full h-full flex justify-center items-center">
					<CircularProgress />
				</div>
			);
		}

		switch (actionAndEventStates.selectedAction.type_id) {
			case "1":
				return (
					<div className="flex flex-col w-full">
						{actionHeaderRenderer(actionAndEventStates.selectedAction.type_id)}
						<APIFormRenderer
							apiForm={actionData}
							setApiForm={setActionData}
							actionAndEventStates={actionAndEventStates}
							selectedEventId={actionAndEventStates.selectedEvent.id}
							selectedActionId={actionAndEventStates.selectedAction.id}
							loading={loading}
							setLoading={setLoading}
							fields={fields}
							customFields={customFields}
							disabledOperatorTypes={[]}
							saveResponseHandler={(responseHandler) => {
								console.log(responseHandler);
							}}
							openingId={openingId}
							afterSave={() => {}}
							afterDelete={() => {}}
							afterUpdate={() => {}}
						/>
					</div>
				);
			case "2":
				return (
					<div className="flex relative flex-col h-full w-full">
						{actionHeaderRenderer(actionAndEventStates.selectedAction.type_id)}
						<EmailFormRenderer
							openingId={openingId}
							fields={fields}
							loading={loading}
							setLoading={setLoading}
							actionData={actionData}
							setActionData={setActionData}
							onClickAddAction={onClickAddAction}
							selectedEventId={actionAndEventStates.selectedEvent.id}
							selectedActionId={actionAndEventStates.selectedAction.id}
							afterSave={() => {}}
							afterDelete={() => {}}
							afterUpdate={() => {}}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<>
			{initialLoading ? (
				<div className="w-full h-full flex justify-center items-center">
					<CircularProgress />
				</div>
			) : (
				<>
					<RadioOptionSelector
						values={eventsAndActions.events}
						onSelect={onEventSelection}
						label="Events"
					/>
					<Seperator />
					{actionAndEventStates.selectedEvent.hasOwnProperty("id") &&
						actionAndEventStates.selectedEvent.id != "" && (
							<>
								<RadioOptionSelector
									values={eventsAndActions.actions}
									onSelect={onActionSelection}
									label="Actions"
									isDisabled={loadingActions}
								/>
								<Seperator />
							</>
						)}
					{RenderActions()}
				</>
			)}
		</>
	);
};

export const RadioOptionSelector = ({ values = [], selectedValue, onSelect, label, isDisabled }) => {
	return (
		<div
			style={{
				minWidth: "max-content",
			}}
		>
			<FormControl>
				<FormLabel id="radio-buttons-group-label">{label}</FormLabel>
				<RadioGroup aria-labelledby="radio-buttons-group-label" name="radio-buttons-group">
					{values.map((element, index) => {
						return (
							<div className="flex justify-start items-center">
								<FormControlLabel
									key={index}
									value={element.type_id}
									control={<Radio onClick={onSelect} />}
									label={element.value}
									name={element.value}
									disabled={isDisabled || element.isDisabled}
									sx={{
										"& .MuiSvgIcon-root": {
											fontSize: 16,
										},
										".MuiFormControlLabel-label": {
											fontSize: ".8rem",
										},
									}}
								/>
								{/* {label === "Actions" && actionCountForAnEvent(element.type_id) > 0 && (
									<Chip
										size="small"
										label={actionCountForAnEvent(element.type_id)}
										color="primary"
									/>
								)} */}
							</div>
						);
					})}
				</RadioGroup>
			</FormControl>
		</div>
	);
};

export default SystemEventsRenderer;
