import axios from "./axiosInstance";

async function fetchDefaultSupNum(interviewReportId) {
	try {
		const response = await axios.get(`/api/globalVars/fetchDefaultSupNum`);
		return response.data;
	} catch (error) {
		const message = error?.response?.data?.message || error.message;
		throw new Error(message);
	}
}

export { fetchDefaultSupNum };
