import clsx from "clsx";
import React from "react";

const Button = ({ type = "button", text = "", className = "", handleClick, disabled = false }) => {
	return (
		<button
			disabled={disabled}
			type={type}
			className={clsx(
				"w-fit cursor-pointer rounded border-none bg-blue-500 px-4 py-2 text-white outline-none hover:bg-blue-700  disabled:opacity-25",
				className,
			)}
			onClick={handleClick}
		>
			{text}
		</button>
	);
};

export default Button;
