import React, { useEffect, useState } from "react";
import {
	Button,
	Chip,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import { fetchInvoicesSuperAdminAPI } from "../../utilities/superAdmin/invoices";
import Invoice from "./Invoice";
import LineItems from "./LineItems";
import { fetchInvoicesAPI } from "../../utilities/invoices";
import { useSelector } from "react-redux";
import MarkInvoiceAsPaid from "./MarkInvoiceAsPaid";

const Invoices = ({ organizationId, organization, getOrganizationsDetails }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [invoices, setInvoices] = useState([]);
	const { user } = useSelector((state) => state.auth);

	const getOrganizationInvoices = async () => {
		try {
			setIsLoading(true);
			const data = { organizationId };
			if (user?.role === "1096") {
				const res = await fetchInvoicesSuperAdminAPI(data);
				setInvoices(res);
			} else if (user?.role === "1024") {
				const res = await fetchInvoicesAPI();
				setInvoices(res);
			}

			setIsLoading(false);
		} catch (error) {
			console.log(error.message);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getOrganizationInvoices();
	}, [organizationId]);

	return (
		<div className="w-full mx-auto p-2">
			{isLoading ? (
				<div className="text-center">
					<CircularProgress />
				</div>
			) : (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow className="bg-gray-100 min-w-full border rounded-lg overflow-hidden text-sm">
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Issue Date
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Amount
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Status
								</TableCell>
								{organization?.zohoCustomerId && (
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Line Items
									</TableCell>
								)}

								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Invoice
								</TableCell>
								{user?.role === "1096" && (
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Action
									</TableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{invoices.length > 0 ? (
								invoices.map((row) => (
									<TableRow key={row._id}>
										<TableCell className="text-center" component="th" scope="row">
											{new Date(row.issuedDate).toDateString()}
										</TableCell>
										<TableCell className="text-center">{row.totalAmount}</TableCell>
										<TableCell className="text-center">
											{row.status === "unpaid" ? (
												<Chip label="Unpaid" color="warning" />
											) : (
												<Chip label="Paid" color="success" />
											)}
										</TableCell>
										{organization?.zohoCustomerId && (
											<TableCell className="text-center">
												<LineItems
													organizationId={organizationId}
													organization={organization}
													getOrganizationInvoices={getOrganizationInvoices}
													invoice={row}
													key={row._id}
												/>
											</TableCell>
										)}
										<TableCell className="text-center">
											{organization?.zohoCustomerId ? (
												row?.zohoInvoiceURL ? (
													<Button
														variant="outlined"
														color="primary"
														className="flex-1 mx-2"
														onClick={() =>
															window.open(row.zohoInvoiceURL, "_blank")
														}
													>
														Invoice
													</Button>
												) : (
													<Invoice
														organizationId={organizationId}
														organization={organization}
														getOrganizationInvoices={getOrganizationInvoices}
														invoice={row}
														key={row._id}
													/>
												)
											) : (
												<Invoice
													organizationId={organizationId}
													organization={organization}
													getOrganizationInvoices={getOrganizationInvoices}
													invoice={row}
													key={row._id}
												/>
											)}
										</TableCell>

										{user?.role === "1096" && (
											<TableCell className="text-center">
												{row.status === "unpaid" && (
													<MarkInvoiceAsPaid
														organizationId={organizationId}
														organization={organization}
														getOrganizationInvoices={getOrganizationInvoices}
														getOrganizationsDetails={getOrganizationsDetails}
														invoice={row}
														key={row._id}
													/>
												)}
											</TableCell>
										)}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={6} className="text-center">
										No invoices till now
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default Invoices;
