import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const Modal403 = ({ showModal, setShowModal }) => {
	return (
		<Modal
			open={showModal}
			onClose={() => setShowModal(false)}
			aria-labelledby="403-issue-modal-title"
			aria-describedby="403-issue-modal-description"
		>
			<Box
				className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white p-6 rounded-lg shadow-lg border-none outline-none"
				sx={{
					"& .MuiBackdrop-root": {
						// Targeting the backdrop directly
						outline: "none !important",
					},
					"&:focus-visible": {
						// Trying to target the focus state specifically
						outline: "none !important",
					},
				}}
			>
				<Typography variant="h6" component="h2">
					Something went wrong while trying to transcribe your audio. Here are a few things you can
					try:
				</Typography>
				<Typography className="mt-4">
					1. Check if the microphone is being used by another app or service.
				</Typography>
				<Typography className="mt-4">
					2. If this is an external device, disconnect and connect again.
				</Typography>
				<Typography className="mt-4">3. Disable any active VPN or DNS connections.</Typography>
				<Typography className="mt-4">
					4. Using a company laptop? Disable any security software, firewall, or network config that
					may be blocking the connection.
				</Typography>
				<Box className="flex justify-end mt-3">
					<Button
						className="normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs"
						onClick={() => setShowModal(false)}
					>
						OK
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default Modal403;
