import React, { useEffect, useState } from "react";
import {
	Chip,
	CircularProgress,
	FormControlLabel,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { formatLabel } from "./formatLabel";
import { useSelector } from "react-redux";
import { fetchTransactionsAPI } from "../../utilities/transaction";
import { fetchTransactionsSuperAdminAPI } from "../../utilities/superAdmin/transaction";

const Transactions = ({ organizationId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [showPromo, setShowPromo] = useState(false);
	const { user } = useSelector((state) => state.auth);

	const getOrganizationTransaction = async () => {
		try {
			setIsLoading(true);
			const data = { organizationId };
			if (user?.role === "1096") {
				const res = await fetchTransactionsSuperAdminAPI(data);
				setTransactions(res);
			} else if (user?.role === "1024") {
				const res = await fetchTransactionsAPI();
				setTransactions(res);
			}
		} catch (error) {
			console.error(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getOrganizationTransaction();
	}, [organizationId]);

	const handleSwitchChange = () => {
		setShowPromo((prev) => !prev);
	};

	const renderTableRows = (rows) =>
		rows.length > 0 ? (
			rows.map((row) => (
				<TableRow key={row._id}>
					<TableCell className="text-center" component="th" scope="row">
						{new Date(row.date).toDateString()}
					</TableCell>
					<TableCell className="text-center">{row.details.price}</TableCell>
					{showPromo ? null : (
						<TableCell className="text-center">{row.details.calculatedTax}</TableCell>
					)}
					<TableCell className="text-center">
						<Typography style={{ color: row.transactionType === "debit" ? "red" : "inherit" }}>
							{row.transactionType === "debit" ? row.details.amount : "-"}
						</Typography>
					</TableCell>
					<TableCell className="text-center">
						<Typography style={{ color: row.transactionType === "credit" ? "green" : "inherit" }}>
							{row.transactionType === "credit" ? row.details.amount : "-"}
						</Typography>
					</TableCell>
					{showPromo ? (
						<TableCell className="text-center">
							<Typography
								style={{
									color:
										row.beforeUpdatePromoBalance === 0
											? "inherit"
											: row.beforeUpdatePromoBalance > 0
												? "green"
												: "red",
								}}
							>
								{row.beforeUpdatePromoBalance}
							</Typography>
						</TableCell>
					) : (
						<TableCell className="text-center">
							<Typography
								style={{
									color:
										row.beforeUpdateCurrentBalance === 0
											? "inherit"
											: row.beforeUpdateCurrentBalance > 0
												? "green"
												: "red",
								}}
							>
								{row.beforeUpdateCurrentBalance}
							</Typography>
						</TableCell>
					)}
					{showPromo ? (
						<TableCell className="text-center">
							<Typography
								style={{
									color:
										row.afterUpdatePromoBalance === 0
											? "inherit"
											: row.afterUpdatePromoBalance > 0
												? "green"
												: "red",
								}}
							>
								{row.afterUpdatePromoBalance}
							</Typography>
						</TableCell>
					) : (
						<TableCell className="text-center">
							<Typography
								style={{
									color:
										row.afterUpdateCurrentBalance === 0
											? "inherit"
											: row.afterUpdateCurrentBalance > 0
												? "green"
												: "red",
								}}
							>
								{row.afterUpdateCurrentBalance}
							</Typography>
						</TableCell>
					)}
					<TableCell className="text-center">
						<Chip
							label={formatLabel(row.status)}
							color={
								row.status === "unbilled"
									? "warning"
									: row.status === "completed"
										? "success"
										: "primary"
							}
						/>
					</TableCell>
					<TableCell className="text-center">{row.details.note}</TableCell>
				</TableRow>
			))
		) : (
			<TableRow>
				<TableCell colSpan={9} className="text-center">
					No {showPromo ? "promo " : ""}transactions till now
				</TableCell>
			</TableRow>
		);

	return (
		<div className="w-full mx-auto p-2">
			<div className="flex justify-between items-center">
				<div></div>
				<FormControlLabel
					control={<Switch checked={showPromo} onChange={handleSwitchChange} />}
					label="Promo Transactions"
				/>
			</div>

			{isLoading ? (
				<div className="text-center">
					<CircularProgress />
				</div>
			) : (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow className="bg-gray-100 min-w-full border rounded-lg overflow-hidden text-sm">
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Date
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Base Amount
								</TableCell>
								{showPromo ? null : (
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
										Tax Amount
									</TableCell>
								)}
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Total Debit
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Total Credit
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Before Balance
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									After Balance
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Status
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Notes
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{renderTableRows(
								transactions.filter((transaction) => transaction.isPromo === showPromo),
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default Transactions;
