import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
	return (
		<Box className="flex justify-center items-center min-h-screen">
			<CircularProgress />
		</Box>
	);
};

export default Loading;
