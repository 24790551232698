import axios from "../axiosInstance";

// function to fetch the invoices
async function fetchInvoicesSuperAdminAPI(data) {
	try {
		const response = await axios.post(`/api/superadmin/invoices/`, data);
		if (response.status === 200) {
			return response.data.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while fetching invoices");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function markInvoiceAsPaidSuperAdminAPI(data) {
	try {
		console.log(data);
		const response = await axios.post(`/api/superadmin/invoices/mark-invoice-as-paid`, data);
		if (response.status === 200) {
			return response.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("Something went wrong while paying the invoices");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}
export { fetchInvoicesSuperAdminAPI, markInvoiceAsPaidSuperAdminAPI };
