import { Box } from "@mui/material";

const DifferentDevice = () => {
	return (
		<Box className="bg-gray-200">
			<Box
				id="panel1"
				className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
			>
				<h2 className="text-center text-xl font-semibold mb-6">
					To ensure a smooth continuation of your interview process, kindly use the same
					device/browser you initially used to start the interview. This helps in maintaining the
					integrity and flow of your interview experience. If you cannot use your earlier device for
					any reason, please contact HR.
				</h2>
			</Box>
		</Box>
	);
};

export default DifferentDevice;
