import React, { useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, Avatar, Tooltip, Popover, Icon, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import zinterviewImageUrl from "../../assets/zi-favicon.png";
import { getFormattedDateTime2 } from "../../utilities/utilityFunctions";
import { removeNotification, removeAllNotification } from "../../features/notifications/NotificationsSlice";
import { Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { renderConfirmationDialog } from "../../components/confirmation/ConfirmationDialog";
import { InterviewStatus } from "../../utilities/realTimeNotifications";

const NotificationInfoPage = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const { notifications } = useSelector((state) => state.notifications);
	const notificationCount = notifications?.length || 0;

	const handleIconClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "notification-popover" : undefined;

	return (
		<div className="relative mx-5 text-gray-800">
			<Tooltip title="Notifications">
				<div
					className="bg-gray-400 hover:bg-gray-500 rounded-2xl p-1 cursor-pointer"
					onClick={handleIconClick}
				>
					<Badge badgeContent={notificationCount} color="error">
						<NotificationsNoneIcon className="text-white" />
					</Badge>
				</div>
			</Tooltip>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<NotificationList notifications={notifications} handleClose={handleClose} />
			</Popover>
		</div>
	);
};

const NotificationList = ({ notifications, handleClose }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const deleteNotification = (interviewReportId, status) => {
		dispatch(removeNotification({ interviewReportId, status }));
	};

	const deleteAllNotifications = () => {
		renderConfirmationDialog(
			"Are you sure you want to delete all notifications?",
			() => {},
			() => {
				dispatch(removeAllNotification());
				handleClose();
			},
			true,
		);
	};

	return (
		<div className="w-80 md:w-96 bg-white shadow-lg rounded-lg">
			<div className="p-4 bg-blue-200 flex justify-between items-center">
				<h3 className="font-semibold text-lg text-gray-800">
					Notifications
					{notifications && !!notifications?.length && (
						<Tooltip title="Remove all notifications" arrow>
							<button
								className="text-red-600 mx-5 p-1 mt-1 rounded-md cursor-pointer border-none"
								onClick={() => deleteAllNotifications()}
								style={{ fontSize: "10px" }}
							>
								Delete all
							</button>
						</Tooltip>
					)}
				</h3>
				<Tooltip title="Close">
					<IconButton>
						<CloseIcon fontSize="small" onClick={handleClose} />
					</IconButton>
				</Tooltip>
			</div>
			<div className="max-h-[65vh] overflow-y-auto">
				{notifications.length > 0 ? (
					notifications.map((report, index) => (
						<div
							key={index}
							className="shadow-sm flex p-4 border-b items-center hover:bg-gray-100 relative"
						>
							<Avatar alt="ZInterview Logo" src={zinterviewImageUrl} className="mr-3 h-8 w-8" />
							<div
								className="flex-1 cursor-pointer"
								onClick={() => {
									navigate(`${report.destinationUrl}`);
									handleClose();
									deleteNotification(report.interviewReportId, report?.status);
								}}
							>
								<p className="text-sm text-gray-400">
									{report?.time ? getFormattedDateTime2(report?.time) : "-"}
								</p>
								<p className="font-bold text-gray-800">
									{InterviewStatus[report?.status] || "-"}
								</p>
								<p className="text-gray-600 text-sm">{report?.message || "-"}</p>
							</div>
							<Tooltip title="Remove">
								<IconButton
									className="absolute right-4 top-3 text-red-500 hover:text-red-600 border-none cursor-pointer"
									onClick={() =>
										deleteNotification(report.interviewReportId, report?.status)
									}
								>
									<Delete className="text-base" />
								</IconButton>
							</Tooltip>
						</div>
					))
				) : (
					<p className="p-4 text-gray-600 text-center">No new notifications!</p>
				)}
			</div>
		</div>
	);
};

export default NotificationInfoPage;
