import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../features/auth/authSlice";
import logo from "../assets/zi-favicon.png";

const ResetPassword = () => {
	const { token } = useParams();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isEmailSent, setIsEmailSent] = useState(false);
	const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
	const [isPwdVisible1, setIsPwdVisible1] = useState(false);
	const [isPwdVisible2, setIsPwdVisible2] = useState(false);
	const [tokenInvalid, setTokenInvalid] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);

	useEffect(() => {
		if (token) {
			isTokenValid(token);
			return;
		}
	}, [token]);

	useEffect(() => {
		if (user) {
			dispatch(logoutUser());
		}
	}, [user]);

	const isValidEmail = (email) => {
		if (email.includes(" ")) {
			toast.warn("Email cannot contain spaces.");
			return false;
		}
		// Regex Explanation:
		// ^ asserts position at start of the string
		// [^@]+ matches one or more characters that are not '@'
		// @ matches the '@' character
		// [^@]+ matches one or more characters that are not '@' ensuring no additional '@' is in the domain
		// \. matches the '.' character
		// [^@.]+ matches one or more characters that are not '@' or '.', ensuring no additional '.' or '@' after the domain
		// $ asserts position at the end of the string
		// The part before the '@' symbol does not contain '@'.
		// The part between the '@' and the '.' does not contain another '@' or '.'.
		// The part after the '.' does not contain another '@' or '.'.
		const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
		if (!emailRegex.test(email)) {
			toast.warn("Invalid Email");
			return false;
		}
		return true;
	};

	const isTokenValid = async (token) => {
		setIsLoading(true);
		try {
			const decodedString = atob(token);
			const [email, randomToken] = decodedString.split(":");
			const response = await axios.post("/api/user/tokenValidation", {
				email,
				token,
			});
			if (response.data.exists) {
				if (!response.data.valid) {
					toast.error("Link expired. Please get a new password reset link.");
					setTokenInvalid(true);
				}
			} else {
				toast.error("Email no longer exists. Please use a valid email.");
				setTokenInvalid(true);
			}
			setIsLoading(false);
		} catch (error) {
			if (
				error.message ===
				"Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded."
			) {
				toast.error("Invalid characters in the link. Please try again.");
				console.log(`Error: ${error}`);
			} else {
				toast.error("Please try again later.");
				console.log(`Error: ${error}`);
			}
			setTokenInvalid(true);
			setIsLoading(false);
		}
	};

	const isValidPassword = (password) => {
		const minLength = 6;
		if (password.length < minLength) {
			toast.warn(`Password must be at least ${minLength} characters long.`);
			return false;
		}
		if (password.includes(" ")) {
			toast.warn("Password cannot contain spaces.");
			return false;
		}
		return true;
	};

	const sendResetLink = async () => {
		setIsLoading(true);
		try {
			const baseUrl = `${window.location.protocol}//${window.location.host}`;
			const response = await axios.post("/api/user/sendresetlink", {
				email,
				baseUrl,
			});
			if (response.data.exists) {
				if (response.data.sent) {
					toast.success("Reset link sent to your email. Please check the spam folder as well.");
					setIsEmailSent(true);
				} else {
					toast.error("Failed to send reset link. Please try again.");
				}
				setIsLoading(false);
			} else {
				toast.error("Email is not registered. Please use a valid email.");
				setIsLoading(false);
			}
		} catch (error) {
			toast.error("Please try again later.");
			console.log(`Error: ${error}`);
			setIsLoading(false);
		}
	};

	const updatePassword = async (token, password) => {
		setIsLoading(true);
		try {
			const response = await axios.post("/api/user/updatepassword", {
				token,
				password,
			});
			if (response.data.exists) {
				if (response.data.valid) {
					if (response.data.updated) {
						toast.success("Your password has been updated.");
						setIsPasswordUpdated(true);
					} else {
						toast.error("Failed to update password. Please try again.");
					}
					setIsLoading(false);
				} else {
					toast.error("Link expired. Please get a new password reset link.");
					setIsLoading(false);
				}
			} else {
				toast.error("Email no longer exists. Please use a valid email.");
			}
		} catch (error) {
			toast.error("Please try again later.");
			console.log(`Error: ${error}`);
			setIsLoading(false);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (token) {
			if (!isValidPassword(password) || !isValidPassword(confirmPassword)) {
				return;
			}
			if (password.trim() !== confirmPassword.trim()) {
				toast.warn("Passwords do not match. Please try again.");
				return;
			} else {
				updatePassword(token, password);
				return;
			}
		} else {
			if (email) {
				if (isValidEmail(email.trim())) {
					sendResetLink();
					return;
				}
			} else {
				toast.warn("Email cannot be empty");
				return;
			}
		}
	};

	return (
		<Box className="bg-gray-100 h-screen flex flex-col justify-center items-center">
			<Box className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3">
				<Box class="flex justify-center mb-6">
					<img src={logo} alt="Zinterview Logo" class="h-16" />
				</Box>
				{!token ? (
					isEmailSent ? (
						<>
							<Typography variant="h6" gutterBottom>
								Check your email for the password reset link. If you do not see the email,
								please check the spam folder as well.
							</Typography>
							<Box
								onClick={() => navigate("/login")}
								className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2 normal-case ${
									isLoading && "cursor-not-allowed opacity-50"
								}`}
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={isLoading}
								component={Button}
							>
								<span>Return to Login</span>
							</Box>
						</>
					) : (
						<>
							<Typography variant="h5" gutterBottom>
								Reset Password
							</Typography>
							<form>
								<Box className="mb-4">
									<label
										htmlFor="email"
										className="block text-sm font-medium text-gray-600"
									>
										Email
									</label>
									<TextField
										sx={{
											"& fieldset": {
												border: "1px solid #F2F3F5",
											},
										}}
										disabled={isLoading}
										type="email"
										fullWidth
										variant="outlined"
										margin="normal"
										required
										value={email}
										color="black"
										autoComplete="email"
										size="small"
										id="email"
										name="email"
										className="mt-1 w-full border rounded-md"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Box>
								<Box
									onClick={handleSubmit}
									className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2 normal-case ${
										isLoading && "cursor-not-allowed opacity-50"
									}`}
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									disabled={isLoading}
									component={Button}
								>
									{isLoading ? (
										<span>
											{"Sending Reset Link... "}
											<i className="fas fa-circle-notch fa-spin mr-1"></i>
										</span>
									) : (
										<span>Reset Password</span>
									)}
								</Box>
							</form>
						</>
					)
				) : isPasswordUpdated ? (
					<>
						<Typography variant="h6" gutterBottom>
							Password updated successfully.
						</Typography>
						<Box
							onClick={() => navigate("/login")}
							className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2 normal-case ${
								isLoading && "cursor-not-allowed opacity-50"
							}`}
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={isLoading}
							component={Button}
						>
							<span>Login</span>
						</Box>
					</>
				) : tokenInvalid ? (
					<>
						<Typography variant="h6" gutterBottom>
							Password reset link is invalid. Please get a new password reset link.
						</Typography>
						<Box
							onClick={() => navigate("/login")}
							className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2 normal-case ${
								isLoading && "cursor-not-allowed opacity-50"
							}`}
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={isLoading}
							component={Button}
						>
							<span>Login</span>
						</Box>
					</>
				) : (
					<>
						<Typography variant="h5" gutterBottom>
							Set a New Password
						</Typography>
						<form>
							<Box className="relative mb-4">
								<label htmlFor="password" className="block text-sm font-medium text-gray-600">
									New Password
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									disabled={isLoading}
									type={isPwdVisible1 ? "text" : "password"}
									fullWidth
									variant="outlined"
									margin="normal"
									required
									color="black"
									autoComplete="new-password"
									size="small"
									id="password"
									name="password"
									className="mt-1 w-full border rounded-md"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<Box
									className="absolute cursor-pointer top-[60%] right-2 transform -translate-y-1/2"
									onClick={() => setIsPwdVisible1((prev) => !prev)}
								>
									{isPwdVisible1 ? (
										<i class="fas fa-eye-slash"></i>
									) : (
										<i class="fas fa-eye"></i>
									)}
								</Box>
							</Box>
							<Box className="relative mb-4">
								<label
									htmlFor="confirmPassword"
									className="block text-sm font-medium text-gray-600"
								>
									Confirm Password
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									disabled={isLoading}
									type={isPwdVisible2 ? "text" : "password"}
									fullWidth
									variant="outlined"
									margin="normal"
									required
									color="black"
									autoComplete="confirm-password"
									size="small"
									id="confirmPassword"
									name="confirmPassword"
									className="mt-1 w-full border rounded-md"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
								/>
								<Box
									className="absolute cursor-pointer top-[60%] right-2 transform -translate-y-1/2"
									onClick={() => setIsPwdVisible2((prev) => !prev)}
								>
									{isPwdVisible2 ? (
										<i class="fas fa-eye-slash"></i>
									) : (
										<i class="fas fa-eye"></i>
									)}
								</Box>
							</Box>
							<Box
								onClick={handleSubmit}
								className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2 normal-case ${
									isLoading && "cursor-not-allowed opacity-50"
								}`}
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={isLoading}
								component={Button}
							>
								{isLoading ? (
									<span>
										{"Updating Password... "}
										<i className="fas fa-circle-notch fa-spin mr-1"></i>
									</span>
								) : (
									<span>Update Password</span>
								)}
							</Box>
						</form>
					</>
				)}
			</Box>
		</Box>
	);
};

export default ResetPassword;
