import React, { useState } from "react";
import MultiLineTextArea from "../components/MultiLineTextArea";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ErrorMessage } from "./CreateUpdateOpening/CreateUpdateOpeningWithJD";
import Button from "./CreateUpdateOpening/Button";
import clsx from "clsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { getMarkdownFromUserInput } from "../utilities/zinterviewgptApi";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch } from "react-redux";
import { checkIfInvalidOrUnauthorized } from "../utilities/utilityFunctions";
import { logoutUser } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import { dummySqlTable } from "../utilities/constants";
import { CircularProgressWithLabel } from "../components/OpeningType";

const userInputSchema = z.object({
	userText: z.string().trim().min(1),
});

const SUBMIT = "Submit";
const REGENERATE = "Regenerate";

const BUTTON_TYPES = {
	SUBMIT,
	REGENERATE,
};

const CreateMarkdownWithGPT = () => {
	const dispatch = useDispatch();
	const [markdown, setMarkdown] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [buttonType, setButtonType] = useState(BUTTON_TYPES.SUBMIT);
	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({
		defaultValues: {
			userText: "",
		},
		resolver: zodResolver(userInputSchema),
	});

	const handleFormSubmit = async (data) => {
		try {
			const text = data.userText;
			setIsLoading(true);
			const payload = {
				text,
				type: buttonType,
			};
			const response = await getMarkdownFromUserInput(payload);
			setMarkdown(response);
			setButtonType(BUTTON_TYPES.REGENERATE);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	};

	const handleClearTextArea = () => {
		setValue("userText", "");
		setButtonType(BUTTON_TYPES.SUBMIT);
	};

	const handleShowExample = () => {
		setValue("userText", dummySqlTable ?? "");
	};

	return (
		<div
			style={{
				minHeight: "calc(100vh - var(--navbar-height,88px))",
			}}
			className="bg-gray-50"
		>
			<div className="p-6 w-[95%] mx-auto max-w-[900px]">
				<form noValidate onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col gap-3">
					<CustomStack className="flex-col gap-0">
						<MultiLineTextArea
							registeredName={"userText"}
							id={"user-text-for-markdown"}
							placeholder={"Enter sql tables and your questions to get its markdown variant"}
							control={control}
							minRows={10}
							maxRows={16}
							className=" p-4"
							darkerBorder={true}
							disabled={isLoading}
						/>
						<ErrorMessage errorCriteria={errors?.userText?.message} />
					</CustomStack>
					<CustomStack className="gap-3">
						{isLoading ? (
							<div className="flex flex-row items-center justify-start ">
								<CircularProgressWithLabel />
							</div>
						) : (
							<>
								<Button
									text="Clear"
									type="reset"
									handleClick={handleClearTextArea}
									disabled={isLoading}
									className="bg-gray-400 hover:bg-gray-500 focus:bg-gray-500"
								/>
								<Button
									text="Show Example"
									handleClick={handleShowExample}
									disabled={isLoading}
									className="bg-gray-400 hover:bg-gray-500 focus:bg-gray-500"
								/>
								<Button
									text={buttonType}
									type="submit"
									handleClick={handleSubmit(handleFormSubmit)}
									disabled={isLoading}
								/>
							</>
						)}
					</CustomStack>
					<CustomStack className="flex-col gap-2 ">
						{markdown && <MarkdownOutputContainer markdown={markdown} />}
					</CustomStack>
				</form>
			</div>
		</div>
	);
};

export default CreateMarkdownWithGPT;

const CustomStack = ({ children, className = "" }) => {
	return <div className={clsx("flex flex-row gap-1", className)}>{children}</div>;
};

const MarkdownOutputContainer = ({ markdown }) => {
	const [copied, setCopied] = useState(false);
	async function handleCopyToClipboard() {
		try {
			await navigator.clipboard.writeText(markdown);
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<>
			<div className="whitespace-pre-line border-[1px] border-solid border-gray-300 p-4 rounded-md">
				{markdown}
			</div>
			<CustomStack>
				<IconButton className="flex justify-center items-center" onClick={handleCopyToClipboard}>
					<Tooltip open={copied} arrow title={<>{copied ? "Copied!" : "Copy"}</>}>
						<ContentCopyIcon className="color-slate-200" />
					</Tooltip>
				</IconButton>
			</CustomStack>
		</>
	);
};
