import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "./countries";

export const CountrySelector = ({ value, onChange, disabled }) => {
	return (
		<Autocomplete
			id="country-selector"
			options={countries}
			autoHighlight
			value={value}
			onChange={(e, newValue) => {
				onChange(newValue);
			}}
			disabled={disabled}
			getOptionLabel={(option) => option.label}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;
				return (
					<Box
						key={key}
						component="li"
						sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
						{...optionProps}
					>
						<img
							loading="lazy"
							width="20"
							srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
							src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
							alt=""
						/>
						{option.label} ({option.code}) +{option.phone}
					</Box>
				);
			}}
			renderInput={(params) => (
				<>
					<label htmlFor="country" className="block text-sm font-medium text-gray-600 mb-2">
						Country
					</label>
					<TextField
						sx={{
							"& fieldset": {
								border: "1px solid #F2F3F5",
							},
						}}
						color="black"
						size="small"
						className="border-gray-200 rounded w-full"
						{...params}
					/>
				</>
			)}
		/>
	);
};
