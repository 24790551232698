import { getScore, getTrustScore, getValuesForCustomFields, interviewStatusComparator, nameColumnComparator } from "../grid/columnRenderer/filters/valueGetters";

export const getFilteredRecords = (gridFilters, allCandidate = [], candidatesInGrid = [], openingData = {}) => {
    let records = allCandidate.filter(candidate => candidatesInGrid.includes(candidate._id));

    if (!gridFilters || gridFilters.length === 0) {
        return records;
    }
    let types = {
        createdAt: "date",
        firstName: "text",
        score: "number",
        trustScore: "number",
        interviewStatus: "text",
        custom: "text",
    }

    let filteredRecords = records.filter(record => {
        let isRecordValid = true;
        gridFilters.forEach(filter => {
            let value = ""

            if (record.hasOwnProperty(filter.field)) {
                value = record[filter.field];
            } else if (record.hasOwnProperty("customFieldsData")) {
                value = getValuesForCustomFields(record, filter.field, null, openingData);
            }

            let type = types[filter.field] || "text";

            switch (filter.field) {
                case "firstName":
                    isRecordValid = nameColumnComparator(record, filter, filter.operator);
                    return;
                case "score":
                    value = getScore(record);
                    break;
                case "trustScore":
                    value = getTrustScore(record);
                    break;
                case "interviewStatus":
                    isRecordValid = interviewStatusComparator(record, filter.value, openingData);
                    return;
                default:
                    break;
            }

            if (type === "number") {
                let lhs = Number(value);
                let rhs = Number(filter.value);
                switch (filter.operator) {
                    case "gt":
                        isRecordValid = isRecordValid && lhs > rhs;
                        break;
                    case "lt":
                        isRecordValid = isRecordValid && lhs < rhs;
                        break;
                    case "lte":
                        isRecordValid = isRecordValid && lhs <= rhs;
                        break;
                    case "gte":
                        isRecordValid = isRecordValid && lhs >= rhs;
                        break;
                    case "eq":
                        isRecordValid = isRecordValid && lhs === rhs;
                        break;
                    case "ne":
                        isRecordValid = isRecordValid && lhs !== rhs;
                        break;
                    default:
                        break;
                }
            } else if (type === "text") {
                switch (filter.operator) {
                    case "equals":
                        isRecordValid = isRecordValid && value == filter.value;
                        break;
                    case "contains":
                        isRecordValid = isRecordValid && value.includes(filter.value);
                        break;
                    case "notcontains":
                        isRecordValid = isRecordValid && !value.includes(filter.value);
                        break;
                    case "startswith":
                        isRecordValid = isRecordValid && value.startsWith(filter.value);
                        break;
                    case "endswith":
                        isRecordValid = isRecordValid && value.endsWith(filter.value);
                        break;
                    case "empty":
                        isRecordValid = isRecordValid && value === "";
                        break;
                    case "notEmpty":
                        isRecordValid = isRecordValid && value !== "";
                        break;
                    case "is":
                        isRecordValid = isRecordValid && value === filter.value;
                        break;
                    default:
                        break;
                }
            } else if (type === "date") {
                let rhs = filter.value;
                try {
                    value = new Date(value);
                    rhs = new Date(rhs);

                    value.setHours(0, 0, 0, 0);
                    rhs.setHours(0, 0, 0, 0);
                } catch (error) {
                    console.log(error);
                }

                switch (filter.operator) {
                    case "today":
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        isRecordValid = isRecordValid && value.getTime() === today.getTime();
                        break;
                    case "tomorrow":
                        let tomorrow = new Date();
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        tomorrow.setHours(0, 0, 0, 0);
                        isRecordValid = isRecordValid && value.getTime() === tomorrow.getTime();
                        break;
                    case "before":
                        isRecordValid = isRecordValid && value.getTime() < rhs.getTime();
                        break;
                    case "after":
                        isRecordValid = isRecordValid && value.getTime() > rhs.getTime();
                        break;
                    case "equals":
                        isRecordValid = isRecordValid && value.getTime() === rhs.getTime();
                        break;
                    case "notEquals":
                        isRecordValid = isRecordValid && value.getTime() !== rhs.getTime();
                        break;
                    default:
                        break;
                }
            }
        });
        return isRecordValid;

    });
    return filteredRecords;
}