import React from "react";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import clsx from "clsx";
import { toast } from "react-toastify";

function JoinTimeOptions({ joinOption, setJoinOption, timeWindow, setTimeWindow }) {
	const sanitizeTimeWindow = (timeWindow) => {
		const validTimeWindow = /^[0-9.]+$/;
		return timeWindow === "0.0" || !validTimeWindow.test(timeWindow) ? "0" : timeWindow;
	};

	const handleTimeWindowChange = (e) => {
		let value = sanitizeTimeWindow(e.target.value).replace(/[^0-9.]/g, "");

		// Remove leading zeros unless it's "0."
		if (!value.startsWith("0.") && value !== "0") {
			value = value.replace(/^0+/, "");
		}

		// Handle multiple decimal points and limit to one decimal place
		const [integerPart, decimalPart] = value.split(".");
		value = integerPart + (decimalPart ? "." + decimalPart.slice(0, 1) : "");

		// Validate and set value if it's within the range (up to 168 hours)
		if (/^\d*\.?\d?$/.test(value) && (parseFloat(value) || 0) <= 168) {
			setTimeWindow(value);
		} else if ((parseFloat(value) || 0) > 168) {
			toast.warn("The maximum allowed value is 168 hours (equivalent to 7 days).");
		}
	};

	return (
		<>
			<label className="block mb-2">Select Join Options:</label>
			<Tooltip
				arrow
				title="The candidate can join at the exact scheduled time and may join up to 10 minutes after the scheduled time."
			>
				<div
					tabIndex={0}
					className={clsx(
						"chip",
						joinOption === "onTime" ? "bg-blue-500 hover:bg-blue-700 text-white" : "",
					)}
					onClick={() => setJoinOption("onTime")}
				>
					Candidate can join at the scheduled time
				</div>
			</Tooltip>

			<Tooltip arrow title="Candidate can join any time after the scheduled time.">
				<div
					tabIndex={0}
					className={clsx(
						"chip",
						joinOption === "anytime" ? "bg-blue-500 hover:bg-blue-700 text-white" : "",
					)}
					onClick={() => setJoinOption("anytime")}
				>
					Candidate can join anytime
				</div>
			</Tooltip>

			<Tooltip
				arrow
				title="Specify a time window during which the candidate can join after the scheduled time."
			>
				<div
					tabIndex={0}
					className={clsx(
						"chip",
						joinOption === "timeWindow" ? "bg-blue-500 hover:bg-blue-700 text-white" : "",
					)}
					onClick={() => setJoinOption("timeWindow")}
				>
					Specify time window
				</div>
			</Tooltip>

			{joinOption === "timeWindow" && (
				<TextField
					autoFocus
					label="Allow joining within (hours after scheduled time)"
					type="number"
					inputProps={{ step: "0.1" }}
					size="small"
					value={timeWindow}
					onChange={(e) => handleTimeWindowChange(e)}
					className="w-3/4 mt-3"
				/>
			)}
		</>
	);
}

export default JoinTimeOptions;
