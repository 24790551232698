import { Info, Remove } from "@mui/icons-material";
import { Button, IconButton, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { CRITERIA_ROW_FORMAT, OPERATORS } from "../constants.js";
import { InputComponent } from "./APIFormRenderer.jsx";

export const CriteriaComponent = ({
	action,
	fields,
	onCriteriaSave = () => null,
	buttonLabel = "Criteria",
	buttonVariant = "outlined",
	buttonColor = "primary",
	buttonClasses = "",
	infoText = "This action will only run if these conditions are satisfied.",
	buttonIcon = null,
}) => {
	const [openCriteriaModal, setOpenCriteriaModal] = useState(false);

	return (
		<div>
			{openCriteriaModal && (
				<CriteriaModal
					open={openCriteriaModal}
					onClose={() => setOpenCriteriaModal(false)}
					actionCriteria={JSON.parse(JSON.stringify(action?.criteria || []))}
					fields={fields}
					onCriteriaSave={onCriteriaSave}
					infoText={infoText}
				/>
			)}
			<Button
				variant={buttonVariant}
				color={buttonColor}
				className={buttonClasses}
				onClick={(e) => {
					setOpenCriteriaModal(true);
				}}
				startIcon={buttonIcon}
			>
				{buttonLabel}{" "}
				{Array.isArray(action.criteria) && action.criteria.length > 0 ? (
					<span className="text-rose-600">
						<sup> *</sup>
					</span>
				) : (
					""
				)}
			</Button>
		</div>
	);
};

const CriteriaModal = ({ open, onClose, actionCriteria = [], onCriteriaSave, fields = [], infoText }) => {
	const [criteria, setCriteria] = useState(Array.isArray(actionCriteria) ? [...actionCriteria] : []);

	if (!open) {
		return null;
	}

	function onClickRemove(index) {
		let newCriteria = [...criteria];
		newCriteria.splice(index, 1);
		if (newCriteria.length > 0 && newCriteria[newCriteria.length - 1]?.type === "condition") {
			newCriteria.pop();
		}
		if (index === 0 && newCriteria.length > 0 && newCriteria[0]?.type === "condition") {
			newCriteria.shift();
		}
		if (index !== 0 && newCriteria[index - 1]?.type === "condition") {
			newCriteria.splice(index - 1, 1);
		}
		setCriteria(newCriteria);
	}

	function onClickSave() {
		let isValid = true;
		criteria.forEach((el) => {
			if (el.type !== "condition" && (el.operator === "" || el.column === "")) {
				isValid = false;
				return;
			}
			if (el.type === "condition" && el.operator === "") {
				isValid = false;
				return;
			}
		});

		if (!isValid) {
			toast.error("Please fill all details in a criteria rows");
			return;
		}

		// toast.warn("Don't forget to save/update action after saving criteria.");
		onCriteriaSave(criteria);
		onClose();
	}

	const onClickAddCriteriaRow = () => {
		if (criteria.length > 0) {
			setCriteria([
				...criteria,
				{ type: "condition", operator: "OR", value: "" },
				{ ...CRITERIA_ROW_FORMAT },
			]);
		} else {
			setCriteria([...criteria, { ...CRITERIA_ROW_FORMAT }]);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<div
				className="bg-white p-2 rounded-lg shadow-lg flex"
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "60vw",
					bgcolor: "background.paper",
					boxShadow: 24,
				}}
			>
				<div className="relative flex w-full flex-col justify-between">
					<div className="flex justify-between w-full pl-3 pt-1 mb-3">
						<Typography variant="h6" className="text-center">
							Criteria
						</Typography>
						<Button className="hover:scale-105 transition-all" onClick={onClickAddCriteriaRow}>
							Add Criteria Row
						</Button>
					</div>
					<div
						style={{
							maxHeight: "60vh",
							overflow: "auto",
						}}
						className="flex flex-col"
					>
						{criteria && criteria.length > 0 ? (
							criteria.map((el, index) => {
								let selectedField = fields.find((field) => field.value === el.column);
								let variant = selectedField?.variant || "text";
								let operatorType = selectedField?.operatorType || "";
								let operator = el.operator;
								return (
									<div
										key={index}
										className="flex gap-2 w-full my-0.5 align-middle justify-center"
									>
										{el.type === "condition" ? (
											el.operator === "AND" ? (
												<Button
													onClick={() => {
														let newCriteria = [...criteria];
														newCriteria[index].operator = "OR";
														setCriteria(newCriteria);
													}}
													className="m-0"
												>
													AND
												</Button>
											) : (
												<Button
													onClick={() => {
														let newCriteria = [...criteria];
														newCriteria[index].operator = "AND";
														setCriteria(newCriteria);
													}}
													className="m-0"
												>
													OR
												</Button>
											)
										) : (
											<div className="flex gap-2 px-4 items-stretch justify-center align-middle w-full">
												<InputComponent
													value={el.column}
													fields={fields.filter(
														(el) =>
															el.type === "report" &&
															el.enabledFor.includes("criteria") &&
															!el.disabled,
													)}
													onChange={(e) => {
														let newCriteria = [...criteria];
														newCriteria[index].column = e.target.value;
														setCriteria(newCriteria);
													}}
													type={"Column"}
													classes="flex-1"
													placeholder="Select Column"
													label=""
												/>
												<InputComponent
													value={el.operator}
													fields={OPERATORS.filter(
														(el) => !el.disabled && el.for.includes(operatorType),
													)}
													onChange={(e) => {
														let newCriteria = [...criteria];
														newCriteria[index].operator = e.target.value;
														setCriteria(newCriteria);
													}}
													type={"Column"}
													classes="flex-1"
													placeholder="Select Operator"
													label=""
												/>
												<InputComponent
													value={el.value}
													fields={
														fields.find((field) => field.value === el.column)
															?.options || []
													}
													onChange={(e) => {
														let newCriteria = [...criteria];
														newCriteria[index].value = e.target.value;
														setCriteria(newCriteria);
													}}
													type={
														OPERATORS.find(
															(el) => el.value == criteria[index].operator,
														)?.rhsRequired
															? variant
															: ""
													}
													classes="flex-1"
													placeholder="Select Value"
													label=""
												/>
												<IconButton
													onClick={() => {
														onClickRemove(index);
													}}
													className="text-rose-500 hover:text-rose-700"
												>
													<Remove />
												</IconButton>
											</div>
										)}
									</div>
								);
							})
						) : (
							<div className="h-11 w-full flex justify-center items-center">
								<p
									className="text-gray-500 hover:scale-105 hover:text-blue-500 transition-all cursor-pointer"
									onClick={onClickAddCriteriaRow}
								>
									Click to add new criteria row.
								</p>
							</div>
						)}
					</div>
					<div className="flex gap-2 justify-end items-end mt-4">
						<div className="text-gray-500 pl-3 text-sm flex items-center">
							<Info className="scale-75" />
							{infoText}{" "}
						</div>
						<div className="flex-1" />
						<Button variant="outlined" className="shadow-none" onClick={onClose} color="error">
							Cancel
						</Button>
						<Button
							className="shadow-none"
							variant="outlined"
							onClick={() => {
								onClickSave();
							}}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default CriteriaModal;
