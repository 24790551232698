import React, { useRef, useState, useEffect } from "react";
import { Button, Box, Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { handleIdentityVerification } from "../../utilities/interviewReportsApi";
import { clientLogger } from "../../utilities/loggingApi";
import { DeviceSelector } from "../../components/Interview/DeviceSelector";

const IdentityVerification = ({
	userinterviewreport_id,
	isCreatedByAdmin,
	handleAfterIdentityVerification,
	videoDevices,
	selectedVideoDevice,
	handleVideoDeviceChange,
	videoStream,
}) => {
	const [idProofSrc, setIdProofSrc] = useState(null);
	const [candidatePhotoSrc, setCandidatePhotoSrc] = useState(null);
	const candidatePhotoRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackbarOpen, setSnackBarOpen] = useState(false);
	const [filePreview, setFilePreview] = useState(null);
	const isSkippable = localStorage.getItem("skip-id-verification") === "true";

	useEffect(() => {
		if (candidatePhotoRef.current) {
			candidatePhotoRef.current.srcObject = videoStream;
		}
	}, [candidatePhotoSrc, videoStream]);

	const capturePhoto = (setSrc, ref) => {
		const video = ref.current;
		const canvas = document.createElement("canvas");
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		const ctx = canvas.getContext("2d");
		ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
		setSrc(canvas.toDataURL("image/png"));
	};

	const deleteCapturePhoto = (setSrc) => {
		setSrc(null);
	};

	const dataURLtoFile = (dataurl, filename) => {
		const arr = dataurl.split(",");
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	};

	const verifyPhoto = async () => {
		try {
			if (loading) return;

			if (!idProofSrc || !candidatePhotoSrc) {
				setSnackBarOpen(true);
				if (!idProofSrc && !candidatePhotoSrc) {
					setSnackBarMessage("Both ID proof and your photo are required.");
				} else if (!idProofSrc) {
					setSnackBarMessage("ID proof photo is required.");
				} else if (!candidatePhotoSrc) {
					setSnackBarMessage("Your photo is required.");
				}
				return;
			}
			if (!userinterviewreport_id) {
				setSnackBarOpen(true);
				setSnackBarMessage("Error verifying photos. Please try again.");
				return;
			}
			clientLogger("The candidate clicks on the verify identity button.");
			setLoading(true);
			const candidatePhotoFile = dataURLtoFile(candidatePhotoSrc, "candidatePhoto.png");
			const formData = new FormData();
			formData.append("idProof", idProofSrc);
			formData.append("candidatePhoto", candidatePhotoFile);
			formData.append("userinterviewreport_id", userinterviewreport_id);

			const response = await handleIdentityVerification(formData);
			if (response.status === 200) {
				handleAfterIdentityVerification(response?.data?.message);
				clientLogger(response?.data?.message);
			} else {
				setSnackBarOpen(true);
				setSnackBarMessage(response?.data?.message);
				clientLogger(response?.data?.message);
			}
		} catch (error) {
			const result = error?.message ? error?.message : "Error verifying photos. Please try again.";
			clientLogger(result);
			setSnackBarMessage(result);
			setSnackBarOpen(true);
		} finally {
			setLoading(false);
		}
	};

	const handleSnackBarClose = () => {
		setSnackBarOpen(false);
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setIdProofSrc(file);
			setFilePreview(URL.createObjectURL(file));
		}
		event.target.value = "";
	};

	const triggerFileInput = () => {
		document.getElementById("uploadPhotoId").click();
	};

	const deleteFileInput = () => {
		setFilePreview(null);
		setIdProofSrc(null);
		triggerFileInput();
	};

	const onSkip = () => {
		handleAfterIdentityVerification("You skipped the ID verification step");
	};

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<div className="flex flex-col items-center justify-center bg-gray-200 p-4">
			<Box className="flex justify-center space-x-4 mt-2">
				{isCreatedByAdmin && <Box id="step1Bar" className="bg-gray-300 rounded h-2 w-24"></Box>}
				<Box id="step2Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
				<Box id="step3Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
				<Box id="step4Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
				<Box id="step5Bar" className="bg-green-500 rounded h-2 w-24"></Box>
			</Box>

			<div className="flex flex-col justify-center items-center mt-10">
				<div className="bg-white p-7 rounded shadow-md w-full max-w-4xl">
					<h2 className="text-center text-lg font-semibold mb-2">Identity Verification</h2>
					<p className="text-center text-gray-500 mb-1">
						Please upload your Photo ID and capture a live image for verification.
					</p>
					<p className="text-center text-gray-500 mb-4">
						This helps us ensure the authenticity and security of your application process.
					</p>
					<div className="flex justify-end">
						<h2 className="text-center text-lg font-semibold mb-5">
							<DeviceSelector
								deviceList={videoDevices}
								onDeviceChange={handleVideoDeviceChange}
								selectedDevice={selectedVideoDevice}
								type={"Camera"}
							/>
						</h2>
					</div>

					<div className="flex flex-row flex-wrap space-x-6">
						<div
							className="flex-1 flex flex-col items-center"
							style={{ border: "1px solid gray", borderRadius: "5px" }}
						>
							<div className="border border-gray-300 rounded-lg overflow-hidden h-80 w-full flex items-center justify-center relative p-4">
								{filePreview ? (
									<img
										src={filePreview}
										alt="ID Preview"
										className="object-contain w-full h-full"
									/>
								) : (
									<span className="text-gray-500 text-center">
										Please upload a clear photo of your government-issued ID. Make sure
										all details are visible and the photo is not blurry or cropped.
									</span>
								)}
								<input
									type="file"
									id="uploadPhotoId"
									accept="image/png, image/jpeg"
									className="absolute inset-0 w-full h-full opacity-0 cursor-pointer hidden"
									onChange={handleFileChange}
								/>
								{filePreview ? (
									<button
										className="absolute bottom-1 border border-red-500 text-red-500 text-sm px-4 py-2 rounded cursor-pointer hover:bg-red-500 hover:text-white"
										onClick={deleteFileInput}
										disabled={loading}
									>
										Re-Upload
									</button>
								) : (
									<button
										className="absolute bottom-1 border border-blue-500 text-blue-500 text-sm px-4 py-2 rounded cursor-pointer hover:bg-blue-500 hover:text-white"
										onClick={triggerFileInput}
										disabled={loading}
									>
										Upload
									</button>
								)}
							</div>
						</div>
						<div
							className="flex-1 flex flex-col items-center"
							style={{ border: "1px solid gray", borderRadius: "5px" }}
						>
							<div className="border border-gray-300 rounded-lg overflow-hidden h-80 w-full flex items-center justify-center relative">
								{!candidatePhotoSrc ? (
									<video
										className="w-full h-full object-cover"
										style={{ transform: "scaleX(-1)" }}
										ref={candidatePhotoRef}
										autoPlay
									></video>
								) : (
									<img
										src={candidatePhotoSrc}
										alt="ID Preview"
										className="object-contain w-full h-full"
										style={{
											maxWidth: "100%",
											maxHeight: "100%",
											transform: "scaleX(-1)",
										}}
									/>
								)}
								{!candidatePhotoSrc ? (
									<button
										onClick={() => capturePhoto(setCandidatePhotoSrc, candidatePhotoRef)}
										className="absolute bottom-1 border border-blue-500 text-blue-500 text-sm px-4 py-2 rounded hover:bg-blue-500 hover:text-white cursor-pointer"
										disabled={loading}
									>
										Capture
									</button>
								) : (
									<button
										className="absolute bottom-1 border border-red-500 text-red-500 text-sm px-4 py-2 rounded hover:bg-red-500 hover:text-white cursor-pointer"
										onClick={() => deleteCapturePhoto(setCandidatePhotoSrc)}
										disabled={loading}
									>
										Re-Capture
									</button>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-row items-center mt-5 mb-8">
					<Button
						variant="contained"
						className="bg-green-500 text-black px-6 py-2 rounded hover:bg-green-600 mt-2"
						size="small"
						disabled={loading}
						onClick={verifyPhoto}
						sx={{ textTransform: "none" }}
					>
						{loading ? (
							<div>
								Verifying... <i className="fas fa-circle-notch fa-spin fa-xs"></i>
							</div>
						) : (
							<div>
								Verify <i className="fas fa-arrow-right fa-xs ml-1"></i>
							</div>
						)}
					</Button>
				</div>

				<div className="flex flex-col gap-4 items-center mt-2 mb-3">
					{isSkippable && (
						<Button
							variant="text"
							className="px-6 py-2 rounded"
							size="small"
							disabled={loading}
							onClick={onSkip}
							sx={{ textTransform: "none" }}
						>
							Skip
						</Button>
					)}
				</div>
			</div>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={handleSnackBarClose}
				message={snackBarMessage}
				action={action}
			/>
		</div>
	);
};

export default IdentityVerification;
