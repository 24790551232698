import React, { useState, useEffect } from "react";
import "./TabularOpeningEvaluations.css";
import { useParams, Link } from "react-router-dom";
import { Box, Tooltip, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import OpeningDataLoaderForEvaluations from "../components/Skeleton-Loaders/OpeningDataLoaderForEvaluations";
import ReportsLoader from "../components/Skeleton-Loaders/ReportsLoader";
import {
	hideHiddenCandidates,
	showHiddenCandidates as showHiddenCandidatesAction,
	showCompletedInterviews,
} from "../app/actions/candidates/candidate";
import { getOpeningDataWithId, setError } from "../features/createOpening/createOpeningSlice";
import ReportsDataGrid from "../components/grid/ReportsDataGrid";
import FilterComponent from "../components/Filter/FilterComponent";
import { removeAllFilters } from "../app/actions/filter/filter";
import ToolBar from "../components/TabularOpeningEvaluationsComponents/ToolBar";
import { fetchingInterviewReportWithID } from "../app/actions/candidates/candidate";

const TabularOpeningEvaluations = () => {
	const { user } = useSelector((state) => state.auth);
	const {
		showHiddenCandidates,
		isFetchingCandidates: loadingReports,
		showCompletedInterviews: hasInterviewCompleted,
	} = useSelector((state) => state.candidates);
	const dispatch = useDispatch();
	const params = useParams();
	const {
		selectedOpening: openingData,
		loading: fetchingOpeningData,
		error,
	} = useSelector((state) => state.openingDetails);
	const [copied, setCopied] = useState(false);
	const [restricted, setRestricted] = useState(false);

	async function getOpeningById() {
		try {
			if (params.openingId) {
				const data = await dispatch(getOpeningDataWithId(params.openingId)).unwrap();
				if (data && user && data?.organizationId !== user?.organizationId && user.role !== "1096") {
					throw new Error("Unauthorized");
				}
				if (user) {
					setRestricted(user?.restricted);
				}
				dispatch(removeAllFilters());
			}
		} catch (e) {
			console.log(e);
		}
	}

	async function getInterviewReportsForOpening() {
		try {
			if (params.openingId) {
				dispatch(fetchingInterviewReportWithID(params.openingId));
			}
		} catch (e) {
			console.log(e);
		}
	}
	async function fetchRequirements() {
		await getOpeningById();
		await getInterviewReportsForOpening();
	}

	useEffect(() => {
		fetchRequirements();
	}, []);

	useEffect(() => {
		if (error) {
			toast.error(`Error: ${error || "An error occurred."}`);
			setTimeout(() => {
				dispatch(setError(null));
			}, 1000);
		}
	}, [error]);

	async function handleCopyInterviewLink(openingId, shareLink) {
		try {
			if (shareLink) {
				await navigator.clipboard.writeText(shareLink);
			} else {
				await navigator.clipboard.writeText(`${window.origin}/interview/${openingId}`);
			}

			!shareLink && setCopied(true);
			toast.success("Interview link copied to clipboard");
		} catch (e) {
			console.log(e.message);
		}
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			setCopied(false);
		}, 3000);
		return () => {
			clearTimeout(timeout);
		};
	}, [copied]);

	return restricted ? (
		<Box
			className={`flex flex-col items-center justify-center bg-gray-100`}
			style={{
				height: "calc(100vh - var(--navbar-height))",
			}}
		>
			<Box id="panel2" className="bg-white p-6 rounded-lg shadow-lg w-4/5 md:w-1/2 lg:w-1/2">
				<Typography
					variant="h4"
					className={`text-center font-bold mb-6 ${isMobile ? "text-2xl" : "text-4xl"}`}
				>
					Account Restricted
				</Typography>
				<Typography
					variant="h6"
					className={`text-justify font-medium ${isMobile ? "text-sm" : "text-lg"}`}
				>
					{user.canConductInterviewMessage}
				</Typography>
			</Box>
		</Box>
	) : (
		<Box className="bg-gray-100 min-h-screen">
			<Box className="container mx-auto p-6">
				{Object.keys(openingData).length > 0 ? (
					<Box className="bg-white p-6 rounded-lg shadow-md mb-6">
						<Typography
							variant="p"
							href="opening-details.html"
							className="text-gray-500 font-bold text-xs mb-2 "
						>
							Job ID: {openingData?._id}
						</Typography>
						<div className="flex flex-row gap-4 items-center mb-2">
							<Typography variant="h2" className="text-2xl font-semibold ">
								{openingData?.title}
							</Typography>
							<Link
								to={
									params.organizationId
										? `/superadmin/admin/${params.organizationId}/create-opening-from-jd/update/${openingData?.isTechnical ? "1" : "0"}/${openingData?._id}`
										: `/admin/create-opening-from-jd/update/${openingData?.isTechnical ? "1" : "0"}/${openingData?._id}`
								}
							>
								<i className="fas fa-edit icon-light text-gray-500 text-sm cursor-pointer"></i>
							</Link>
						</div>

						<Box className="flex items-center flex-wrap gap-3 ">
							<input
								type="text"
								className="border rounded-md p-2 basis-[400px] bg-gray-200 text-sm"
								value={`${window.location.protocol}//${window.location.host}/interview/${openingData?._id}`}
								disabled
							/>
							{copied ? (
								<i className="fas fa-check text-green-500 text-sm "></i>
							) : (
								<Tooltip title={"Copy and share this link with the candidate."}>
									<i
										className="fas fa-copy text-blue-500 text-sm cursor-pointer "
										onClick={(e) => {
											e.stopPropagation();
											handleCopyInterviewLink(openingData?._id);
										}}
									></i>
								</Tooltip>
							)}
						</Box>
					</Box>
				) : (
					<OpeningDataLoaderForEvaluations />
				)}
				{loadingReports || fetchingOpeningData ? (
					<ReportsLoader />
				) : (
					<Box className="bg-white p-6 rounded-lg shadow-md mb-6">
						<ToolBar />
						<Box className="bg-gray-50 p-4 flex justify-start gap-5 items-center rounded-lg mb-6 flex-wrap">
							<label className="flex items-center space-x-3">
								<input
									checked={hasInterviewCompleted}
									type="checkbox"
									id="showCompletedInterviews"
									onChange={(e) => {
										dispatch(showCompletedInterviews());
									}}
								/>
								<span className="text-sm">Show Completed Interviews</span>
							</label>
							<label className="flex items-center space-x-3">
								<input
									checked={showHiddenCandidates}
									type="checkbox"
									id="showHiddenCandidates"
									onChange={(e) => {
										if (!e.target.checked) {
											dispatch(hideHiddenCandidates());
										} else {
											dispatch(showHiddenCandidatesAction());
										}
									}}
								/>
								<span className="text-sm">Show Hidden Candidates</span>
							</label>
							<div className="flex flex-1 justify-end">
								<FilterComponent />
							</div>
						</Box>
						{!fetchingOpeningData && <ReportsDataGrid />}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default TabularOpeningEvaluations;
