import React from 'react';
import Box from '@mui/material/Box';

const ResumePrompt = () => {
	return (
		<Box className='bg-gray-200'>
			<Box
				id='panel2'
				className='bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto flex flex-col items-center'>
				<i className='fas fa-info-circle text-blue-500 text-2xl'></i>
				<h2 className='text-center text-xl font-semibold mb-6'>
					It appears that you were interrupted. No worries, your
					session was saved, and you can resume where you left off.
				</h2>
				<Box className='flex justify-center'>
					<Box
						onClick={() => {
							window.location.reload();
						}}
						id='continue2'
						className='bg-blue-500 text-white py-2 px-4 rounded text-sm normal-case hover:bg-blue-600 focus:outline-none focus:border-none
                    cursor-pointer'>
						<i className='fas fa-arrow-right mr-2'></i>
						Resume
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ResumePrompt;
