const TrustScoreRenderer = ({params}) => {
	let interviewReport = params.row;
	return (
		<div className="flex items-center justify-center w-full h-full">
			{interviewReport?.trustScore
				? interviewReport?.trustScore !== 0
					? interviewReport?.trustScore
					: "0"
				: "-"}
		</div>
	);
};

export default TrustScoreRenderer;