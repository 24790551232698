import { PlayArrow } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

const RecordingRenderer = ({params}) => {
	let interviewReport = params.row;
	return (
		<div className="flex flex-wrap w-full h-full justify-center align-middle items-center text-center">
			{interviewReport?.meetingId && interviewReport?.concatenationId ? (
				interviewReport?.interviewCompleted || !interviewReport?.activeSession ? (
					<IconButton
						component={Link}
						to={`https://procturemeet.s3.ap-southeast-1.amazonaws.com/recordings/${interviewReport?._id}/composited-video/${interviewReport?.concatenationId}.mp4`}
						variant="text"
						color="primary"
						target="_blank"
						className="bg-sky-100 hover:bg-sky-200"
					>
						<PlayArrow />
					</IconButton>
				) : (
					<div className="text-sm text-gray-500 break-words overflow-hidden whitespace-normal">
						Recording will be available after the meeting ends
					</div>
				)
			) : (
				<div className="text-sm text-gray-500 break-words overflow-hidden whitespace-normal">
					Meeting has not started
				</div>
			)}
		</div>
	);
};

export default RecordingRenderer;