import { UploadFile } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { updateCandidate } from "../../../app/actions/candidates/candidate";
import { updateUserResume } from "../../../utilities/interviewReportsApi";
import { toast } from "react-toastify";
import React from "react";
import { useDispatch } from "react-redux";

const ResumeRenderer = ({ params }) => {
	let interviewReport = params.row;
	const [loading, setLoading] = React.useState(false);
	const dispatch = useDispatch();

	const openResumeUploadDialog = (candidateId) => {
		const fileInput = document.createElement("input");
		fileInput.type = "file";
		fileInput.accept = "application/pdf";
		fileInput.onchange = (e) => {
			handleResumeUpload(e, candidateId);
		};
		fileInput.click();
	};

	const handleResumeUpload = async (e, id) => {
		const file = e.target.files[0];
		if (file) {
			try {
				const formData = new FormData();
				formData.append("resume", file);
				formData.append("candidateId", id);
				await sendResumeToServer(formData);
			} catch (error) {
				console.error("Error uploading resume:", error);
			}
		}
	};

	const sendResumeToServer = async (formData) => {
		setLoading(true);
		const res = await updateUserResume(formData);
		if (res?.message && res?.data) {
			const { data } = res;
			if (data?._id) {
				dispatch(updateCandidate(data));
				toast.success("Resume uploaded successfully.");
			}
		}
		if (res?.failed && res?.message) {
			toast.error("Failed to upload resume. Please try again.");
		}
		setLoading(false);
	};

	return (
		<div className="flex items-center justify-center w-full h-full">
			{loading ? (
				<i id="" className="fas fa-circle-notch fa-spin text-2xl"></i>
			) : interviewReport?.resumeFileNameInS3 ? (
				<IconButton
					disableRipple
					onClick={() => {
						const resumeFileName = interviewReport.resumeFileNameInS3;
						const hasValidExtension = /\.(pdf|doc|docx)$/i.test(resumeFileName);
						const resumeUrl = hasValidExtension ? resumeFileName : `${resumeFileName}.pdf`;

						const url = resumeUrl.endsWith(".pdf")
							? `https://procturemeet.s3.ap-southeast-1.amazonaws.com/resumes/${resumeUrl}`
							: `${window.location.origin}/view/${resumeUrl}`;

						window.open(url, "_blank");
					}}
				>
					<i
						className={`fas scale-110 ${
							interviewReport?.resumeFileNameInS3.endsWith(".doc") ||
							interviewReport?.resumeFileNameInS3.endsWith(".docx")
								? "fa-file-word text-blue-500 hover:text-blue-700"
								: "fa-file-pdf text-red-500 hover:text-red-700"
						}`}
					></i>
				</IconButton>
			) : (
				<IconButton
					onClick={() => openResumeUploadDialog(interviewReport._id)}
					color="primary"
					className="scale-125 hover:bg-sky-100"
				>
					<UploadFile />
				</IconButton>
			)}
		</div>
	);
};

export default ResumeRenderer;
