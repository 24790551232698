import axios from "axios";

const getOrganizationNameFromAPI = async function (organizationId) {
	const res = await axios.get(`/api/organization/name/${organizationId}`);
	if (res?.data?.data?.organizationName) {
		sessionStorage.setItem(
			"organization",
			JSON.stringify({
				organizationName: res?.data?.data?.organizationName,
				orgLogoUrl: res?.data?.data?.orgLogoUrl,
				aboutUs: res?.data?.data?.aboutUs,
			}),
		);
	}
	return res.data;
};

const organizationService = { getOrganizationNameFromAPI };
export default organizationService;
