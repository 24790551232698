import { Box } from '@mui/material';

const JoinedOnMobile = () => {
	return (
		<Box className='bg-gray-200'>
			<Box
				id='panel1'
				className='bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto'>
				<h2 className='text-center text-xl font-semibold mb-6'>
					It looks like the interview is already open on another
					device. Please return to the original device/browser to
					continue your interview. If you cannot use your earlier
					device for any reason, please contact HR.
				</h2>
			</Box>
		</Box>
	);
};

export default JoinedOnMobile;
