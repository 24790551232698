//Index 0 indicates 'Interview Started,' and index 1 indicates 'Interview Dropped.'
const InterviewStatus = ["Interview Started!", "Interview Dropped!"];

const formatFullName = (firstName, lastName) => {
	const fullName = `${firstName?.trim() || ""} ${lastName?.trim() || ""}`.trim().toLowerCase();
	return fullName.charAt(0).toUpperCase() + fullName.slice(1);
};

const createNotificationMessage = (data, type) => {
	const fullName = formatFullName(data.firstName, data.lastName) || "A candidate";
	const email = data?.email || "-";
	const phoneNumber = data?.phoneNumber || "-";
	const organizationName = data.organization?.organizationName
		? `for ${data.organization.organizationName}`
		: "";
	const openingTitle = data.openingTitle ? `${data.openingTitle} interview` : "interview";

	let actionMessage;
	switch (type) {
		case "drop":
			actionMessage = `has dropped the ${openingTitle}`;
			break;
		case "start":
			actionMessage = `has started the ${openingTitle}`;
			break;
		case "complete":
			actionMessage = `has completed the ${openingTitle}`;
			break;
		default:
			actionMessage = `has updated the ${openingTitle}`;
			break;
	}

	return `${fullName} ${actionMessage} ${organizationName}. Email: ${email} Phone: ${phoneNumber}`;
};

const createNotificationObject = (message, data, destinationUrl, status) => {
	return {
		status: status,
		time: new Date().toISOString(),
		message: message,
		interviewReportId: data?._id,
		destinationUrl: destinationUrl,
	};
};

export { createNotificationMessage, createNotificationObject, InterviewStatus };
