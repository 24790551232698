import { TableCell, Tooltip, IconButton, Chip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { getFormattedDateTime2 } from "../../utilities/utilityFunctions";
import { removeDropReports } from "../../features/dropReports/dropReportsSlice";
import { useDispatch } from "react-redux";
import ViewLogsColumn from "../onGoingInterviews/ViewLogsColumn";
import ViewReportsColumn from "../onGoingInterviews/ViewReportsColumn";
import { checkIf48HoursPassed } from "../../utilities/utilityFunctions";
import { Link } from "react-router-dom";
import { removeNotification } from "../../features/notifications/NotificationsSlice";
const s3BaseUrl = "https://procturemeet.s3.ap-southeast-1.amazonaws.com";

const DropReportRows = ({ report }) => {
	const dispatch = useDispatch();
	const unscripted = localStorage.getItem("unscripted");
	const handleDelete = (id) => {
		dispatch(removeDropReports(id));
		dispatch(removeNotification({ interviewReportId: id, status: 1 }));
	};
	let parsedResult = report?.unscriptedELSAResult ? JSON.parse(report.unscriptedELSAResult) : null;

	const getLastActiveOrUpdatedTime = (report) => {
		if (report?.lastActiveTimestamp) {
			return getFormattedDateTime2(report.lastActiveTimestamp);
		}
		if (report?.updatedAt) {
			return getFormattedDateTime2(report.updatedAt);
		}
		return "-";
	};

	return (
		<>
			<TableCell className="text-center flex flex-col justify-center items-center">
				<p className="font-semibold">
					{report?.firstName || report?.lastName
						? `${report?.firstName || ""} ${report?.lastName || ""}`.trim()
						: "-"}
				</p>
				<p>{report?.phoneNumber || "-"}</p>
				<p className="text-blue-500">{report?.email || "-"}</p>
			</TableCell>
			<TableCell className="text-center">
				<p className="font-semibold cursor-pointer">
					<Link to={`/admin/evaluations/${report?.opening}`} className="no-underline text-blue-500">
						<Tooltip title={report?.openingTitle}>
							{report?.openingTitle?.length > 30
								? `${report.openingTitle.substring(0, 30)}...`
								: report?.openingTitle || "-"}
						</Tooltip>
					</Link>
				</p>
			</TableCell>
			<TableCell className="text-center">
				{(report?.interviewStartTime && getFormattedDateTime2(report?.interviewStartTime)) || "-"}
			</TableCell>
			<TableCell className="text-center">{getLastActiveOrUpdatedTime(report)}</TableCell>
			<TableCell className="text-center">{report?.organization?.organizationName || "-"}</TableCell>
			<TableCell className="text-center">{report?.trustScore || "-"}</TableCell>
			<TableCell className="text-center">
				<DropColumn report={report} />
			</TableCell>
			<TableCell className="text-center">
				{report?.resumeFileNameInS3 ? (
					<Tooltip title="View Resume">
						<IconButton
							disableRipple
							onClick={(e) =>
								window.open(`${s3BaseUrl}/resumes/${report?.resumeFileNameInS3}`, "_blank")
							}
						>
							<i className="fas fa-file-pdf text-red-500 text-2xl hover:text-red-700"></i>
						</IconButton>
					</Tooltip>
				) : (
					"-"
				)}
			</TableCell>
			<TableCell className="text-center">
				<ViewReportsColumn interviewReportId={report?._id} openingId={report?.opening} />
			</TableCell>
			<TableCell className="text-center">
				<ViewLogsColumn interviewReportId={report._id} />
			</TableCell>
			{unscripted === "show" && (
				<>
					<TableCell className="text-center">
						{parsedResult?.metrics?.general_scores?.elsa?.eps_score || "-"}
					</TableCell>
					<TableCell className="text-center">
						{parsedResult?.metrics?.general_scores?.cefr?.overall_cefr || "-"}
					</TableCell>
					<TableCell className="text-center">
						{report?.introAudioClipURL ? (
							<audio controls>
								<source src={report?.introAudioClipURL} type="audio/mpeg" />
								Your browser does not support the audio element.
							</audio>
						) : (
							"-"
						)}
					</TableCell>
				</>
			)}
			<TableCell
				className="text-center text-red-500 cursor-pointer"
				onClick={() => handleDelete(report?._id)}
			>
				<Tooltip title="Remove">
					<Delete fontSize="small" />
				</Tooltip>
			</TableCell>
		</>
	);
};

const DropColumn = ({ report }) => {
	return (
		<>
			{report?.browserClosed ? (
				checkIf48HoursPassed(report?.lastActiveTimestamp) ? (
					<Tooltip
						title="Interview Closed Due to Inactivity: It has been more than 48 hours since the candidate closed the tab or browser."
						arrow
					>
						<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
					</Tooltip>
				) : (
					<Tooltip title="Browser Closed: Candidate closed the tab or browser." arrow>
						<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
					</Tooltip>
				)
			) : (
				<Tooltip title="Candidate was unable to proceed." arrow>
					<Chip label="Dropped" className="bg-red-200 text-red-800 font-semibold" />
				</Tooltip>
			)}
		</>
	);
};

export default DropReportRows;
