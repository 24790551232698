import React, { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import { EDITOR_JS_TOOLS } from "./editorTools";

const EmailEditorComponent = ({ action, index = 99, onChange, suffix = "", style = {} }) => {
	const ejInstance = useRef();
	const initEditor = () => {
		const editor = new EditorJS({
			holder: `editorjs-${index}-${suffix}`,
			onReady: () => {
				ejInstance.current = editor;
			},
			autofocus: true,
			data: JSON.parse(action?.body),
			onChange: async () => {
				let content = await editor.saver?.save();
				onChange(JSON.stringify(content));
			},
			tools: EDITOR_JS_TOOLS,
		});
	};

	useEffect(() => {
		if (ejInstance.current === null || ejInstance.current === undefined) {
			initEditor();
		}

		return () => {
			ejInstance?.current?.destroy();
			ejInstance.current = null;
		};
	}, [action.id, index]);

	return (
		<>
			<div id={`editorjs-${index}-${suffix}`} className="pl-16 w-full pr-1 text-sm" style={style}></div>
		</>
	);
};

export default EmailEditorComponent;
