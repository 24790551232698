const API_FORM_FORMAT = {
    id: "",
    name: "",
    url: "",
    method: "GET",
    saving: false,
    bodyType: "rows",
    bodyJSON: {},
    convertToFormData: false,
    headers: [
        {
            key: "",
            type: "Text",
            value: "",
        },
    ],
    body: [
        {
            key: "",
            type: "Text",
            value: "",
        },
    ],
    params: [
        {
            key: "",
            type: "Text",
            value: "",
        },
    ],
    processResponse: false,
};
const RESPONSE_HANDLER_FORM_FORMAT = {
    type: "ARRAY",
    location: "response.data.",
    criteria: [
        {
            lhs: "",
            operator: "",
            rhs: "",
        },
    ],
    keyValuePairs: {
        firstName: {
            operator: "",
            rhs: "",
        },
        lastName: {
            operator: "",
            rhs: "",
        },
        preferredName: {
            operator: "",
            rhs: "",
        },
        email: {
            operator: "",
            rhs: "",
        },
        experience: {
            operator: "",
            rhs: "",
        },
        phoneNumber: {
            operator: "",
            rhs: "",
        },
        resumePDFLink: {
            operator: "",
            rhs: "",
        },
    },
}

const SYSTEM_COLUMNS = [
    {
        required: true,
        label: "First Name",
        name: "firstName",
        position: 1,
        type: "Text",
    },
    {
        required: true,
        label: "Last Name",
        position: 2,
        name: "lastName",
        type: "Text",
    },
    {
        required: false,
        label: "Preferred Name",
        position: 3,
        name: "preferredName",
        type: "Text",
    },
    {
        required: true,
        label: "Email",
        position: 4,
        name: "email",
        type: "Text",
    },
    {
        required: false,
        label: "Experience",
        position: 5,
        name: "experience",
        type: "Text",
    },
    {
        required: false,
        label: "Phone",
        position: 6,
        name: "phoneNumber",
        type: "Text",
    },
    {
        required: false,
        label: "Resume PDF Link",
        position: 7,
        name: "resumePDFLink",
        type: "Text",
    },
];

const EMAIL_TEMPLATE = {
    from: "support@zinterview.ai",
    to: "",
    subject: "",
    cc: "",
    body: JSON.stringify({
        time: new Date().getTime(),
        blocks: [
            {
                type: "paragraph",
                data: {
                    text: "Dear <=Name=>,",
                },
            },
        ],
    })
}

const MIDDLE_OPERATOR_TYPES = [
    { value: "Text", disabled: false },
    { value: "Column", disabled: false },
];

const VARIABLES = [
    // { value: "Admin Email" },
    // { value: "Admin Username" },
    // { value: "Organization" },
    // { value: "Evaluation" },
    // { value: "Current Date Time" }
]

const CRITERIA_ROW_FORMAT = {
    type: "row", // row or condition
    column: "",
    operator: "", // row = [ > , < , = , != , contains , not contains , starts with , ends with, today, tomorrow ] condition = [ AND , OR ]
    value: "",
};

const OPERATORS = [
    { value: ">", label: "Greater Than", disabled: false, rhsRequired: true, for: ["number", "date"] },
    { value: "<", label: "Less Than", disabled: false, rhsRequired: true, for: ["number", "date"] },
    { value: "=", label: "Equal To", disabled: false, rhsRequired: true, for: ["number", "date", "text"] },
    { value: "!=", label: "Not Equal To", disabled: false, rhsRequired: true, for: ["number", "date", "text"] },
    { value: "contains", label: "Contains", disabled: false, rhsRequired: true, for: ["text"] },
    { value: "not contains", label: "Not Contains", disabled: false, rhsRequired: true, for: ["text"] },
    { value: "starts with", label: "Starts With", disabled: false, rhsRequired: true, for: ["text"] },
    { value: "ends with", label: "Ends With", disabled: false, rhsRequired: true, for: ["text"] },
    { value: "empty", label: "Empty", disabled: false, rhsRequired: false, for: ["text"] },
    { value: "not empty", label: "Not Empty", disabled: false, rhsRequired: false, for: ["text", "date"] },
    { value: "today", label: "Today", disabled: true, rhsRequired: false, for: ["date"] },
    { value: "tomorrow", label: "Tomorrow", disabled: true, rhsRequired: false, for: ["date"] },
    { value: "true", label: "True", disabled: false, rhsRequired: false, for: ["boolean"] },
    { value: "false", label: "False", disabled: false, rhsRequired: false, for: ["boolean"] },
];

export { API_FORM_FORMAT, RESPONSE_HANDLER_FORM_FORMAT, SYSTEM_COLUMNS, MIDDLE_OPERATOR_TYPES, EMAIL_TEMPLATE, VARIABLES, CRITERIA_ROW_FORMAT, OPERATORS };