import React from "react";
import { isMobile } from "react-device-detect";
import ContentLoader from "react-content-loader";
const CreateUpdateOpeningLoader = () => {
	return (
		<ContentLoader
			speed={1.5}
			width={isMobile ? "90%" : "50%"}
			height={isMobile ? 1000 : 1800}
			viewBox="0 0 600 1800"
			backgroundColor="#e2e8f0"
			foregroundColor="#cbd5e0"
			style={{ margin: "0 auto" }}
		>
			{/* Form Header */}
			<rect x="25" y="20" rx="8" ry="8" width="550" height="24" />

			{/* Job Opening Name */}
			<rect x="25" y="60" rx="4" ry="4" width="550" height="16" />
			<rect x="25" y="90" rx="4" ry="4" width="550" height="40" />

			{/* Experience Range */}
			<rect x="25" y="150" rx="4" ry="4" width="550" height="16" />
			<rect x="25" y="180" rx="4" ry="4" width="550" height="40" />

			{/* Job Responsibilities */}
			<rect x="25" y="240" rx="8" ry="8" width="550" height="24" />
			<rect x="25" y="270" rx="4" ry="4" width="550" height="40" />
			<rect x="25" y="320" rx="20" ry="20" width="200" height="40" />

			{/* Core Skills */}
			<rect x="25" y="380" rx="8" ry="8" width="550" height="24" />
			<rect x="25" y="410" rx="4" ry="4" width="550" height="40" />
			<rect x="25" y="460" rx="4" ry="4" width="550" height="40" />
			<rect x="25" y="510" rx="4" ry="4" width="550" height="40" />
			<rect x="25" y="560" rx="20" ry="20" width="200" height="40" />

			{/* Interview Setup */}
			<rect x="25" y="620" rx="8" ry="8" width="550" height="24" />
			{/* Interview Setup Buttons */}
			<rect x="25" y="650" rx="20" ry="20" width="150" height="40" />
			<rect x="200" y="650" rx="20" ry="20" width="150" height="40" />
			<rect x="375" y="650" rx="20" ry="20" width="150" height="40" />

			{/* Web Cam Settings */}
			<rect x="25" y="710" rx="8" ry="8" width="550" height="24" />
			{/* Web Cam Settings Buttons */}
			<rect x="25" y="740" rx="20" ry="20" width="200" height="40" />
			<rect x="250" y="740" rx="20" ry="20" width="250" height="40" />

			{/* Support Information */}
			<rect x="25" y="800" rx="8" ry="8" width="550" height="24" />
			<rect x="25" y="830" rx="4" ry="4" width="150" height="40" />
			<rect x="200" y="830" rx="4" ry="4" width="150" height="40" />
			<rect x="375" y="830" rx="4" ry="4" width="200" height="40" />

			{/* Notifications */}
			<rect x="25" y="900" rx="8" ry="8" width="550" height="24" />
			<rect x="25" y="930" rx="4" ry="4" width="550" height="40" />

			{/* Additional Settings */}
			<rect x="25" y="990" rx="8" ry="8" width="550" height="24" />
			<rect x="25" y="1020" rx="4" ry="4" width="550" height="40" />
			<rect x="25" y="1080" rx="4" ry="4" width="550" height="40" />

			{/* Custom Questions */}
			<rect x="25" y="1140" rx="8" ry="8" width="550" height="24" />
			<rect x="25" y="1170" rx="4" ry="4" width="550" height="40" />
			<rect x="25" y="1220" rx="20" ry="20" width="200" height="40" />

			{/* Submit Button */}
			<rect x="25" y="1280" rx="20" ry="20" width="550" height="40" />
		</ContentLoader>
	);
};

export default CreateUpdateOpeningLoader;
