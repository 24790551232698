import { TextField } from "@mui/material/node";

const stringColumnFilter = (comparator) => {
    let filters = [
        {
            label: 'Equals',
            value: 'equals',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    return comparator(row, filterItem, 'equals');
                };
            },
            InputComponent: (props) => <TextField type="text" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: 'Contains',
            value: 'contains',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    return comparator(row, filterItem, 'contains');
                };
            },
            InputComponent: (props) => <TextField type="text" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: 'Starts with',
            value: 'startsWith',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    return comparator(row, filterItem, 'startsWith');
                };
            },
            InputComponent: (props) => <TextField type="text" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: 'Ends with',
            value: 'endsWith',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.value) {
                    return null;
                }
                return (value, row) => {
                    return comparator(row, filterItem, 'endsWith');
                };
            },
            InputComponent: (props) => <TextField type="text" size='small' value={props.item.value || ''} onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })} />,
        },
        {
            label: 'Empty',
            value: 'empty',
            getApplyFilterFn: (filterItem) => {
                return (value, row) => {
                    return comparator(row, filterItem, 'empty');
                };
            },
            InputComponent: () => null,
        },
        {
            label: 'Not Empty',
            value: 'notEmpty',
            getApplyFilterFn: (filterItem) => {
                return (value, row) => {
                    return comparator(row, filterItem, 'notEmpty');
                };
            },
            InputComponent: () => null
        }
    ];

    return filters;
}

export default stringColumnFilter;