import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const StatCard = ({ value, label, color, IconComponent }) => {
	return (
		<Card className="flex-1 text-white bg-blue-400" style={{ position: "relative", overflow: "hidden" }}>
			<CardContent>
				<Typography variant="h6" component="div">
					{value}
				</Typography>
				<Typography variant="body2">{label}</Typography>
			</CardContent>
			{IconComponent && (
				<IconComponent
					style={{
						position: "absolute",
						bottom: 0,
						right: 0,
						fontSize: "5rem",
						opacity: 0.3,
						color: "white",
					}}
				/>
			)}
		</Card>
	);
};

export default StatCard;
