import axios from "axios";

const getOpeningDataWithId = async (openingId) => {
	if (openingId) {
		const response = await axios.get(`/api/openings/opening/${openingId}/oc`);
		return response.data.opening;
	} else {
		throw new Error("Opening not found");
	}
};
const openingServices = { getOpeningDataWithId };
export default openingServices;
