import axios from "./axiosInstance";

export async function downloadEvaluationReportDocx(interviewReportId, fileName) {
	try {
		const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const response = await axios.post(
			`/api/evaluationReports/download-report-docx/${interviewReportId}`,
			{ userTimeZone },
			{
				responseType: "blob",
			},
		);
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
		link.remove();
	} catch (e) {
		console.log("error in fetching evaluation report for the opening");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}
