import axios from "axios";

const registerUser = async (formData) => {
	const res = await axios.post("/api/auth/register", formData);
	return res.data;
};

const loginUser = async (formData) => {
	const res = await axios.post("/api/auth/login", formData);
	return res.data;
};

const handleTwoFactorAuth = async (req) => {
	const res = await axios.post("/api/auth/verify", req);
	return res.data;
};

const logoutUser = async () => {
	// logout user from localStorage if localStorage mechanism is used
	// if httponly cookie version is used, make the api call to clear the cookie res.cookie("jwt","",{expiry : new Date(0)})
	const res = await axios.post("/api/auth/logout");
	return res.data;
};

const getAuthStatus = async () => {
	const res = await axios.get("/api/auth/auth-status");
	return res.data;
};

const authService = { registerUser, loginUser, logoutUser, handleTwoFactorAuth, getAuthStatus };
export default authService;
