import { FileOpen } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const LogsRenderer = ({ params }) => {
	let interviewReport = params.row;
	return (
		<div className="flex items-center justify-center w-full h-full">
			<Tooltip title="View Logs" arrow>
				<IconButton
					component={Link}
					to={`/superadmin/logs/${interviewReport._id}`}
					className="hover:bg-sky-100"
					target="_blank"
				>
					<FileOpen color="primary" />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default LogsRenderer;
