import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const NoReportsFoundMessage = () => {
	return (
		<Box className="flex flex-col items-center justify-center mt-[10%]">
			<ErrorOutlineIcon className="text-[50px] text-[#b0bec5] mb-[30px]" />
			<Typography variant="h6" textAlign="center" style={{ color: "#607d8b", fontWeight: "bold" }}>
				No Reports Found !
			</Typography>
		</Box>
	);
};
export default NoReportsFoundMessage;
