import { TextField } from "@mui/material";

const SearchReport = ({ reportsLength, onChangeInput, inputVlaue }) => {
	const changeInput = (e) => {
		onChangeInput(e.target.value);
	};
	return (
		<div className="mr-4">
			<TextField
				type="search"
				name="searchReports"
				value={inputVlaue}
				onChange={changeInput}
				placeholder={`Search ${reportsLength === 1 ? "report" : "reports"}${reportsLength ? ` from ${reportsLength} ${reportsLength === 1 ? "report" : "reports"}` : ""}`}
				variant="outlined"
				fullWidth
				size="small"
				spellCheck={false}
				sx={{
					marginBottom: 2,
					marginLeft: 2,
					marginRight: 5,
				}}
				className="focus:outline-none focus:ring-2 focus:ring-blue-500"
			/>
		</div>
	);
};

export default SearchReport;
