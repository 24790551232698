import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

const ViewReportRenderer = ({ params, openingData }) => {
	let interviewReport = params.row;
	return (
		<div className="flex items-center justify-center w-full h-full">
			<IconButton
				component={Link}
				to={`/admin/evaluation/${interviewReport?._id}?openingId=${openingData?._id}`}
				variant="text"
				className="inline-flex items-center justify-center h-8 w-8 bg-blue-100 text-blue-500 rounded-full hover:bg-blue-200"
				target="_blank"
			>
				<i className="fas fa-chevron-right text-sm hover:text-blue-700"></i>
			</IconButton>
		</div>
	);
};

export default ViewReportRenderer;
