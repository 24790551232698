import * as React from "react";
import { Button, IconButton, Modal } from "@mui/material";
import { useState } from "react";
import { Cancel, Event } from "@mui/icons-material";

import SystemEventsRenderer from "./events/SystemEventsRenderer";
import CustomEventsRenderer from "./events/CustomEventsRenderer";
import getFieldsArray from "../utilities/getFieldsArray";
import { useSelector } from "react-redux";

const EventsModal = ({ onEventModalClose = () => null }) => {
	const [modalState, setModalState] = useState({
		openEventModal: false,
		panel: 0,
	});

	const {
		selectedOpening: { customFields, _id: openingId },
	} = useSelector((state) => state.openingDetails);

	const [fields, _] = useState(getFieldsArray(customFields));

	const eventPanelRenderer = () => {
		switch (modalState.panel) {
			case 0:
				return (
					<SystemEventsRenderer openingId={openingId} fields={fields} customFields={customFields} />
				);
			case 1:
				return (
					<CustomEventsRenderer openingId={openingId} fields={fields} customFields={customFields} />
				);
			default:
				return null;
		}
	};

	return (
		<>
			<Button
				className="normal-case rounded-md focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
				sx={{
					backgroundColor: "#10B981",
					":hover": {
						backgroundColor: "#047857",
					},
				}}
				onClick={() => {
					setModalState({
						openEventModal: true,
						panel: 0,
					});
				}}
			>
				<Event className="pr-2" />
				Add Event
			</Button>
			{modalState.openEventModal && (
				<Modal
					open={modalState.openEventModal}
					onClose={() => {
						setModalState({
							openEventModal: false,
							panel: 0,
						});
						onEventModalClose();
					}}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<div
						className="bg-white p-6 pb-2 rounded-lg shadow-lg flex"
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "85vw",
							height: "85vh",
							bgcolor: "background.paper",
							boxShadow: 24,
						}}
					>
						<div className="flex align-middle justify-between pl-2 absolute top-[-2.2rem] w-full left-0 rounded">
							<div className="flex justify-center align-middle gap-1">
								<Button
									size="small"
									variant="contained"
									className={modalState.panel != "0" ? "bg-gray-400" : "scale-110 z-10"}
									onClick={() =>
										setModalState({
											openEventModal: true,
											panel: 0,
										})
									}
								>
									System Events
								</Button>
								<Button
									size="small"
									variant="contained"
									className={modalState.panel != "1" ? "bg-gray-400" : "scale-110 z-10"}
									onClick={() =>
										setModalState({
											openEventModal: true,
											panel: 1,
										})
									}
								>
									Custom Events
								</Button>
							</div>
							<IconButton
								onClick={() => {
									setModalState({
										openEventModal: false,
										panel: 0,
									});
									onEventModalClose();
								}}
								className="text-white cursor-pointer"
							>
								<Cancel />
							</IconButton>
						</div>
						{eventPanelRenderer()}
					</div>
				</Modal>
			)}
		</>
	);
};

export default EventsModal;
