import React, { useState } from "react";
import { Box, Modal, TextField, Button, Grid } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setuser } from "../features/auth/authSlice";

const OTPModal = ({ open, handleClose, email, rememberMe }) => {
	const dispatch = useDispatch();
	const [otp, setOtp] = useState("");
	const [otploader, setOtploader] = useState(false);

	const handleChange = (e) => {
		setOtp(e.target.value);
	};

	const handleOtpSubmit = async (otp) => {
		if (otp.length !== 6) {
			toast.error("Otp must be 6 number");
			return;
		}
		try {
			setOtploader(true);
			const res = await axios.post("/api/auth/verify", {
				code: parseInt(otp),
				email: email,
				rememberMe: rememberMe,
			});
			dispatch(setuser(res.data.data));
			setOtploader(false);
			toast.success("Code successfully verify");
		} catch (error) {
			setOtploader(false);
			console.error("Error verifying OTP", error);
			toast.error("Invalid OTP. Please try again.");
		}
	};
	const handleResend = async () => {
		try {
			setOtploader(true);
			await axios.put(`/api/auth/generate/${email}`);
			setOtploader(false);
			toast.success("Code Successfully Resent");
		} catch (error) {
			setOtploader(false);
			console.error("Error resending code:", error);
			toast.error("Failed to send otp, please try again!");
		}
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="otp-modal-title"
			aria-describedby="otp-modal-description"
		>
			<Box className="bg-white p-6 rounded-lg shadow-lg w-1/3 mx-auto mt-40">
				<h2 id="otp-modal-title" className="text-lg font-medium">
					Enter OTP
				</h2>
				<TextField
					id="otp"
					type="number"
					label="OTP"
					variant="outlined"
					fullWidth
					value={otp}
					required
					onChange={handleChange}
					className="mt-4 mb-4"
					disabled={otploader}
				/>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Button
							disabled={otploader}
							variant="outlined"
							color="primary"
							onClick={() => handleOtpSubmit(otp)}
							fullWidth
						>
							Submit
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant="outlined"
							color="secondary"
							disabled={otploader}
							onClick={handleResend}
							fullWidth
						>
							Resend
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};

export default OTPModal;
