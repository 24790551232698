import { CircularProgress } from "@mui/material";
import React from "react";

const Loader = () => {
	return (
		<div className="py-6 flex justify-center items-center min-h-[90vh]  w-full">
			<CircularProgress />
		</div>
	);
};

export default Loader;
