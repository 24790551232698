import * as React from "react";
import { Modal, TextField } from "@mui/material";

const ScheduleCancellationDialog = ({
	label = "",
	onClose = () => {},
	onConfirm = () => {},
	header = "Confirmation",
	open,
}) => {
	const [cancellationReason, setCancellationReason] = React.useState("");
	const onClickConfirm = () => {
		onConfirm(cancellationReason);
		onClose();
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="confirmation-dialog"
			aria-describedby="confirmation-dialog-description"
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "30vw",
					boxShadow: 24,
					backgroundColor: "white",
					borderRadius: "0.5rem",
				}}
			>
				<div
					style={{
						padding: "1rem",
						// w-96
						// width: "22rem",
					}}
				>
					<h2
						style={{
							fontSize: "1.25rem",
							fontWeight: "600",
							marginBottom: "1.25rem",
						}}
					>
						{header}
					</h2>
					<p
						style={{
							marginBottom: "1.5rem",
						}}
					>
						{label}
					</p>
					<p
						htmlFor="modal-cancellationReason"
						style={{
							display: "block",
							fontSize: "0.875rem",
							fontWeight: "500",
							color: "#4B5563",
							marginBottom: "0.5rem",
						}}
					>
						Reason for Cancellation (optional)
					</p>
					<TextField
						sx={{
							"& fieldset": {
								border: "1px solid #E5E7EB",
							},
						}}
						size="small"
						type="text"
						name="cancellationReason"
						fullWidth
						value={cancellationReason}
						onChange={(e) => setCancellationReason(e.target.value)}
						style={{ marginBottom: 10 }}
					/>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							gap: "1rem",
						}}
					>
						<button
							style={{
								backgroundColor: "#ccc",
								cursor: "pointer",
								padding: "0.5rem 1rem",
								borderRadius: "0.25rem",
								border: "none",
							}}
							onClick={onClose}
						>
							Cancel
						</button>
						<button
							style={{
								backgroundColor: "#007bff",
								color: "#fff",
								cursor: "pointer",
								padding: "0.5rem 1rem",
								borderRadius: "0.25rem",
								border: "none",
								opacity: 1,
							}}
							onClick={onClickConfirm}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export { ScheduleCancellationDialog };
