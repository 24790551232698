import {
	TextField,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
	TableContainer,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { isValidEmail } from "../../utilities/validation";
import AddIcon from "@mui/icons-material/Add";

const CCInviteTo = ({ isCCInviteActive, ccEmails, setCcEmails }) => {
	const [ccEmail, setCcEmail] = useState("");

	if (!isCCInviteActive) return null;

	function addEmailToCcEmails(e) {
		e.preventDefault();
		if (!ccEmail) {
			toast.warn("Email cannot be empty.");
			return;
		}
		if (!isValidEmail(ccEmail)) {
			toast.warn("Invalid Email.");
			return;
		}
		if (ccEmails.includes(ccEmail)) {
			toast.warn("Email already exists.");
			return;
		}
		if (ccEmails?.length >= 50) {
			toast.warn("Too many email addresses in CC. The limit is 50.");
			return;
		}
		ccEmails.unshift(ccEmail);
		setCcEmails([...ccEmails]);
		setCcEmail("");
	}

	function deleteEmailFromCcEmails(email) {
		let filterCcEmails = ccEmails.filter((eachEmail) => {
			if (eachEmail !== email) return eachEmail;
		});
		setCcEmails(filterCcEmails);
	}

	return (
		<div className="flex flex-col gap-3 rounded-lg bg-blue-50 p-4 shadow-lg mt-4">
			<div className="mt-4 flex justify-center items-center gap-2 w-1/2 mx-auto">
				<form onSubmit={addEmailToCcEmails} className="flex-grow">
					<TextField
						autoFocus
						label="Enter email"
						type="text"
						size="small"
						value={ccEmail}
						onChange={(e) => {
							setCcEmail(e.target.value?.replace(/\s+/g, ""));
						}}
						className="w-full"
					/>
				</form>
				<button
					className="px-3 py-2 flex border-none bg-green-500 text-white rounded-md shadow hover:bg-green-600 text-sm cursor-pointer"
					onClick={addEmailToCcEmails}
				>
					<AddIcon className="w-5 h-5" /> Add
				</button>
			</div>
			<EmailRecipientsTable ccEmails={ccEmails} deleteEmailFromCcEmails={deleteEmailFromCcEmails} />
		</div>
	);
};

const EmailRecipientsTable = ({ ccEmails, deleteEmailFromCcEmails }) => {
	if (ccEmails?.length === 0) return null;

	return (
		<TableContainer className="shadow-lg rounded-lg">
			<Table aria-label="simple table" className="bg-blue-100">
				<TableHead>
					<TableRow>
						<TableCell className="text-center">No</TableCell>
						<TableCell className="text-center">Email</TableCell>
						<TableCell className="text-center">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ccEmails?.map((email, index) => (
						<TableRow key={index}>
							<TableCell className="text-center">{index + 1}</TableCell>
							<TableCell
								className="text-center"
								sx={{
									wordBreak: "break-word",
									whiteSpace: "normal",
								}}
							>
								{email}
							</TableCell>
							<TableCell className="text-center">
								<Tooltip title="delete">
									<DeleteIcon
										className="text-red-600 cursor-pointer"
										onClick={() => deleteEmailFromCcEmails(email)}
									/>
								</Tooltip>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CCInviteTo;
