import React from "react";
import {
	Box,
	Typography,
	Stack,
	Skeleton,
	IconButton,
	Menu,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Button,
	LinearProgress,
	Tooltip,
	Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchInterviewReportById, resumeInterviewFromGivenIndex } from "../utilities/interviewReportsApi";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";

const InterviewMessages = ({ source, report, setReport }) => {
	const dispatch = useDispatch();
	const params = useParams();
	const [searchParams] = useSearchParams();
	const [messages, setMessages] = useState(null);
	const [isLoadingMessages, setIsLoadingMessages] = useState(false);
	const [userName, setUserName] = useState("");
	const [anchorElForMessagesMenuIcon, setAnchorElForMessagesMenuIcon] = useState(null);
	const [isResumeInterviewMode, setIsResumeInterviewMode] = useState(false);
	const [resumeInterviewDialogOpen, setResumeInterviewDialogOpen] = useState(false);
	const [resumeFromMessageIndex, setResumeFromMessageIndex] = useState(null);
	const [isResumingInterview, setIsResumingInterview] = useState(false);
	const messagesMenuOpen = Boolean(anchorElForMessagesMenuIcon);

	useEffect(() => {
		async function getMessages() {
			const openingId = searchParams.get("openingId");
			if (report) {
				if (report?.messages) {
					setMessages(report.messages);
				}
				if (report?.preferredName) {
					setUserName(report.preferredName);
				}
			} else if (params.interviewReportId && openingId) {
				setIsLoadingMessages(true);
				const report = await fetchInterviewReportById(params.interviewReportId);
				if (report.messages) {
					setMessages(report.messages);
					setReport((prev) => ({
						...prev,
						messages: report.messages,
					}));
				}
				if (report.preferredName) {
					setUserName(report.preferredName);
				}
				setIsLoadingMessages(false);
			}
		}
		try {
			getMessages();
		} catch (e) {
			toast.warn(e.message);
		}
	}, [params, searchParams, report]);

	useEffect(() => {
		function getLatestMessages(e) {
			if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === "m") {
				e.preventDefault();
				handleGetLatestMessages();
			}
		}
		document.addEventListener("keydown", getLatestMessages);
		return () => {
			document.removeEventListener("keydown", getLatestMessages);
		};
	}, []);

	const renderMessages =
		!isLoadingMessages &&
		messages &&
		messages.length > 0 &&
		messages.map((message, i) => {
			const {
				role,
				content,
				function_call_response,
				isAutoSkipped = false,
				isInterviewEnded = false,
			} = message;
			return (
				<MessageBox
					message={content}
					user={role}
					key={i}
					userName={userName}
					function_call_response={function_call_response}
					isResumeInterviewMode={isResumeInterviewMode}
					handleOpenResumeInterviewDialog={(e) => handleOpenResumeInterviewDialog(e, i)}
					isResumableMessage={i >= messages.length - 5}
					isCurrentCellSelected={i === resumeFromMessageIndex}
					isResumingInterview={isResumingInterview}
					isAutoSkipped={isAutoSkipped}
					isInterviewEnded={isInterviewEnded}
				/>
			);
		});

	const handleMessagesMenuOpen = (e) => {
		setAnchorElForMessagesMenuIcon(e.currentTarget);
	};
	const handleMessagesMenuClose = async (e, option) => {
		setAnchorElForMessagesMenuIcon(null);
		if (option === "resume") {
			if (!messages || messages.length === 0) {
				await handleResumeInterviewFromGivenMessageIndex();
			} else {
				setIsResumeInterviewMode((prev) => !prev);
			}
		}
	};

	const handleOpenResumeInterviewDialog = (e, messageIndex) => {
		setResumeInterviewDialogOpen(true);
		setResumeFromMessageIndex(messageIndex);
	};

	const handleCloseResumeInterviewDialog = (e) => {
		setResumeInterviewDialogOpen(false);
		setResumeFromMessageIndex(null);
	};

	const handleResumeInterviewFromGivenMessageIndex = async (e) => {
		try {
			if (+resumeFromMessageIndex >= 0) {
				// console.log(
				// 	'resuming interview from message index: ',
				// 	resumeFromMessageIndex
				// );
				setIsResumingInterview(true);
				const res = await resumeInterviewFromGivenIndex(
					params.interviewReportId,
					resumeFromMessageIndex ?? -1,
				);
				if (res?.interviewReport?.messages) {
					setReport((prev) => ({
						...prev,
						messages: res.interviewReport.messages,
					}));
				}
				toast.success(res.message || "Interview resumed successfully");
				setIsResumeInterviewMode(false);
				setResumeFromMessageIndex(null);
				// setIsResumingInterview(false);
				// setResumeInterviewDialogOpen(false);
				await handleCopyInterviewLinkToClipboard(res?.resumeToken);
			}
		} catch (e) {
			setIsResumeInterviewMode(false);
			setResumeFromMessageIndex(null);
			setIsResumingInterview(false);
			setResumeInterviewDialogOpen(false);
			toast.warn(e.message);
		}
	};

	const handleCopyInterviewLinkToClipboard = async (resumeToken) => {
		try {
			const openingId = searchParams.get("openingId");
			let url = "";
			if (resumeToken) {
				url = `/interview/${openingId}/start/${params.interviewReportId}/${resumeToken}`;
			} else {
				// url = `/interview/${openingId}/start/${params.interviewReportId}`;
				report?.resumeToken
					? (url = `/interview/${openingId}/start/${params.interviewReportId}/${report?.resumeToken}`)
					: (url = `/interview/${openingId}/start/${params.interviewReportId}`);
			}
			const newUrl = new URL(url, window.location.origin);
			await navigator.clipboard.writeText(newUrl.href);
			toast.success("Copied interview link to clipboard");
			handleMessagesMenuClose();
		} catch (e) {
			console.log("could not copy link for the interview");
			console.log(e);
		}
		setIsResumingInterview(false);
		setResumeInterviewDialogOpen(false);
	};

	const handleGetLatestMessages = async () => {
		try {
			setIsLoadingMessages(true);
			const report = await fetchInterviewReportById(params.interviewReportId);
			if (report) {
				setReport(report);
			}
			// come back here, do we really need to set report.messages again?
			// since InterviewMessages is a child of InterviewReport, when setReport is called, InterviewMessages should re-render again.
			// if (report.messages) {
			// 	setMessages(report.messages);
			// }
			// if (report.preferredName) {
			// 	setUserName(report.preferredName);
			// }
			setIsLoadingMessages(false);
		} catch (e) {
			toast.warn(e.message);
		}
	};

	return (
		<>
			<Box
				sx={{
					textAlign: "center",
					paddingBottom: "1rem",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					minHeight: "100vh",
					flexDirection: "column",
					position: "relative",
					width: "100%",
				}}
			>
				{!report && (
					<Typography variant="h4" component={"h1"} gutterBottom>
						Interview Exchange
					</Typography>
				)}
				{!isLoadingMessages && (
					<Stack
						direction={"row"}
						sx={{
							position: "absolute",
							right: "0px",
							top: "0px",
							padding: "0px",
							margin: "0px",
						}}
					>
						<IconButton onClick={handleMessagesMenuOpen}>
							<MoreVertIcon />
						</IconButton>

						<IconButton onClick={handleGetLatestMessages}>
							<Tooltip title="Get Latest Messages (Ctrl+M)">
								<RefreshIcon />
							</Tooltip>
						</IconButton>
					</Stack>
				)}

				<Box
					sx={{
						// backgroundColor: '#fafafa',
						flex: 1,
						width: `${source ? "100%" : "min(100% - 10px , 1020px)"}`,
						paddingBlock: "0.625rem",
						paddingInline: `${report ? "0rem" : "0.5rem"}`,
						display: "flex",
						flexDirection: "column",
						gap: "1rem",
					}}
				>
					{isLoadingMessages ? <DummyMessages /> : renderMessages}
				</Box>
			</Box>
			<Menu
				id="messages-option-menu"
				anchorEl={anchorElForMessagesMenuIcon}
				open={messagesMenuOpen}
				onClose={handleMessagesMenuClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<MenuItem onClick={(e) => handleMessagesMenuClose(e, "resume")}>
					Resume Interview <Switch checked={isResumeInterviewMode} />
				</MenuItem>
				<MenuItem onClick={() => handleCopyInterviewLinkToClipboard()}>Copy Interview Link </MenuItem>
			</Menu>
			<Dialog
				open={resumeInterviewDialogOpen}
				onClose={handleCloseResumeInterviewDialog}
				aria-labelledby="resume-interview-dialog-title"
				aria-describedby="resume-interview-dialog-description"
			>
				<DialogTitle id="resume-interview-dialog-title">{"Resume interview from here?"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="resume-interview-dialog-description">
						This action will resume the interview from the current message. This means that all
						messages below this message will be deleted.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseResumeInterviewDialog}>Cancel</Button>
					<Button onClick={handleResumeInterviewFromGivenMessageIndex} autoFocus>
						Resume
					</Button>
				</DialogActions>
				{isResumingInterview && <LinearProgress />}
			</Dialog>
		</>
	);
};

export default InterviewMessages;

const MessageBox = ({
	user,
	message,
	userName,
	function_call_response,
	isResumeInterviewMode = false,
	handleOpenResumeInterviewDialog,
	isCurrentCellSelected = false,
	isResumableMessage = false,
	isResumingInterview,
	isAutoSkipped,
	isInterviewEnded = false,
}) => {
	let functionCallEvalString = "";
	try {
		if (function_call_response && function_call_response?.function_call) {
			const { name, arguments: args } = function_call_response?.function_call;

			if (name === "listening_comprehension_test") {
				const { audio_scenario, questions_from_the_audio } = JSON.parse(args);
				let questionsString = "";
				for (let obj of questions_from_the_audio) {
					questionsString += obj["question"] + "\n";
				}
				functionCallEvalString = `
	We will now proceed to the listening comprehension test.\n
	Audio Passage is -> \n${audio_scenario} \n
	After you've heard the audio material, answer the following questions:\n 
	${questionsString}
				`;
			}
			if (name === "error_correction") {
				const { error_ridden_passage } = JSON.parse(args);
				functionCallEvalString = `
	Next, you will be presented with a passage containing errors. Your task is to identify and correct these errors.\n
	Error_Ridden Passage is ->\n ${error_ridden_passage}
				`;
			}
			if (name === "email_writing_assessment") {
				const { compose_email_scenario } = JSON.parse(args);
				functionCallEvalString = `
	In this segment, you will be provided with a scenario, and your task is to compose an email based on that scenario.\n
	The scenario is -> \n${compose_email_scenario}
				`;
			}
		}
	} catch (e) {
		console.log("error while displaying function call responses in interview messages");
		console.log(e.message);
	}
	let responseMessage = "";

	const tankLeft = user === "assistant";
	if (!message) message = "";
	const codeStringIndex = message.indexOf("```");
	const codeString =
		codeStringIndex !== -1 ? message.substring(codeStringIndex + 3, message.length - 3) : "";
	responseMessage = codeStringIndex === -1 ? message : message.substring(0, codeStringIndex - 1);
	if (responseMessage && functionCallEvalString) {
		// check if both content and function call is present
		responseMessage += functionCallEvalString;
	} else if (!responseMessage && functionCallEvalString) {
		responseMessage = functionCallEvalString;
	}

	const isAllowedForResuming =
		isResumeInterviewMode && tankLeft && isResumableMessage && !isResumingInterview;

	return (
		<Box
			// display={'flex'}
			// flexDirection={'column'}
			// justifyContent={'flex-start'}
			// alignItems={tankLeft ? 'flex-start' : 'flex-end'}
			className={`flex flex-col justify-start ${tankLeft ? "items-start" : "items-end"} `}
		>
			<Typography variant="body2">
				{user == "assistant"
					? "Interviewer"
					: userName
						? `${userName + (isAutoSkipped ? " (Auto Skipped)" : "")}`
						: `Candidate + ${isAutoSkipped ? " (Auto Skipped)" : ""}`}
			</Typography>
			<Box
				sx={{
					backgroundColor: "lavender",
					borderRadius: "4px",
					padding: "1rem",
					width: "fit-content",
					maxWidth: { xs: "100%", sm: "75%" },
					textAlign: "left",
					cursor: isAllowedForResuming ? "pointer" : "auto",
				}}
				className={`${isAllowedForResuming ? "message-resume-mode" : ""} ${
					isAllowedForResuming && isCurrentCellSelected ? "resume-from-selected-message" : ""
				}`}
				onClick={
					!isResumingInterview
						? isAllowedForResuming
							? handleOpenResumeInterviewDialog
							: () => {
									if (isResumeInterviewMode && tankLeft) {
										toast.warn(
											"Resuming the interview is only possible from the latest three assistant messages",
										);
									}
								}
						: null
				}
			>
				<>
					<Typography variant="body1" className="whitespace-pre-line">
						{responseMessage}
					</Typography>
					{codeString && (
						<Box margin={"1rem"}>
							{codeStringIndex != -1 && codeString.length > 0 && (
								<div>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											backgroundColor: "black",
											borderRadius: "10px 10px 0 0",
											paddingInline: "10px",
										}}
									>
										<span
											style={{
												color: "white",
												padding: "5px 3px",
											}}
										>
											Code
										</span>
									</div>
									<SyntaxHighlighter
										language={"javascript"}
										style={monoBlue}
										customStyle={{
											padding: "2rem",
											borderRadius: "0 0 10px 10px",
										}}
									>
										{codeString.trim()}
									</SyntaxHighlighter>
								</div>
							)}
						</Box>
					)}
				</>
			</Box>
		</Box>
	);
};

function DummyMessages() {
	const arr = Array.from({ length: 10 }, (_, i) => i);

	return (
		<Stack flexDirection={"column"} gap="2rem">
			{arr.map((i) => {
				const tankLeft = i % 2 == 0;
				return (
					<Box
						key={i}
						// display={'flex'}
						// flexDirection={'column'}
						// justifyContent={'flex-start'}
						// alignItems={tankLeft ? 'flex-start' : 'flex-end'}
						// gap={'3px'}\
						className={`flex flex-col justify-start ${
							tankLeft ? "items-start" : "items-end"
						} gap-[3px]`}
					>
						<Skeleton
							variant="rectangular"
							width={"100px"}
							height={20}
							sx={{ borderRadius: "10px" }}
						/>
						<Skeleton
							variant="rectangular"
							width={"80%"}
							height={50}
							sx={{ borderRadius: "10px" }}
						/>
					</Box>
				);
			})}
		</Stack>
	);
}
