import React, { useState } from "react";
import { Box, Button, Grid, Modal } from "@mui/material";
import { toast } from "react-toastify";
import logo from "../../assets/zi-favicon.png";
import { markInvoiceAsPaidSuperAdminAPI } from "../../utilities/superAdmin/invoices";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	maxWidth: "100%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	overflowY: "auto",
	maxHeight: "90vh",
	borderRadius: "10px",
	"@media (min-width: 800px)": {
		width: 800,
	},
};

const MarkInvoiceAsPaid = ({ organizationId, getOrganizationInvoices, invoice, getOrganizationsDetails }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	console.log(invoice);

	const onSubmit = async (event) => {
		event.preventDefault();
		try {
			const data = {
				organizationId: organizationId,
				invoiceId: invoice._id,
			};
			setIsLoading(true);
			const res = await markInvoiceAsPaidSuperAdminAPI(data);
			toast.success(res.message);
			setIsLoading(false);
			setShowModal(false);
			getOrganizationInvoices();
            getOrganizationsDetails();
		} catch (error) {
			console.log(error.message);
			toast.error(error.message);
			setIsLoading(false);
			setShowModal(false);
			getOrganizationInvoices();
		}
	};

	return (
		<>
			<Button
				variant="outlined"
				color="secondary"
				className="flex-1"
				onClick={() => setShowModal(true)}
			>
				Mark This Invoice As Paid
			</Button>
			<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
				aria-labelledby="mark invoice as account"
				aria-describedby="mark invoice as account"
			>
				<Box sx={modalStyle}>
					<Box className="flex justify-center mb-6">
						<img src={logo} alt="Zinterview Logo" className="h-16" />
					</Box>
					<form onSubmit={onSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box className="mb-2 p-2">
									Mark this invoice as paid
									<strong> ₹{invoice?.totalAmount ? invoice?.totalAmount : 0}</strong>. Click
									the "Pay Now" button below to complete your payment.
								</Box>
							</Grid>
						</Grid>
						<Box
							onClick={onSubmit}
							className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2 normal-case ${
								isLoading && "cursor-not-allowed opacity-50"
							}`}
							type="submit"
							fullWidth
							disabled={isLoading}
							component={Button}
						>
							{isLoading ? (
								<span>
									{"Marking This Invoice As Paid... "}
									<i className="fas fa-circle-notch fa-spin mr-1"></i>
								</span>
							) : (
								<span>Mark This Invoice As Paid</span>
							)}
						</Box>
					</form>
				</Box>
			</Modal>
		</>
	);
};

export default MarkInvoiceAsPaid;