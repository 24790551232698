import React from "react";
import { Box } from "@mui/material";

const Cancelled = () => {
	return (
		<Box id="panel2" className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto">
			<h2 className="text-center text-2xl font-bold mb-6">
				This interview has been cancelled. For more details, you can reach out to the HR team.
			</h2>
		</Box>
	);
};

export default Cancelled;
