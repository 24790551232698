import { Button } from "@mui/material/node";
import React, { useState } from "react";
import AddEditCandidateModal from "../AddEditCandidates";
import { useSelector } from "react-redux";
import { DEFAULT_COUNTRY } from "../country-selector/countries";
const initialUserDetails = {
	firstName: "",
	lastName: "",
	preferredName: "",
	email: "",
	phoneNumber: "",
	country: DEFAULT_COUNTRY,
	experience: "",
	resumeFileNameInS3: "",
	customFieldsData: {},
};
const AddCandidates = () => {
	const openingData = useSelector((state) => state.openingDetails.selectedOpening);
	const [addCandidate, setAddCandidate] = useState(false);
	const isResumeRequired = openingData?.questionsBasedOnResume;
	const finalCustomFields = openingData
		? openingData?.customFields?.filter((field) => {
				return !field.isDeleted;
			})
		: [];

	return (
		<>
			<Button
				className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
				onClick={() => {
					setAddCandidate(true);
				}}
				sx={{
					backgroundColor: "#3B82F6",
					":hover": {
						backgroundColor: "#1D4ED8",
					},
				}}
			>
				<i className="fas fa-plus mr-2"></i>
				Add Candidate
			</Button>
			{addCandidate && (
				<AddEditCandidateModal
					open={addCandidate}
					onClose={() => {
						setAddCandidate(false);
					}}
					candidate={JSON.parse(JSON.stringify(initialUserDetails))}
					finalCustomFields={finalCustomFields}
					openingId={openingData?._id}
					isResumeRequired={isResumeRequired}
				/>
			)}
		</>
	);
};

export default AddCandidates;
