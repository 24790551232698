import { useEffect } from "react";

export const useNavHeight = (navbarId = "nav-bar") => {
	useEffect(() => {
		try {
			const navEl = document.getElementById(navbarId);
			const h = navEl?.getBoundingClientRect()?.height;
			if (h) {
				const root = document.querySelector(":root");
				root.style.setProperty("--navbar-height", h + "px");
			}
		} catch (e) {
			console.log(e.message);
		}
	}, [navbarId]);
};
