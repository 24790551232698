import { Box, Typography } from '@mui/material'
import React from 'react'

export default function NotFound() {
  return (
    <Box sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "lavender" }}>
      <Typography variant='h2' component={"h1"}> 404 Page Not Found {":("} </Typography>

    </Box>
  )
}
