function getFormattedDateTime(dateString) {
	const date = new Date(dateString);
	return date.toLocaleDateString() + " " + date.toLocaleTimeString();
}

function getFormattedDateTime2(dateString) {
	const date = new Date(dateString);
	const dateOptions = { year: "numeric", month: "long", day: "numeric" };
	const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
	return (
		date.toLocaleDateString("en-US", dateOptions) + ", " + date.toLocaleTimeString("en-US", timeOptions)
	);
}

function getFormattedDateTime3(dateString) {
	const date = new Date(dateString);
	const options = { year: "numeric", month: "short", day: "numeric" };
	return date.toLocaleDateString("en-US", options);
}

function getFormattedDateTime4(dateString) {
	const date = new Date(dateString);

	// Options for formatting the date part
	const dateOptions = {
		weekday: "short",
		year: "numeric",
		month: "short",
		day: "numeric",
	};
	const formattedDate = date.toLocaleDateString("en-US", dateOptions);

	// Options for formatting the time part
	const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
	const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

	return `${formattedDate}, ${formattedTime}`;
}

function checkIfInvalidOrUnauthorized(message = "") {
	if (
		message &&
		typeof message === "string" &&
		(message.toLowerCase().includes("unauthorized") ||
			message.toLowerCase().includes("not authorized") ||
			message.toLowerCase().includes("missing token") ||
			message.toLowerCase().includes("expired"))
	) {
		return true;
	}
	return false;
}

function checkIf48HoursPassed(dateString) {
	if (!dateString) {
		return false;
	}
	const date = new Date(dateString);
	if (isNaN(date.getTime())) {
		return false;
	}
	const currentTime = new Date();
	const diffInHours = (currentTime - date) / (1000 * 60 * 60);
	return diffInHours >= 48;
}

function getValuesFromRHFArray(arrayOfObjects = []) {
	return arrayOfObjects.map((obj) => {
		return obj.value;
	});
}

function stripMarkdown(input = "") {
	// Remove code blocks
	input = input.replace(/```(?:\w+)?\n[\s\S]*?\n```/g, "");
	// Remove headers
	input = input.replace(/^#.*$/gm, "");
	// Remove bold and italic
	input = input.replace(/\*\*([^*]+)\*\*/g, "$1"); // **text**
	input = input.replace(/\*([^*]+)\*/g, "$1"); // *text*
	input = input.replace(/__([^_]+)__/g, "$1"); // __text__
	input = input.replace(/_([^_]+)_/g, "$1"); // _text_
	// Remove inline code
	input = input.replace(/`([^`]+)`/g, "$1"); // `text`
	// Remove horizontal rules
	input = input.replace(/^(-\s*?){3,}$/gm, "");
	// Remove unordered lists
	input = input.replace(/^\s*[-+*]\s+/gm, "");
	// Remove ordered lists
	input = input.replace(/^\s*\d+\.\s+/gm, "");
	// Remove tables
	input = input.replace(/^\|.*\|$/gm, "");

	return input;
}

const convertFilenameToLocal = (filename) => {
	// Extract the timestamp part from the filename
	const timestamp = filename.split("/").pop().split("_")[0]; // Get the part before the underscore

	// Convert timestamp format to ISO format
	const isoTimestamp = timestamp.replace(/T(\d{2})-(\d{2})-(\d{2})-(\d{3})Z$/, "T$1:$2:$3.$4Z");

	// Create a Date object from the ISO timestamp
	const date = new Date(isoTimestamp);

	// Check for invalid date
	if (isNaN(date.getTime())) return "Invalid Date";

	// Format the date to a readable string
	const options = {
		weekday: "short",
		year: "numeric",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	};

	return date.toLocaleString("en-US", options);
};

const calculateRelativeTimes = (startTimes, fileName) => {
	// 1. Extract the timestamp from the filename
	const timestamp = fileName.split("_")[0]; // Assuming the format is `timestamp_FaceLabel.extension`

	// 2. Convert extracted timestamp to ISO format
	const isoTimestamp = timestamp.replace(/T(\d{2})-(\d{2})-(\d{2})-(\d{3})Z$/, "T$1:$2:$3.$4Z");
	const submissionTime = new Date(isoTimestamp).getTime(); // Convert to milliseconds

	let cumulativeDuration = 0;

	// 3. Loop through each session's start time
	for (let i = 0; i < startTimes.length; i++) {
		const sessionStart = new Date(startTimes[i]).getTime(); // Convert start time to milliseconds

		if (submissionTime >= sessionStart) {
			cumulativeDuration += submissionTime - sessionStart; // Accumulate the difference
		} else {
			// If the frame's timestamp is before the session's start, stop the loop
			break;
		}
	}

	// 4. Calculate relative time and format it
	const relativeTime = cumulativeDuration / 1000; // Convert to seconds
	const relativeTimeInMinsAndSeconds = `${Math.floor(relativeTime / 60)}m ${Math.floor(relativeTime % 60)}s`;

	return relativeTimeInMinsAndSeconds;
};

export {
	getFormattedDateTime,
	getFormattedDateTime2,
	getFormattedDateTime3,
	getFormattedDateTime4,
	checkIfInvalidOrUnauthorized,
	checkIf48HoursPassed,
	getValuesFromRHFArray,
	stripMarkdown,
	convertFilenameToLocal,
	calculateRelativeTimes,
};
