export const numberSorter = (v1, v2) => {
	if (v1 == null) return -1;
	if (v2 == null) return 1;
	v1 = parseFloat(v1);
	v2 = parseFloat(v2);
	if (v1 < v2) {
		return -1;
	}
	if (v1 > v2) {
		return 1;
	}
	return 0;
};

export const dateSorter = (date1, date2) => {
	date1 = new Date(date1);
	date2 = new Date(date2);
	if (date1 < date2) {
		return -1;
	}
	if (date1 > date2) {
		return 1;
	}
	return 0;
};

export const nameSorter = (name1, name2) => {
	return name1.localeCompare(name2);
};

export const pronScoreSorter = (score1, score2) => {
	if (score1 == null) return -1;
	if (score2 == null) return 1;
	score1 = parseFloat(score1);
	score2 = parseFloat(score2);
	return score1 - score2;
};
