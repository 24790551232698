import {
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Modal,
	Typography,
} from "@mui/material/node";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { bulkUploadCVs } from "../../utilities/interviewReportsApi";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { addCandidates } from "../../app/actions/candidates/candidate";
const UploadResumes = () => {
	const dispatch = useDispatch();
	const openingData = useSelector((state) => state.openingDetails.selectedOpening);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [uploadStates, setUploadStates] = useState({
		resumeModal: false,
		bulkUploading: false,
	});
	const supportedFileTypes = [
		"application/pdf",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/msword",
	];
	const onDrop = useCallback(
		(acceptedFiles) => {
			if (selectedFiles.length >= 10) {
				alert("You have reached the maximum limit of 10 files.");
				return;
			}

			let filteredFiles = [];
			let rejectedFiles = [];
			let fileNames = new Set(selectedFiles.map((file) => file.name));

			const availableSlots = 10 - selectedFiles.length;

			acceptedFiles.every((file, index) => {
				if (index >= availableSlots) {
					return false;
				}

				if (supportedFileTypes.includes(file.type) && !fileNames.has(file.name)) {
					filteredFiles.push(file);
					fileNames.add(file.name);
				} else if (!supportedFileTypes.includes(file.type)) {
					rejectedFiles.push(file);
				}

				return true;
			});

			setSelectedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);

			if (rejectedFiles.length > 0) {
				const rejectedFileNames = rejectedFiles.map((file) => file.name).join(", ");
				alert(
					`Some files were not accepted because they are not supported types: ${rejectedFileNames}`,
				);
			}

			if (acceptedFiles.length > availableSlots) {
				alert(`Some files were not added because you can only upload a maximum of 10 files.`);
			}
		},
		[selectedFiles],
	);

	const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		noClick: true,
		accept: "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
	});
	const removeFile = (fileName) => {
		setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
	};

	const bulkResumeUpload = async () => {
		if (selectedFiles.length === 0) {
			alert("Please select files to upload.");
			return;
		}
		setUploadStates({ ...uploadStates, bulkUploading: true });
		const formData = new FormData();
		selectedFiles.forEach((file) => {
			formData.append("resume", file);
		});
		formData.append("openingId", openingData._id);

		const res = await bulkUploadCVs(formData);
		if (res?.message && res?.data) {
			toast.success(res.message);
			setUploadStates({ ...uploadStates, bulkUploading: false });
			setSelectedFiles([]);
			setUploadStates({ ...uploadStates, resumeModal: false });
			dispatch(addCandidates(res.data));
		}
		if (res?.failed && res?.message) {
			toast.error(res.message);
			setUploadStates({ ...uploadStates, bulkUploading: false });
			setSelectedFiles([]);
			setUploadStates({ ...uploadStates, resumeModal: false });
		}
		document.body.style.overflow = "auto";
	};
	return (
		<>
			<Button
				className="normal-case focus:outline-none focus:border-none transition duration-300 ease-in-out text-white px-4 py-2 rounded-lg flex items-center justify-between"
				onClick={() => {
					selectedFiles.length !== 0 && setSelectedFiles([]);
					setUploadStates({ ...uploadStates, resumeModal: true });
					document.body.style.overflow = "hidden";
				}}
				sx={{
					backgroundColor: "#F59E0B",
					":hover": {
						backgroundColor: "#B45309",
					},
				}}
			>
				<i className="fas fa-upload mr-2"></i>
				Upload Resumes
			</Button>
			<Modal
				open={uploadStates.resumeModal}
				onClose={() => {
					if (!uploadStates.bulkUploading) {
						setUploadStates({ ...uploadStates, resumeModal: false });
						document.body.style.overflow = "auto";
					}
				}}
				aria-labelledby="bulk-resume"
				aria-describedby="bulk-resume-upload"
				disableEscapeKeyDown={uploadStates.bulkUploading}
				sx={{
					overflow: "auto",
				}}
			>
				<Box className="w-full max-w-md mx-auto mt-10 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]">
					<Box className="bg-white p-6 rounded-lg shadow-lg">
						<Typography component="h2" variant="h2" className="text-lg font-semibold mb-4">
							Add Resumes
						</Typography>
						<Box
							className={`flex justify-center items-center w-full ${uploadStates.bulkUploading && "cursor-not-allowed"}`}
							{...(uploadStates.bulkUploading ? {} : getRootProps())}
						>
							<label
								htmlFor="file-upload"
								className={`flex flex-col justify-center items-center w-full h-32 border-2 border-gray-300 ${
									uploadStates.bulkUploading
										? "bg-gray-200 cursor-not-allowed"
										: `${
												isFocused && "border-blue-600"
											} ${isDragAccept && "border-green-600"} ${
												isDragReject && "border-red-600"
											} border-dashed cursor-pointer`
								} rounded-lg`}
							>
								<Box className="text-center">
									{isDragActive ? (
										<p className="mt-1 text-sm text-gray-500">Drop the files here...</p>
									) : (
										<p className="mt-1 text-sm text-gray-500">
											Drag and Drop here
											<br />
											or
											<br />
											<span
												className={`${uploadStates.bulkUploading ? "text-gray-500" : "text-blue-600 hover:underline"}`}
											>
												Browse files
											</span>
										</p>
									)}
									<input
										{...(uploadStates.bulkUploading ? {} : getInputProps())}
										id="file-upload"
										name="file-upload"
										type="file"
										className="hidden"
										multiple
										disabled={uploadStates.bulkUploading}
									/>
								</Box>
							</label>
						</Box>
						<List>
							{selectedFiles.map((file, index) => (
								<ListItem key={index} className="h-[30px]">
									<ListItemText primary={file.name} />
									<ListItemSecondaryAction>
										<IconButton
											disabled={uploadStates.bulkUploading}
											className={`
													${uploadStates.bulkUploading && "cursor-not-allowed opacity-50"}
													`}
											edge="end"
											aria-label="cancel"
											onClick={() => removeFile(file.name)}
										>
											<CancelIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>

						<Typography variant="p" component="p" className="text-xs text-gray-500 mt-2">
							Accepted File Types: .pdf,.doc and .docx only
						</Typography>
						<Box
							component={Button}
							disabled={uploadStates.bulkUploading}
							className={`normal-case mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 cursor-pointer focus:outline-none focus:border-none text-center ${
								uploadStates.bulkUploading && "cursor-not-allowed opacity-50"
							}`}
							onClick={bulkResumeUpload}
						>
							{uploadStates.bulkUploading ? (
								<Box className="flex justify-center items-center">
									Uploading...
									<i id="" className="fas fa-circle-notch fa-spin text-base ml-2"></i>
								</Box>
							) : (
								"Upload"
							)}
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default UploadResumes;
