import React, { useEffect, useState } from "react";
import EmailEditorComponent from "./EmailEditor";
import "./EmailFormRenderer.css";
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Divider,
	IconButton,
	MenuItem,
	Modal,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	Archive,
	AutoAwesome,
	CloseFullscreen,
	Delete,
	Fullscreen,
	Info,
	Preview,
	Save,
} from "@mui/icons-material";
import { createTrigger, deleteTrigger, getEmailPreview, updateTrigger } from "../../../utilities/eventsApi";
import { toast } from "react-toastify";
import { renderConfirmationDialog } from "../../confirmation/ConfirmationDialog";
import { useSelector } from "react-redux";
import { CriteriaComponent } from "./CriteriaModalComponent";
import { InputComponent } from "./APIFormRenderer";
import PausePlayAction from "./PausePlayAction";
import { addTemplate, deleteTemplateApi, getTemplates, updateTemplate } from "../../../utilities/templates";
import { GenerateWithAiModal, InfoRenderer } from "../../directEmail/SendEmail";
import CopyActionComponent from "./CopyActionComponent";

const EmailFormRenderer = ({
	openingId,
	fields = [],
	actionData,
	setActionData,
	loading = false,
	setLoading = () => {},
	onClickAddAction,
	showDivider = true,
	selectedEventId,
	selectedActionId,
	forCustomEvent = false,
	afterSave = () => {},
	afterUpdate = () => {},
	afterDelete = () => {},
}) => {
	const { selectedOpening } = useSelector((state) => state.openingDetails);
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState({
		index: null,
		template: null,
	});
	const [deletingTemplate, setDeletingTemplate] = useState(false);
	const [generateWithAiModal, setGenerateWithAiModal] = useState({
		open: false,
		index: null,
	});

	const [showFullEmailEditor, setShowFullEmailEditor] = useState({
		index: null,
		open: false,
	});

	const [showPreviewModal, setShowPreviewModal] = useState({
		index: null,
		open: false,
	});

	const [generatedTemplate, setGeneratedTemplate] = useState({
		template: "",
		subject: "",
	});

	const generateWithAi = (e, index) => {
		setGenerateWithAiModal({
			open: true,
			index: index,
		});
	};

	const fetchTemplates = async () => {
		try {
			const response = await getTemplates();
			if (response && response.data) {
				setTemplates(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const saveTemplate = async (index) => {
		let emailData = actionData[index];
		if (!emailData.subject || !emailData.body) {
			toast.error("Subject and body are required");
			return;
		}
		try {
			let templateFunction = false ? updateTemplate : addTemplate;
			const response = await templateFunction({
				data: {
					subject: emailData.subject,
					body: emailData.body,
					templateName: emailData.subject,
					// id: emailData._id,
				},
			});
			if (response && response.data) {
				fetchTemplates();
				toast.success(
					emailData._id ? "Template updated successfully" : "Template saved successfully",
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const onClickTemplateName = (e, index) => {
		const template = templates.find((el) => el._id === e.target.value);
		if (template) {
			let actionCopy = [...actionData];
			actionCopy[index].body = template.body;
			actionCopy[index].subject = template.subject;
			setActionData(actionCopy);
			setSelectedTemplate({
				index: index,
				template: template,
			});
			setShowFullEmailEditor({
				index: index,
				open: true,
			});
		} else {
			setSelectedTemplate(null);
		}
	};

	const onDeleteTemplate = async () => {
		renderConfirmationDialog(
			"Are you sure you want to delete this template?",
			() => null,
			deleteTemplate,
			true,
			0,
		);
	};

	const deleteTemplate = async () => {
		if (!selectedTemplate) {
			toast.error("No template selected");
			return;
		}
		try {
			setDeletingTemplate(true);
			let response = await deleteTemplateApi({ id: selectedTemplate.template._id });
			if (response && response.data) {
				setTemplates(
					JSON.parse(JSON.stringify(templates.filter((el) => el._id !== selectedTemplate._id))),
				);
				setSelectedTemplate({
					index: null,
					template: null,
				});
				fetchTemplates();
				toast.success("Template deleted successfully");
			} else {
				toast.error("Error deleting template");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setDeletingTemplate(false);
		}
	};

	const useGeneratedTemplate = () => {
		let blocks = generatedTemplate.template.split("\n\n").map((el) => {
			el = el.replace(/\n/g, "<br>");
			return {
				type: "paragraph",
				data: {
					text: el,
				},
			};
		});
		let actionCopy = [...actionData];
		actionCopy[generateWithAiModal.index].body = JSON.stringify({
			time: new Date().getTime(),
			blocks: blocks,
		});
		actionCopy[generateWithAiModal.index].subject = generatedTemplate.subject;
		setActionData(actionCopy);
		setGenerateWithAiModal({
			open: false,
			index: null,
		});
		setGeneratedTemplate({
			template: "",
			subject: "",
		});
	};

	const onClickSaveAction = async (e, index) => {
		let action = actionData[index];
		let column = action.column || "";

		if (selectedEventId == 3 && column == "") {
			toast.error("Column is required");
			return;
		}

		if (action.from === "" || action.to === "" || action.subject === "") {
			toast.error("From, To and Subject are required");
			return;
		}
		let fromEmails = action.from.split(",");
		let ccEmails = action.cc.split(",");
		let isValid = true;
		fromEmails.forEach((email) => {
			if (!validateEmail(email)) {
				isValid = false;
			}
		});
		ccEmails.forEach((email) => {
			email = email.trim();
			if (!validateEmail(email) && email !== "") {
				isValid = false;
				if (["admin", "candidate"].indexOf(email.toLowerCase()) > -1) {
					isValid = true;
				}
			}
		});
		if (!isValid) {
			toast.error("Invalid email address");
			return;
		}
		if (!action.id) {
			let body = {
				openingId: openingId,
				event: selectedEventId,
				action: selectedActionId,
				actionData: JSON.stringify(action),
			};
			try {
				let response = await createTrigger(body);
				if (response && response.data) {
					let apiFormCopy = [...actionData];
					apiFormCopy[index].id = response.data._id;
					setActionData(apiFormCopy);
					toast.success("Action created successfully");
					if (afterSave) afterSave(null, response.data._id);
				} else {
					console.log(response);
					toast.error("Error creating action");
				}
			} catch (error) {
				console.log(error);
				// toast.error("Error creating action");
			}
		} else {
			let body = {
				triggerId: action.id,
				actionData: JSON.stringify(action),
			};
			try {
				let response = await updateTrigger(body);
				if (response && response.data) {
					toast.success("Action updated successfully");
					if (afterUpdate) afterUpdate();
				} else {
					toast.error("Error updating action");
				}
			} catch (error) {
				// toast.error("Error updating action");
			}
		}
	};

	const deleteAction = async (e, apiIndex) => {
		let action = actionData[apiIndex];
		if (!openingId) {
			toast.error("Opening Id is required");
			return;
		}
		let actionId = action.id;
		let apiFormCopy = [...actionData];
		apiFormCopy.splice(apiIndex, 1);
		setActionData(apiFormCopy);

		if (actionId && actionId !== "") {
			try {
				let body = {
					triggerId: actionId,
				};
				let response = await deleteTrigger(body);
				if (response) {
					toast.success("Action deleted successfully");
					if (afterDelete) afterDelete(null, actionId);
				} else {
					toast.error("Error deleting action");
					let apiFormCopy = [...actionData];
					setActionData(apiFormCopy);
				}
			} catch (error) {
				let apiFormCopy = [...actionData];
				setActionData(apiFormCopy);
				// toast.error("Error deleting action");
			}
		}
	};

	const validateEmail = (email) => {
		let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailPattern.test(email);
	};

	const onFieldsValueChange = (e, index, field) => {
		let newActionData = [...actionData];
		newActionData[index][field] = e.target.value;
		setActionData(newActionData);
	};

	const showPreview = (e, index) => {
		setShowPreviewModal({
			index: index,
			open: true,
		});
	};

	useEffect(() => {
		fetchTemplates();
	}, []);

	return (
		<>
			<div className="flex-1 overflow-y-auto rounded-md overflow-hidden my-3">
				{showPreviewModal.open && (
					<PreviewModal
						open={showPreviewModal.open}
						onClose={() =>
							setShowPreviewModal({
								index: null,
								open: false,
							})
						}
						action={actionData[showPreviewModal.index]}
						openingId={openingId}
					/>
				)}
				{generateWithAiModal.open && (
					<GenerateWithAiModal
						open={generateWithAiModal.open}
						onClose={() => setGenerateWithAiModal({ open: false, index: null })}
						onUseTemplate={useGeneratedTemplate}
						generatedTemplate={generatedTemplate.template}
						afterGeneration={(content) => {
							setGeneratedTemplate(content);
						}}
					/>
				)}
				{actionData.length > 0 &&
					actionData.map((action, index) => {
						return (
							<>
								{showDivider && (
									<Divider>
										<Chip
											size="small"
											label={+index + 1}
											color="primary"
											variant="outlined"
										/>
									</Divider>
								)}
								<div className="flex gap-2 align-bottom justify-between items-end mb-1 mx-1">
									{selectedEventId == 3 && (
										<div>
											<Typography
												id="modal-modal-title"
												style={{
													fontSize: ".8rem",
												}}
												className="text-gray-500 ml-1"
											>
												Column
											</Typography>
											<InputComponent
												value={actionData[index].column || ""}
												fields={fields.filter(
													(el) =>
														el.type === "report" &&
														!el.disabled &&
														el.enabledFor.includes("eventType3"),
												)}
												onChange={(e) => {
													let actionCopy = [...actionData];
													actionCopy[index].column = e.target.value;
													setActionData(actionCopy);
												}}
												type={"Column"}
												key={index}
												styles={{ width: "16ch" }}
											/>
										</div>
									)}
									<div className="flex-1"></div>
									<CriteriaComponent
										action={action}
										fields={fields}
										onCriteriaSave={(data) => {
											let actionCopy = [...actionData];
											actionCopy[index].criteria = data;
											setActionData(actionCopy);
											onClickSaveAction(null, index);
										}}
									/>
									<CopyActionComponent
										eventType={selectedEventId}
										triggerId={action.id}
										onEventModalClose={() => {}}
										actionType={selectedActionId}
									/>
								</div>
								{showFullEmailEditor.open && showFullEmailEditor.index == index && (
									<FullEmailEditorModal
										open={showFullEmailEditor.open}
										onClose={() =>
											setShowFullEmailEditor({
												index: null,
												open: false,
											})
										}
										action={action}
										index={index}
										fields={fields}
										setActionData={setActionData}
										InfoRenderer={InfoRenderer}
										showPreview={showPreview}
										onClickSaveAction={onClickSaveAction}
										onClickGenerateWithAi={generateWithAi}
										aiModalOpen={generateWithAiModal.open}
									/>
								)}
								<div className="flex flex-col mt-1 mx-1">
									<div className="flex flex-col my-1">
										<div className="text-sm text-gray-800 font-semibold">
											Load Template:
										</div>
										<div className="flex items-center gap-1">
											<TextField
												select
												fullWidth
												variant="outlined"
												size="small"
												inputProps={{ className: "text-sm" }}
												value={
													selectedTemplate.index == index
														? selectedTemplate.template._id
														: ""
												}
												onChange={(e) => onClickTemplateName(e, index)}
											>
												{templates.length > 0 ? (
													templates.map((option) => (
														<MenuItem
															key={option._id}
															value={option._id}
															className="text-sm"
														>
															{option.templateName}
														</MenuItem>
													))
												) : (
													<MenuItem value="0" className="text-sm">
														No Templates Found
													</MenuItem>
												)}
											</TextField>
											{selectedTemplate.index == index && (
												<Tooltip title="Delete Template">
													<IconButton
														onClick={onDeleteTemplate}
														disabled={deletingTemplate}
														className="text-rose-500"
													>
														<Delete />
													</IconButton>
												</Tooltip>
											)}
										</div>
									</div>
									<div className="flex flex-col my-1">
										<div className="text-sm text-gray-800 font-semibold">From:</div>
										<TextField
											size="small"
											variant="outlined"
											fullWidth
											disabled
											value={action.from}
											onChange={(e) => onFieldsValueChange(e, index, "from")}
											inputProps={{ className: "text-sm" }}
										/>
									</div>
									<div className="flex flex-col my-1">
										<div className="text-sm text-gray-800 font-semibold">To:</div>
										<Select
											size="small"
											multiple
											variant="outlined"
											fullWidth
											value={action.to.split(",")}
											onChange={(e) => {
												e.target.value = e.target.value.join(",");
												onFieldsValueChange(e, index, "to");
											}}
											inputProps={{ className: "text-sm" }}
											renderValue={(selected) => (
												<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
													{selected.length < 4
														? selected.map(
																(value) =>
																	value.trim().length > 0 && (
																		<Chip key={value} label={value} />
																	),
															)
														: selected
																.slice(0, 4)
																.map(
																	(value) =>
																		value.trim().length > 0 && (
																			<Chip key={value} label={value} />
																		),
																)}
													{selected.length > 4 && (
														<Chip
															key="more"
															label={`+${selected.length - 4} more`}
														/>
													)}
												</Box>
											)}
										>
											<MenuItem value="Admin" className="text-sm">
												Admin
											</MenuItem>
											<MenuItem value="Candidate" className="text-sm">
												Candidate
											</MenuItem>
											{selectedOpening?.supportEmail &&
												selectedOpening?.supportEmail !== "" && (
													<MenuItem
														value={selectedOpening.supportEmail}
														className="text-sm"
													>
														Support Email
													</MenuItem>
												)}
										</Select>
									</div>
									<div className="flex flex-col my-1">
										<div className="text-sm text-gray-800 font-semibold">Cc:</div>
										<Tooltip
											title="Write 'Admin' or 'Candidate' to use their email. Multipe emails should be comma separated"
											placement="bottom-start"
										>
											<TextField
												size="small"
												variant="outlined"
												fullWidth
												value={action.cc}
												onChange={(e) => onFieldsValueChange(e, index, "cc")}
												inputProps={{ className: "text-sm" }}
											/>
										</Tooltip>
									</div>
									<div className="flex flex-col mt-1">
										<div className="text-sm text-gray-800 font-semibold">Subject:</div>
										<TextField
											size="small"
											variant="outlined"
											fullWidth
											value={action.subject}
											onChange={(e) => onFieldsValueChange(e, index, "subject")}
											inputProps={{ className: "text-sm" }}
										/>
									</div>
								</div>
								<div className="mb-1 mx-1 z-20 w-full flex flex-col relative">
									<div className="flex justify-end w-full gap-1 pr-4 pt-0 pb-0 items-end">
										<div className="text-sm text-gray-800 flex-1 font-semibold">
											Message:
										</div>
										<span className="scale-75">
											<Tooltip title="Save As Template">
												<IconButton
													className="bg-sky-100 cursor-pointer"
													onClick={(e) => saveTemplate(index)}
												>
													<Archive color="info" />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<Tooltip title="Generate With AI">
												<IconButton
													className="cursor-pointer bg-indigo-100 text-indigo-600 animate-pulse"
													onClick={(e) => generateWithAi(e, index)}
												>
													<AutoAwesome />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<Tooltip title="Preview">
												<IconButton
													className="cursor-pointer bg-orange-300"
													onClick={(e) => showPreview(e, index)}
												>
													<Preview />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<PausePlayAction
												action={action}
												index={index}
												setActionData={setActionData}
												allActions={actionData}
												icon={true}
											/>
										</span>
										<span className="scale-75">
											<Tooltip title="Save">
												<IconButton
													className="cursor-pointer bg-green-400"
													onClick={(e) => {
														onClickSaveAction(e, index);
													}}
												>
													<Save />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<Tooltip title={<InfoRenderer fields={fields} />}>
												<IconButton className="bg-gray-200 cursor-pointer">
													<Info />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<Tooltip title="Maximize">
												<IconButton
													className="bg-sky-100 cursor-pointer"
													onClick={() => {
														setShowFullEmailEditor({
															index: index,
															open: true,
														});
													}}
												>
													<Fullscreen />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<Tooltip title="Delete">
												<IconButton
													className="cursor-pointer text-white bg-red-400"
													onClick={(e) => {
														renderConfirmationDialog(
															"Are you sure you want to delete this action?",
															() => {},
															() => {
																deleteAction(e, index);
															},
															true,
														);
													}}
												>
													<Delete />
												</IconButton>
											</Tooltip>
										</span>
									</div>
									{!showFullEmailEditor.open && !generateWithAiModal.open && (
										<EmailEditorComponent
											action={action}
											index={index}
											onChange={(content) => {
												setActionData((prev) => {
													let newActionData = [...prev];
													newActionData[index].body = content;
													return newActionData;
												});
											}}
											style={{
												border: "1px solid #E5E7EB",
												maxWidth: "99%",
												borderRadius: "5px",
											}}
										/>
									)}
								</div>
								<div className="relative flex w-full mb-10">
									<div className="flex justify-end items-center w-full gap-2 m-1 pr-3 hidden">
										<Button variant="outlined" onClick={(e) => saveTemplate(index)}>
											Save As Template
										</Button>
										<PausePlayAction
											action={action}
											index={index}
											setActionData={setActionData}
											allActions={actionData}
											icon={false}
										/>
										<Button
											variant="outlined"
											onClick={(e) => onClickSaveAction(e, index)}
										>
											{action.id ? "Update" : "Save"}
										</Button>
										<Button
											variant="outlined"
											color="warning"
											onClick={(e) => showPreview(e, index)}
										>
											Preview
										</Button>
										<Button
											variant="outlined"
											onClick={(e) => {
												renderConfirmationDialog(
													"Are you sure you want to delete this action?",
													() => {},
													() => {
														deleteAction(e, index);
													},
													true,
												);
											}}
											color="error"
										>
											Delete
										</Button>
									</div>
								</div>
							</>
						);
					})}
			</div>
		</>
	);
};

const FullEmailEditorModal = ({
	open,
	onClose,
	aiModalOpen = false,
	action,
	index,
	setActionData,
	fields = [],
	InfoRenderer,
	showPreview = () => {},
	onClickSaveAction = () => {},
	onClickGenerateWithAi = () => {},
}) => {
	let icons = [
		{
			title: "Generate With AI",
			onClick: (e) => {
				onClickGenerateWithAi(e, index);
			},
			icon: <AutoAwesome />,
			className: "bg-white animate-pulse text-sky-600",
		},
		{
			title: "Preview",
			onClick: (e) => showPreview(e, index),
			icon: <Preview />,
			className: "bg-orange-300",
		},
		{
			title: "Save",
			onClick: (e) => {
				onClickSaveAction(e, index);
				onClose();
			},
			icon: <Save />,
			className: "bg-green-600",
		},
		{
			title: <InfoRenderer fields={fields} />,
			onClick: () => {},
			icon: <Info />,
			className: "bg-white",
		},
		{
			title: "Minimize",
			onClick: onClose,
			icon: <CloseFullscreen />,
			className: "bg-gray-300",
		},
	];

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "80vw",
					bgcolor: "background.paper",
					boxShadow: 24,
					backgroundColor: "white",
					borderRadius: "10px",
					paddingBottom: "1rem",
					paddingTop: "1rem",
				}}
				className="transition-all duration-300 ease-in-out"
			>
				<div className="absolute flex flex-row justify-end items-center w-full -top-[2.4rem] gap-3">
					{icons.map((el, index) => {
						return (
							<Tooltip key={index} title={el.title}>
								<IconButton
									size="small"
									className={`scale-90 cursor-pointer ${el.className}`}
									onClick={el.onClick}
									style={el.styles ? el.styles : {}}
								>
									{el.icon}
								</IconButton>
							</Tooltip>
						);
					})}
				</div>
				<div
					className="py-2 px-3 rounded-md overflow-hidden overflow-y-auto flex max-h-[100%]"
					style={{ maxHeight: "80vh", overflowY: "auto" }}
				>
					{!aiModalOpen && (
						<EmailEditorComponent
							action={action}
							index={index}
							onChange={(content) => {
								setActionData((prev) => {
									let newActionData = [...prev];
									newActionData[index].body = content;
									return newActionData;
								});
							}}
							suffix="full"
							style={{ border: "none" }}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
};

const PreviewModal = ({ open, onClose, action, openingId }) => {
	const [state, setState] = useState({
		loading: true,
		htmlTemplateString: "",
	});

	const { selectedCandidate } = useSelector((state) => state.candidates);

	const getEmailTemplate = async () => {
		try {
			setState({
				loading: true,
				htmlTemplateString: "",
			});
			let response = await getEmailPreview({
				action,
				openingId,
				candidateId: selectedCandidate?.join(","),
			});
			if (response && response.data) {
				if (response.data?.html?.html) {
					setState({
						loading: false,
						htmlTemplateString: response.data.html.html,
					});
				}
			} else {
				console.log(response);
			}
		} catch (error) {
			console.log(error);
			setState({
				loading: false,
				htmlTemplateString: "",
			});
		}
	};

	useEffect(() => {
		getEmailTemplate();
	}, []);

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "60vw",
					bgcolor: "background.paper",
					boxShadow: 24,
					borderRadius: "10px",
					paddingBottom: "1rem",
				}}
				className="bg-white overflow-hidden"
			>
				<div className="w-full flex justify-between items-center bg-white-600 text-black pb-3 pr-4 pl-6 pt-4">
					<Typography className="text-lg font-bold">Email Preview</Typography>
					<IconButton size="small" onClick={onClose} className="text-rose-600">
						<CloseFullscreen />
					</IconButton>
				</div>
				<div
					style={{
						maxHeight: "70vh",
						minHeight: "30vh",
						overflow: "auto",
						borderTop: "1px solid #E5E7EB",
					}}
					className="relative h-full w-full"
				>
					{state.loading ? (
						<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
							<CircularProgress />
						</div>
					) : (
						<div
							className="break-words py-1 text-sm mx-6 whitespace-pre-wrap"
							id="preview-modal"
							dangerouslySetInnerHTML={{ __html: state.htmlTemplateString }}
						/>
					)}
				</div>
				<div
					className="pl-4 pr-4 pt-4 pb-0 flex justify-center items-center text-gray-600 text-sm"
					style={{
						borderTop: "1px solid #E5E7EB",
					}}
				>
					<Info className="mr-2" /> This preview is intended to verify the correct rendering of the
					email and the proper replacement of all variables. Please note that it may display values
					from a report or placeholder data.
				</div>
			</div>
		</Modal>
	);
};

export default EmailFormRenderer;
export { FullEmailEditorModal, PreviewModal };
