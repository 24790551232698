import ContentLoader from "react-content-loader";

const OpeningDataLoaderForEvaluations = () => {
	return (
		<ContentLoader
			speed={1.5}
			width="100%"
			height={300}
			viewBox="0 0 100% 300"
			backgroundColor="#e2e8f0"
			foregroundColor="#cbd5e0"
		>
			{/* Job ID */}
			<rect x="40" y="20" rx="4" ry="4" width="200" height="16" />

			{/* Job Title */}
			<rect x="40" y="50" rx="4" ry="4" width="300" height="30" />

			{/* Experience Level */}
			<rect x="40" y="100" rx="4" ry="4" width="250" height="16" />

			{/* Created On */}
			<rect x="40" y="130" rx="4" ry="4" width="150" height="16" />

			{/* Core Skills */}
			<rect x="40" y="170" rx="20" ry="20" width="80" height="30" />
			<rect x="130" y="170" rx="20" ry="20" width="80" height="30" />
			<rect x="220" y="170" rx="20" ry="20" width="80" height="30" />

			{/* Interview URL */}
			<rect x="40" y="220" rx="4" ry="4" width="450" height="30" />

			{/* Copy Icon */}
			<circle cx="50" cy="265" r="10" />

			{/* Copy Instruction */}
			<rect x="40" y="280" rx="4" ry="4" width="300" height="16" />
		</ContentLoader>
	);
};

export default OpeningDataLoaderForEvaluations;
