import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Modal } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { supportInfoAkaAssignedManagerSchema } from "../../schemas/opening.schema";
import { ErrorMessage } from "../../pages/CreateUpdateOpening/CreateUpdateOpeningWithJD";
import { updateSupportAkaAssignedManagerInfo } from "../../utilities/openingsApi";
import { toast } from "react-toastify";
import useLocalStorage from "../../custom-hooks/useLocalStorage";

const modalStyles = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	"@media (max-width:600px)": {
		width: "80%",
	},
	"@media (min-width:600px)": {
		width: "50%",
	},
	"@media (min-width:992px)": {
		width: "400px",
	},

	bgcolor: "background.paper",
	boxShadow: 5,
	px: 4,
	pt: 4,
	pb: 2,
	overflowY: "auto",
	maxHeight: "90vh",
	borderRadius: "10px",
	border: "none",
	outline: "none",
};

const supportInfo = [
	{ id: "supportName", type: "text", label: "Name", placeholder: "Manager Name" },
	{ id: "supportPhone", type: "tel", label: "Phone", placeholder: "Phone Number" },
	{ id: "supportEmail", type: "email", label: "Email", placeholder: "Email Address" },
	{
		id: "allowSupportContact",
		type: "checkbox",
		label: "Allow candidates to contact?",
		extraInfo: "Candidates will see this phone number and can contact you if needed.",
	},
];

const defaultValues = {
	supportPhone: "",
	supportEmail: "",
	supportName: "",
	allowSupportContact: false,
};
const AssignedManager = ({ openingId, open, handleClose, onSuccess = () => {} }) => {
	const [values, setValues] = useLocalStorage(
		"assignedManagerInfo",
		defaultValues,
		supportInfoAkaAssignedManagerSchema,
	);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues,
		values,
		resolver: zodResolver(supportInfoAkaAssignedManagerSchema),
	});
	const handleAssignedManagerDetailsSubmit = async (data) => {
		try {
			setIsSubmitting(true);
			const response = await updateSupportAkaAssignedManagerInfo(openingId, data);
			if (response) {
				setIsSubmitting(false);
				setValues(data);
				toast.success("Assigned manager details updated successfully!");
				onSuccess();
			}
		} catch (error) {
			setIsSubmitting(false);
			toast.error(error.message);
		}
	};
	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="assigned-manager-modal">
			<Box sx={modalStyles}>
				<h2 className="text-xl font-bold mb-2">Assigned Manager</h2>
				<p className="text-sm text-gray-600 mb-4">
					Enter the team member responsible for this job opening. Candidates or Zinterview support
					staff may contact you for coordination or any specific job-related queries.
				</p>
				<form
					noValidate
					onSubmit={handleSubmit(handleAssignedManagerDetailsSubmit)}
					className="create-update-opening"
				>
					<div className="flex flex-col gap-3">
						{supportInfo.map((field) => {
							if (field.id === "allowSupportContact") {
								return (
									<div className="flex  flex-col gap-2 select-none" key={field.id}>
										<Label htmlFor={field.id} label={field.label} />
										<label>
											<input
												type={field.type}
												id={field.id}
												className="mr-2 align-middle"
												{...register("allowSupportContact")}
											/>
											Yes
										</label>
										<p className="text-xs text-gray-500">
											Candidates will see this phone number and can contact you if
											needed.
										</p>
									</div>
								);
							} else {
								return (
									<FormElement
										key={field.id}
										{...field}
										register={register}
										errors={errors}
										autoFocus={field.id === "supportName"}
									/>
								);
							}
						})}
					</div>
					<div className="flex flex-row flex-wrap justify-end mt-4 gap-3">
						<Button
							disabled={isSubmitting}
							type="button"
							id="closeModal"
							className="bg-gray-300 text-gray-700 "
							onClick={handleClose}
						>
							Cancel
						</Button>
						<Button type="submit" className="bg-blue-500 text-white" disabled={isSubmitting}>
							{isSubmitting ? "Saving" : "Save"}
						</Button>
					</div>
				</form>
			</Box>
		</Modal>
	);
};

const FormElement = ({ id, type = "text", label, placeholder = "", register, errors, ...inputProps }) => {
	return (
		<div className="flex flex-col gap-2">
			<Label htmlFor={id} label={label} />
			<input
				{...inputProps}
				className="w-full p-2 border rounded "
				type={type}
				id={id}
				placeholder={placeholder}
				{...register(id)}
			/>
			<ErrorMessage errorCriteria={errors?.[id]?.message} />
		</div>
	);
};

const Label = ({ htmlFor, label }) => {
	return (
		<label className="block text-sm font-medium text-gray-700" htmlFor={htmlFor}>
			{label}
		</label>
	);
};

const Button = ({ className = "", type = "button", onClick = () => {}, children, ...buttonProps }) => {
	return (
		<button
			{...buttonProps}
			type={type}
			onClick={onClick}
			className={clsx(
				"px-4 py-2 rounded outline-none border-none cursor-pointer disabled:opacity-50",
				className,
			)}
		>
			{children}
		</button>
	);
};

export default AssignedManager;
