import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import openingServices from "./OpeningService";

const initialState = {
	openingDetails: {},
	selectedOpening: {},
	allOpenings: [],
	loading: false,
	error: null,
};
export const getOpeningDataWithId = createAsyncThunk(
	"opening/fetchingOpeningData",
	async (openingId, thunkAPI) => {
		try {
			const res = await openingServices.getOpeningDataWithId(openingId);
			return res;
		} catch (error) {
			console.log("error while getting selected opening details");
			console.log(error);
			const message = error?.response?.data?.message || error.message;
			return thunkAPI.rejectWithValue(message);
		}
	},
);

const createOpeningSlice = createSlice({
	name: "opening",
	initialState,
	reducers: {
		createOpeningAction: (state, action) => {
			state.openingDetails = action.payload;
		},
		resetOpeningDetails: (state, action) => {
			return initialState;
		},
		setSelectedOpening: (state, action) => {
			state.selectedOpening = action.payload;
		},
		setColumnsOrder: (state, action) => {
			state.selectedOpening.columns = action.payload;
		},
		setAllOpenings: (state, action) => {
			state.allOpenings = action.payload;
		},
		setError: (state, action) => {
			state.error = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOpeningDataWithId.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getOpeningDataWithId.fulfilled, (state, action) => {
				state.loading = false;
				state.selectedOpening = action.payload;
			})
			.addCase(getOpeningDataWithId.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const createOpeningSelector = (state) => state.openingDetails.openingDetails;

const createOpeningReducer = createOpeningSlice.reducer;
export default createOpeningReducer;
export const {
	createOpeningAction,
	resetOpeningDetails,
	setSelectedOpening,
	setColumnsOrder,
	setAllOpenings,
	setError,
} = createOpeningSlice.actions;
