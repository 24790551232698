import React, { useState } from "react";
import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import logo from "../../assets/zi-favicon.png";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	maxWidth: "100%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	overflowY: "auto",
	maxHeight: "90vh",
	borderRadius: "10px",
	"@media (min-width: 800px)": {
		width: 800,
	},
};

const PlanDetails = ({ currentPlan }) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button variant="outlined" color="secondary" onClick={() => setShowModal(true)}>
				View Details
			</Button>
			<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
				aria-labelledby="current-plan-details"
				aria-describedby="current-plan-details"
			>
				<Box sx={modalStyle}>
					<Box className="flex justify-center mb-6">
						<img src={logo} alt="Zinterview Logo" className="h-16" />
					</Box>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>
										<b>Plan Type</b>
									</TableCell>
									<TableCell>{currentPlan?.type || "-"}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										<b>Plan Start Date</b>
									</TableCell>
									<TableCell>
										{currentPlan?.startDate
											? new Date(currentPlan.startDate).toDateString()
											: "-"}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										<b>Plan Renew Date</b>
									</TableCell>
									<TableCell>
										{currentPlan?.renewalDate
											? new Date(currentPlan.renewalDate).toDateString()
											: "-"}
									</TableCell>
								</TableRow>
								{currentPlan?.type === "Package" && (
									<>
										<TableRow>
											<TableCell>
												<b>Is Pro Rated?</b>
											</TableCell>
											<TableCell>{currentPlan?.isProRated ? "Yes" : "No"}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<b>Plan Name</b>
											</TableCell>
											<TableCell>{currentPlan?.details?.name || "-"}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<b>Quota Validity</b>
											</TableCell>
											<TableCell>
												{currentPlan?.details?.quotaValidity || "-"}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<b>Interviews Per Quota</b>
											</TableCell>
											<TableCell>
												{currentPlan?.details?.interviewsPerQuota || "-"}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<b>Additional Interview Rate</b>
											</TableCell>
											<TableCell>
												{currentPlan?.details?.additionalInterviewRate || "-"}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<b>Price</b>
											</TableCell>
											<TableCell>{currentPlan?.details?.price || "-"}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<b>Interviews Used</b>
											</TableCell>
											<TableCell>
												{currentPlan?.details?.interviewsUsed || "-"}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<b>Additional Interviews Used</b>
											</TableCell>
											<TableCell>
												{currentPlan?.details?.additionalInterviewsUsed || "-"}
											</TableCell>
										</TableRow>
									</>
								)}
								{currentPlan?.type === "PayAsYouGo" && (
									<>
										<TableRow>
											<TableCell>
												<b>Plan Name</b>
											</TableCell>
											<TableCell>{currentPlan?.details?.name || "-"}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<b>Interview Rate</b>
											</TableCell>
											<TableCell>
												{currentPlan?.details?.interviewRate || "-"}
											</TableCell>
										</TableRow>
									</>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Modal>
		</>
	);
};

export default PlanDetails;
